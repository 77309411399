import { t } from 'i18next'

import { updateAsset } from '@/api'
import { createOperation } from '@/api/broker/v2/operations'
import { uploadFiles } from '@/api/files'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { IsNot } from '@/helpers/test'
import { key as QueryOperationsKey } from '@/hooks/queries/useOperations'
import { ActionResponse } from '@/types/actions'
import { IAsset } from '@/types/asset'
import { IFile } from '@/types/file'

export const CreateCase = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const name = formData.get('case_name')?.toString()?.trim() ?? ''
  const assets = formData.getAll('case_asset')

  // const notes = formData.get('case_notes')?.toString() // << Not currently implemented in BE
  let files: IFile[] = []

  // Check basic details
  if (IsNot(name, REGEX.IS_CASE_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'case_name',
        error: t('invalid_case_name_provided'),
      },
    ])
  }

  // Grab the files from the form (if any) and ignore any
  // files that are not valid
  const formFiles = [...formData.getAll('case_files')]?.filter((file) => {
    return file && file instanceof File && file.size !== 0
  }) as File[]

  // Check if we have any files to upload
  if (formFiles.length > 0) {
    // Upload the files and get back a list of keys
    files = await uploadFiles(formFiles)
  }

  // Create the new operation
  const newOperationRes = await createOperation({
    name,
    ...(files.length > 0 && { files }), // Only include files if we have any
  })

  // Check if the call failed
  if (newOperationRes.error_code || newOperationRes.error) {
    throw new Error(ExtractErrorFrom(newOperationRes))
  }

  // Invalidate cache
  await queryClient.invalidateQueries({ queryKey: [QueryOperationsKey] })

  // If assets were chosen we now need to run through them and assign each
  // to our new operation
  if (newOperationRes.data && assets) {
    const newOperationId = newOperationRes.data

    try {
      // Run through each asset
      await Promise.all(
        assets.map((a) => {
          const parsedAsset = JSON.parse(a.toString()) as IAsset
          return updateAsset({
            ...parsedAsset,
            operation_id: newOperationId,
          })
        })
      )
    } catch (e) {
      // At this point we should throw an error but not anything that could
      // cause a re-create as the operation has been created - we can use a banner
      // instead
      console.error(e)
      return {
        error: false,
        message: `${t('successfully_added_case')} '${name}' ${t('but_failed_to_add_assets')}`,
        completion: 'COMPLETE_WITH_ERROR',
        data: newOperationRes.data, // New operation Id
      }
    }
  }

  return {
    error: false,
    message: `${t('new_case')} '${name}' ${t('added_successfully')}`,
    completion: 'COMPLETE',
    data: newOperationRes.data, // New operation Id
  }
}
