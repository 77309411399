import { t } from 'i18next'

import { generateReport as apiGenerateReport } from '@/api/broker/v2/reports'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ActionResponse } from '@/types/actions'

export const GenerateReport = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const formTemplateId = formData.get('template_id') as string
  //   const formCase = formData.get('case') as string
  //   const formParty = formData.get('party') as string
  const formCategories = formData.get('categories') as string
  const formDateFrom = formData.get('date_from') as string
  const formDateTo = formData.get('date_to') as string

  // Attempt to parse the categories
  const parsedCategories = JSON.parse(formCategories) as string[]

  // Attempt to generate the report
  const res = await apiGenerateReport({
    template: formTemplateId,
    categories: parsedCategories,
    ...(formDateFrom &&
      formDateTo && {
        seizured_at: {
          from: new Date(formDateFrom).toISOString(),
          to: new Date(formDateTo).toISOString(),
        },
      }),
  })

  //
  // Check response
  if (res.error_code || res.error) {
    throw new Error(ExtractErrorFrom(res))
  }

  return {
    error: false,
    message: t('report_generated_successfully'),
    completion: 'COMPLETE',
    data: true,
  }
}
