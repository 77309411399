import { t } from 'i18next'
import moment from 'moment'
import { ChangeEvent, useEffect, useState } from 'react'
import { useRevalidator } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { findLastStatus } from '@/actions/create-asset/assetForm.ts'
import { INTENTS } from '@/actions/intents'
import { AssetDefendants } from '@/components/asset/asset-defendants.tsx'
import { AssetStatusSelector } from '@/components/asset/asset-status-selector'
import Button from '@/components/button'
import {
  AssetDetailsValues,
  AssetFormDetailsGroup,
} from '@/components/drawer/variants/new-asset/asset-form-details-group.tsx'
import { Form } from '@/components/form'
import { Group } from '@/components/group'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { AssetRules } from '@/helpers/assetRules.ts'
import { getAssetTypeDescription } from '@/helpers/assetTypeDescription.ts'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { formatValue } from '@/helpers/formatValue'
import { useOrg } from '@/hooks/queries/useOrg.ts'
import { ActionResponse } from '@/types/actions'
import { AssetCategory, IAsset, IAssetStatusName } from '@/types/asset'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  asset: IAsset
}

export const EditAssetDrawer = (props: Props) => {
  const revalidator = useRevalidator()
  const { org } = useOrg()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const lastStatus = findLastStatus(props.asset.statuses)
  const asset = props.asset
  const [_digitalBalance, setDigitalBalance] = useState<number>(
    asset.category === AssetCategory.DIGITAL ? (asset.amount ?? 0) : 0
  )
  const [_status, setStatus] = useState<IAssetStatusName>(
    lastStatus?.name || 'PENDING'
  )
  const [_statusAt, setStatusAt] = useState(
    moment(lastStatus?.happened_at).format('YYYY-MM-DD')
  )

  // Create an asset ruleset
  const rules = AssetRules.fromAsset(asset)

  const detailsValues: AssetDetailsValues = {
    category: asset.category,
    country: asset.location?.country,
    address: asset.location?.address,
  }

  switch (asset.category) {
    case AssetCategory.FIAT_CASH:
      detailsValues.currency = asset.currency
      detailsValues.costs = asset.costs
      detailsValues.valuations = [
        {
          value: asset.amount ?? 0,
          currency: asset.currency,
        },
      ]
      break
    case AssetCategory.DIGITAL:
      break
    case AssetCategory.PERSONAL_PROPERTY:
      detailsValues.serialNumber = asset.serial_number
      detailsValues.costs = asset.costs
      detailsValues.valuations = asset.valuations

      break
    case AssetCategory.REAL_ESTATE:
      detailsValues.serialNumber = asset.plot_number
      detailsValues.costs = asset.costs
      detailsValues.valuations = asset.valuations

      break
    case AssetCategory.VEHICLE:
      detailsValues.make = asset.make
      detailsValues.model = asset.model
      detailsValues.serialNumber = asset.vin
      detailsValues.costs = asset.costs
      detailsValues.valuations = asset.valuations

      break
  }

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    if (_submitting === INTENTS.EDIT_ASSET) {
      revalidator.revalidate()
      ToastManager.showToast({
        type: 'success',
        text: actionRes.message ?? `Asset updated successfully`,
      })
      props.onClose()
    } else {
      clearSubmitting()
    }
  }

  useEffect(() => {
    const handleOnChangeStatus = (value: IAssetStatusName) => {
      if (value !== 'SEIZED' && value !== 'ADOPTED') {
        const newValue = moment().format('YYYY-MM-DD')

        setStatusAt(newValue)
      } else {
        const sortedStatuses = props.asset.statuses.sort(
          (a, b) =>
            new Date(b.created_at ?? 0).getTime() -
            new Date(a.created_at ?? 0).getTime()
        )
        switch (value) {
          case 'SEIZED': {
            const latestSeized = sortedStatuses.find(
              (status) => status.name === 'SEIZED'
            )
            if (latestSeized) {
              const newValue = moment(latestSeized.happened_at).format(
                'YYYY-MM-DD'
              )

              setStatusAt(newValue)
            } else {
              setStatusAt(moment().format('YYYY-MM-DD'))
            }
            return
          }
          case 'ADOPTED': {
            const latestAdopted = sortedStatuses.find(
              (status) => status.name === 'ADOPTED'
            )
            if (latestAdopted) {
              const newValue = moment(latestAdopted.happened_at).format(
                'YYYY-MM-DD'
              )
              setStatusAt(newValue)
            } else {
              setStatusAt(moment().format('YYYY-MM-DD'))
            }
            return
          }
        }
      }
    }

    handleOnChangeStatus(_status)
  }, [_status])

  const onBalanceChange = (e: ChangeEvent<HTMLInputElement>) =>
    setDigitalBalance(Number(e.target.value))

  return (
    <Drawer
      title={t('edit_asset')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form
        className="flex w-full max-w-[45rem] flex-col gap-9"
        data-testid="asset-form"
        buttons={[
          <Button.Basic
            key={'btn_edit_asset'}
            testId={'btn_update_asset'}
            hierarchy={'primary'}
            width={'intrinsic'}
            label={t('update_asset')}
            size={'medium'}
            state={_submitting === INTENTS.EDIT_ASSET ? 'loading' : 'default'}
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.EDIT_ASSET,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
      >
        <input
          type={'hidden'}
          name={'asset_original'}
          value={JSON.stringify(asset)}
          data-testid={'obj_asset_original'}
        />
        <input
          type={'hidden'}
          name={'asset_org_currency'}
          value={org?.preferred_currency}
        />

        <Group label={t('status')} className="flex-row gap-6">
          <AssetStatusSelector
            status={_status}
            onChangeStatus={(value) => setStatus(value as IAssetStatusName)}
          />

          <Input.Date
            max={moment().format('YYYY-MM-DD')}
            type="date"
            id="date"
            name={'asset_status_at'}
            value={_statusAt}
            onChange={(e) => setStatusAt(e.target.value)}
            placeholder={t('eg_ledger_usb')}
            className="max-w-40"
            disabled={_status !== 'SEIZED' && _status !== 'ADOPTED'}
          />
        </Group>

        <Group label={t('asset_details')}>
          <Input.IDNumber id={asset.platform_id ?? '-'} />
          <Input.Text
            testId={'asset_type_description'}
            value={getAssetTypeDescription(asset).title}
            state={'readonly'}
            className={twMerge('font-bold')}
            icon={
              <Icon
                name={getAssetTypeDescription(asset).icon}
                size={'medium'}
                variant="solid"
                className={'min-w-8'}
              />
            }
          />

          <Input.Text
            name={'external_ref'}
            testId={'asset_name'}
            value={asset?.external_ref}
            label={t('identifier')}
            hint={t('create_a_memorable_name')}
            placeholder={t('eg_ledger_usb')}
          />

          <Input.TextArea
            name={'asset_notes'}
            testId={'asset_description'}
            label={t('description')}
            placeholder={t('identification_notes')}
            defaultValue={(asset.notes && asset.notes.length > 0
              ? asset.notes[0].content
              : ''
            )?.trim()}
            className={'max-w-none'}
          />
        </Group>

        <AssetFormDetailsGroup rules={rules} values={detailsValues} />

        {asset.category === AssetCategory.DIGITAL &&
          asset.is_self_managed === true && (
            <Group label={t('custom_tracker')}>
              <Input.Text
                state={'readonly'}
                label={t('token')}
                value={asset.ticker.toUpperCase().replace('$', '') ?? ''}
                suffix={capitalizeFirstLetter(asset.network ?? '')}
              />

              <Input.Number
                name={'asset_balance'}
                testId={'asset_balance'}
                placeholder={'0.0'}
                min={0}
                label={t('balance')}
                defaultValue={asset.amount}
                onChange={onBalanceChange}
              />

              <Input.Text
                state={'readonly'}
                label={t('total_value')}
                value={
                  ((asset.price?.value ?? 0) /
                    (asset.category === 'DIGITAL' ? (asset.amount ?? 0) : 0)) *
                  _digitalBalance
                }
                text={formatValue(
                  ((asset.price?.value ?? 0) /
                    (asset.category === 'DIGITAL' ? (asset.amount ?? 0) : 0)) *
                    _digitalBalance,
                  asset.price?.currency,
                  { notation: 'standard' }
                )}
                suffix={asset.price?.currency ?? '??'}
                hint={t('total_value_hint')}
              />
            </Group>
          )}

        {rules.WhenEditing.Can.Edit.Defendant() && (
          <Group label={t('subject')}>
            <AssetDefendants
              name={'asset_defendant'}
              defaultDefendantId={asset.defendant_id}
              defaultMode={'ADD_EXISTING'}
            />
          </Group>
        )}
      </Form>
    </Drawer>
  )
}
