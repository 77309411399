import { t } from 'i18next'
import Cookies from 'js-cookie'
import { LoaderFunction, redirect } from 'react-router-dom'

import { getAssetById, getOrgUsers, getTransferRequests } from '@/api'
import { ROUTES } from '@/constants/routes'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'
import { IAsset } from '@/types/asset'
import { ITransferRequest } from '@/types/transfer'
import { IUser } from '@/types/user'

export interface LoaderData {
  transferRequest?: ITransferRequest
  requestedBy?: IUser
  asset: IAsset
}
export const loader: LoaderFunction = async ({ params, request }) => {
  try {
    const cookies = Cookies.get()
    const token = cookies['token']

    // Check we are authenticated
    if (!token) {
      const params = new URLSearchParams()
      params.set('from', new URL(request.url).pathname)
      return redirect(ROUTES.AUTH.INDEX + '?' + params.toString())
    }

    if (!params.transfer_id) {
      return ErrorHandler(404, t('missing_params'))
    }

    try {
      // Get the transfer request
      const res = await getTransferRequests(params.transfer_id)

      // Check we got the transfer request ok
      if (
        res.error_code ||
        res.error ||
        !res.data ||
        res.data.asset_ids.length <= 0
      ) {
        return ErrorHandler(404, ExtractErrorFrom(res, t('not_found')))
      }

      // Grab some further data to go alongisde the request
      const promRes = await Promise.all([
        getOrgUsers(res.data.organization_id),
        getAssetById(res.data.asset_ids[0]),
      ])

      // Check we succeeded
      if (
        promRes[0].error_code ||
        promRes[0].error ||
        !promRes[0].data ||
        promRes[1].error_code ||
        promRes[1].error ||
        !promRes[1].data
      ) {
        return ErrorHandler(404, ExtractErrorFrom(res, t('not_found')))
      }

      // Extract the asset out
      const asset = promRes[1].data

      // Find the user that made the request
      const requestedBy = promRes[0].data.find(
        (u) => u.id === res.data?.created_by
      )

      return {
        transferRequest: res.data,
        asset,
        requestedBy,
      }
    } catch (error) {
      return {
        transferRequest: undefined,
        asset: undefined,
        requestedBy: undefined,
      }
    }
  } catch (error: unknown) {
    const message = ExtractErrorFrom(error, t('failed_to_load_asset'))
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
