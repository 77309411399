import { memo, useEffect, useState } from 'react'

import Drawer from '@/components/drawer'
import { FormErrorsManager } from '@/contexts/formErrors'

import { DrawerProps } from '../../drawer.types'

interface Props {
  content: DrawerProps | null
}

export const DrawerContent = memo((props: Props) => {
  const [_selectedDrawer, setSelectedDrawer] = useState<DrawerProps | null>(
    props.content
  )
  const [_visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    // Check if anything has changed
    if (
      (_selectedDrawer === null && props.content !== null) ||
      (_selectedDrawer !== null && props.content === null) ||
      _selectedDrawer?.id !== props.content?.id
    ) {
      // Somethings changed - start by clearing down form errors
      // to keep the situation clear
      FormErrorsManager.clearErrors()

      // Check if we're currently displaying something
      if (_selectedDrawer !== null) {
        // We are displaying something so lets close that
        setVisible(false)
      }

      // Set the newly selected drawer
      setSelectedDrawer(props.content)
    }
  }, [props.content])

  useEffect(() => {
    // This will be called after a selected drawer has been changed
    // meaning the drawer will be rendered but not visible yet
    // Lets check that the selected drawer isn't null and if not
    // we'll show the drawer now
    if (_selectedDrawer) {
      setVisible(true)
    }
  }, [_selectedDrawer])

  const onClose = () => {
    // Hide the drawer first
    setVisible(false)

    // Wait a delay and then clear the drawer from our state
    setTimeout(() => {
      setSelectedDrawer(null)
    }, 300)
  }

  // If there is no selected drawer then we can
  // return null
  if (!_selectedDrawer) {
    return null
  }

  // Otherwise we'll figure out which to display
  // depending on the id of the props
  switch (_selectedDrawer.id) {
    case 'NEW_WORKSPACE':
      return (
        <Drawer.NewWorkspace
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'NEW_CASE':
      return (
        <Drawer.NewCase
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'NEW_OPERATION_DOCUMENT':
      return (
        <Drawer.NewOperationDocument
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'NEW_ASSET_DOCUMENT':
      return (
        <Drawer.NewAssetDocument
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'EDIT_CASE':
      return (
        <Drawer.EditCase
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'NEW_ASSET':
      return (
        <Drawer.NewAsset
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'EDIT_ASSET':
      return (
        <Drawer.EditAsset
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'REPORTING':
      return (
        <Drawer.Reporting
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'NEW_DEFENDANT':
      return (
        <Drawer.NewDefendant
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'EDIT_DEFENDANT':
      return (
        <Drawer.EditDefendant
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'EDIT_TEAM_USER':
      return (
        <Drawer.EditTeamUser
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'NEW_TEAM_USER':
      return (
        <Drawer.NewTeamUser
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'NEW_ORGANIZATION':
      return (
        <Drawer.NewOrganization
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'EDIT_ORGANIZATION':
      return (
        <Drawer.EditOrganization
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'NEW_CUSTODIAN':
      return (
        <Drawer.NewCustodian
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'NEW_SUPPORTED_ASSET':
      return (
        <Drawer.NewSupportedAsset
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
    case 'EDIT_SUPER_ADMIN_USER':
      return (
        <Drawer.EditTeamSuperUser
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )

    case 'CASE_ADD_EXISTING_ASSETS':
      return (
        <Drawer.CaseAddExistingAssets
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )

    case 'NEW_ESCROW':
      return (
        <Drawer.NewEscrow
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )

    case 'IMPORT_ASSETS':
      return (
        <Drawer.ImportAssets
          {..._selectedDrawer}
          visible={_visible}
          onClose={onClose}
        />
      )
  }
})
DrawerContent.displayName = 'DrawerContent'
