import { CustomCellRendererProps } from 'ag-grid-react'

export const UserRequestRenderer: React.FC<CustomCellRendererProps> = ({
  value,
  data,
}) => {
  return (
    <div className="leading-5">
      <div>{value}</div>
      <div className="text-[#585B5F]">{data.organization}</div>
    </div>
  )
}
