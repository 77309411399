import { ColDef, RowClickedEvent } from 'ag-grid-community'
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Avatar } from '@/components/avatar'
import { Typography } from '@/components/typography'
import { IEscrow } from '@/types/escrows'

import styles from '../aggrid.module.css'
import { CreatedAtColDef } from '../colDefs/createdAt.coldef'
import { ReferenceColDef } from '../colDefs/reference.coldef'
import { StatusColDef } from '../colDefs/status.coldef'

export interface Props {
  data: IEscrow[]
}

export const EscrowsTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const defaultColDef = useMemo<ColDef>(
    () => ({
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
    }),
    []
  )

  const colDefs = useMemo<ColDef<IEscrow>[]>(
    () => [
      {
        field: 'name',
        headerName: t('identifier'),
        cellRenderer: (props: CustomCellRendererProps<IEscrow>) => {
          return (
            <div className={'flex flex-row gap-[16px] items-center w-full'}>
              <div>
                <Avatar
                  type={'icon'}
                  size={'medium'}
                  shape={'square'}
                  iconClassName={'text-black'}
                  name={'hands-holding-dollar'}
                  iconVariant={'solid'}
                  family={'sharp'}
                />
              </div>
              <Typography variant={'label-small'} className={'truncate'}>
                {props.value}
              </Typography>
            </div>
          )
        },
        flex: 1,
        minWidth: 250,
        sort: 'desc',
      },
      ReferenceColDef(),
      CreatedAtColDef(),
      StatusColDef({
        headerName: t('fees'),
        context: 'ESCROW_FEE',
      }),
      StatusColDef({
        headerName: t('escrow_status'),
        context: 'ESCROW',
      }),
    ],
    [t]
  )

  return (
    <div
      className={`ag-theme-quartz ar-theme ${styles.grid} ${styles.no_cell_selection} h-full`}
    >
      <AgGridReact
        suppressContextMenu
        autoSizeStrategy={{
          type: 'fitGridWidth',
        }}
        gridOptions={{
          rowHeight: 72,
        }}
        rowData={props.data}
        onRowClicked={(e: RowClickedEvent<IEscrow>) =>
          navigate(`/escrow/${e.data?.id}`)
        }
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
      />
    </div>
  )
}
