import { t } from 'i18next'
import { LoaderFunction } from 'react-router-dom'

import {
  getDefendant,
  getDigitalByDefendantId,
  getOperationsByDefendantId,
  getPhysicalByDefendantId,
} from '@/api/broker/v2/defendants'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'
// import { ErrorHandler } from '@/errors/error.handler'
import { IAsset } from '@/types/asset'
import { IDefendant } from '@/types/defendants'
import { IOperation } from '@/types/operations'

export interface LoaderData {
  operations: IOperation[]
  assets: IAsset[]
  defendant: IDefendant
  error: null | string
}

export const loader: LoaderFunction = async ({ params }) => {
  try {
    if (!params.subject_id) return ErrorHandler(404, t('missing_params'))

    const res = await Promise.allSettled([
      getDigitalByDefendantId(params.subject_id),
      getPhysicalByDefendantId(params.subject_id),
      getOperationsByDefendantId(params.subject_id),
      getDefendant(params.subject_id),
    ])

    // We have to have a defendant object back otherwise we have nothing
    // to display - everything else is fine if its failed
    if (res[3].status == 'rejected' || res[3].value.data === null) {
      return ErrorHandler(404, ExtractErrorFrom(res[3]))
    }

    // Grab our values
    const _assets = [
      ...(res[0].status === 'fulfilled' && Array.isArray(res[0].value.data)
        ? res[0].value.data
        : []),
      ...(res[1].status === 'fulfilled' && Array.isArray(res[1].value.data)
        ? res[1].value.data
        : []),
    ]
    const _operations = [
      ...(res[2].status === 'fulfilled' && Array.isArray(res[2].value.data)
        ? res[2].value.data
        : []),
    ]
    const _defendant = res[3].value.data

    return {
      operations: _operations,
      assets: _assets,
      defendant: _defendant,
      error: null,
    } satisfies LoaderData
  } catch (error: unknown) {
    const message = ExtractErrorFrom(error)
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
