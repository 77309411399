import { refreshSession } from '@/api'
import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { useWorkspace } from '@/contexts/workspace'
import { roleTitleTranslated } from '@/helpers/roles'

import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<unknown> {}
const WorkspaceView = (props: Props) => {
  const { workspaces, selectedWorkspace, setWorkspace } = useWorkspace()

  const changeWorkspace = async (workspaceId: string) => {
    setWorkspace(workspaceId)
    await refreshSession()
    window.location.reload()
  }

  return (
    <DropdownView
      {...props}
      id={'WORKSPACE_SELECT'}
      testId={'dropdown_view_workspace'}
      items={[
        ...workspaces
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((w, i) => (
            <ListItem
              key={`item_workspace_${i}`}
              testId={`workspaces-nav-${w.name}`}
              title={w.name}
              description={w.role ? roleTitleTranslated(w.role) : '-'}
              leading={
                <Icon name={'sitemap'} family={'sharp'} variant={'solid'} />
              }
              {...(selectedWorkspace &&
                selectedWorkspace.id === w.id && {
                  trailing: (
                    <Icon
                      name="circle-check"
                      family={'sharp'}
                      variant={'solid'}
                      size={'medium'}
                    />
                  ),
                })}
              onClick={() => changeWorkspace(w.id)}
              className={'pl-2 pr-2'}
            />
          )),
      ]}
    />
  )
}
export default WorkspaceView
