import { t } from 'i18next'

import { updateCustodians } from '@/api/org'
import { updateOrg } from '@/api/org/v1/organization'
import { FormErrorsManager } from '@/contexts/formErrors'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ActionResponse } from '@/types/actions'
import { ICustodian } from '@/types/custodian'
export const EditOrganization = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const organization_name = formData.get('org_name')?.toString()
  const country = formData.get('country')?.toString()
  const currency = formData.get('currency')?.toString()
  const status_2fa = formData.get('two_factor_enabled') as unknown as string
  const existingAddress = formData.get('existing_address')?.toString()
  const customTracker = formData.get('custom_tracker')?.toString()
  const escrowEnabled = formData.get('escrow_enabled')?.toString()

  const org_id = formData.get('org_id')?.toString()

  if (!org_id) {
    return {
      error: true,
      message: t('no_organization_selected'),
      completion: 'COMPLETE_WITH_ERROR',
      data: null,
    }
  }

  const updatedCustodians = JSON.parse(
    (formData.get('custodian_obj') as string) || '[]'
  ) as ICustodian[]

  const organization = {
    name: organization_name,
    address: '',
    country,
    preferred_currency: currency,
    language: 'en',
    two_factor_disabled: status_2fa === 'true' ? false : true,
    existing_wallet_digital_assets_enabled: existingAddress === 'true',
    self_managed_digital_assets_enabled: customTracker === 'true',
    escrow_enabled: escrowEnabled === 'true',
  }

  try {
    await updateOrg(organization, org_id)
    await Promise.all(
      updatedCustodians?.map((_c: ICustodian) => {
        if (_c.status === 'ACTIVE') {
          return updateCustodians({
            organization_id: org_id,
            custodian_id: _c.id,
          })
        }
        return _c
      })
    )
  } catch (error) {
    const message = ExtractErrorFrom(error)
    return FormErrorsManager.addErrors([
      {
        fieldName: message?.includes('email')
          ? 'email'
          : message?.includes('name')
            ? 'org_name'
            : message?.includes('country')
              ? 'country'
              : '',
        error: message ?? t('something_went_wrong'),
      },
    ])
  }

  return {
    error: false,
    message: `'${organization_name}' ${t('updated_successfully')}`,
    completion: 'COMPLETE',
    data: null,
  }
}
