import { Icon } from '@/components/icon'

interface Props {
  ticker: string
  value: number
}

export const Asset: React.FC<Props> = ({ ticker, value }) => {
  return (
    <div className="flex items-center">
      <Icon size="medium" crypto={ticker} className="mr-3" />
      <div className="text-3xl font-extrabold mr-2">{value}</div>
      <div className="text-lg font-extrabold">{ticker}</div>
    </div>
  )
}
