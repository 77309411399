/*
 This file makes sure that we use the same rules that decide what is required when
 editing, when we display an asset and decide which fields to display
 */
import { t } from 'i18next'

import { Currency } from '@/constants/currencies'
import { isDefined } from '@/helpers/isDefined.ts'
import {
  AssetCategory,
  AssetTangibleType,
  DigitalAssetType,
  DigitalWalletSource,
  IAsset,
  ICreateAsset,
} from '@/types/asset.ts'
import { ILocation } from '@/types/common'

export enum RuleMode {
  EDIT,
  DISPLAY,
}

const serialRequiredCategories = [
  AssetCategory.VEHICLE,
  AssetCategory.REAL_ESTATE,
  AssetCategory.PERSONAL_PROPERTY,
]

export class AssetRules {
  private category?: AssetCategory
  private location?: ILocation
  private currency?: Currency
  private type?: DigitalAssetType | AssetTangibleType
  private walletSource?: DigitalWalletSource

  constructor(
    category?: AssetCategory,
    type?: DigitalAssetType | AssetTangibleType,
    currency?: Currency,
    location?: ILocation,
    walletSource?: DigitalWalletSource
  ) {
    this.category = category
    this.type = type
    this.currency = currency
    this.location = location
    this.walletSource = walletSource
  }

  // Creates a new AssetRules instance from an IAsset object
  public static fromAsset(
    asset?:
      | IAsset
      | ICreateAsset
      | {
          category: AssetCategory
          type?: DigitalAssetType | AssetTangibleType
          currency?: Currency
          location?: ILocation
          wallet?: { source: DigitalWalletSource }
        }
      | null
  ): AssetRules {
    if (!asset) {
      return new AssetRules()
    } else {
      return new AssetRules(
        asset.category,
        'type' in asset ? asset.type : undefined,
        'currency' in asset ? asset.currency : undefined,
        'location' in asset ? asset.location : undefined,
        'wallet' in asset ? asset.wallet?.source : undefined
      )
    }
  }

  public static getSerialMissingErrorMsg(category?: AssetCategory): string {
    switch (category) {
      case AssetCategory.VEHICLE:
        return t('vin_should_be_filled')
      case AssetCategory.REAL_ESTATE:
        return t('plot_number_must_be_filled')
      case AssetCategory.PERSONAL_PROPERTY:
      default:
        return t('serial_number_must_be_filled')
    }
  }

  public static isTangible(category?: AssetCategory): boolean {
    return (
      category === AssetCategory.REAL_ESTATE ||
      category === AssetCategory.PERSONAL_PROPERTY ||
      category === AssetCategory.VEHICLE ||
      category === AssetCategory.FIAT_CASH
    )
  }

  public static isSerialRequired(category?: AssetCategory): boolean {
    return isDefined(category) && serialRequiredCategories.includes(category)
  }

  public static canAddPhotos(
    mode: RuleMode,
    type?: DigitalAssetType | AssetTangibleType,
    category?: AssetCategory
  ): boolean {
    return (
      AssetRules.isTangible(category) &&
      AssetRules.isTypeDefinedWhenEditingOrTrue(mode, type)
    )
  }

  public static canEditDefendant(
    category?: AssetCategory,
    walletSource?: DigitalWalletSource
  ): boolean {
    return (
      category !== AssetCategory.DIGITAL ||
      walletSource !== DigitalWalletSource.GENERATED
    )
  }

  public static showManufacturer(
    mode: RuleMode,
    type?: DigitalAssetType | AssetTangibleType,
    category?: AssetCategory
  ): boolean {
    return (
      category === AssetCategory.VEHICLE &&
      AssetRules.isTypeDefinedWhenEditingOrTrue(mode, type)
    )
  }

  public static showModel(
    mode: RuleMode,
    type?: DigitalAssetType | AssetTangibleType,
    category?: AssetCategory
  ): boolean {
    return (
      category === AssetCategory.VEHICLE &&
      AssetRules.isTypeDefinedWhenEditingOrTrue(mode, type)
    )
  }

  public static showCountryAndAddress(
    mode: RuleMode,
    type?: DigitalAssetType | AssetTangibleType,
    category?: AssetCategory,
    location?: ILocation
  ): boolean {
    return (
      (category === AssetCategory.REAL_ESTATE || isDefined(location)) &&
      AssetRules.isTypeDefinedWhenEditingOrTrue(mode, type)
    )
  }

  public static showSerial(
    mode: RuleMode,
    type?: DigitalAssetType | AssetTangibleType,
    category?: AssetCategory
  ): boolean {
    return (
      isDefined(category) &&
      serialRequiredCategories.includes(category) &&
      AssetRules.isTypeDefinedWhenEditingOrTrue(mode, type)
    )
  }

  public static showValuationAndCost(
    mode: RuleMode,
    type?: DigitalAssetType | AssetTangibleType,
    category?: AssetCategory,
    currency?: Currency
  ): boolean {
    const hasCurrencyInEditing =
      mode === RuleMode.EDIT ? isDefined(currency) : true
    return (
      (category === AssetCategory.FIAT_CASH && hasCurrencyInEditing) ||
      (AssetRules.isTangible(category) &&
        AssetRules.isTypeDefinedWhenEditingOrTrue(mode, type))
    )
  }

  private static isTypeDefinedWhenEditingOrTrue(
    mode?: RuleMode,
    type?: DigitalAssetType | AssetTangibleType
  ): boolean {
    if (mode === RuleMode.EDIT) {
      return isDefined(type)
    }
    return true
  }

  private modeFunctionWrapper(mode: RuleMode) {
    return {
      Can: {
        Add: {
          Photos: () => AssetRules.canAddPhotos(mode, this.type, this.category),
        },
        Edit: {
          Defendant: () =>
            AssetRules.canEditDefendant(this.category, this.walletSource),
        },
      },
      Show: {
        Manufacturer: () =>
          AssetRules.showManufacturer(mode, this.type, this.category),
        Model: () => AssetRules.showModel(mode, this.type, this.category),
        CountryAndAddress: () =>
          AssetRules.showCountryAndAddress(
            mode,
            this.type,
            this.category,
            this.location
          ),
        Serial: () => AssetRules.showSerial(mode, this.type, this.category),
        ValuationAndCost: () =>
          AssetRules.showValuationAndCost(
            mode,
            this.type,
            this.category,
            this.currency
          ),
      },
    }
  }

  IsTangible(): boolean {
    return AssetRules.isTangible(this.category)
  }

  IsSerialRequired(): boolean {
    return AssetRules.isSerialRequired(this.category)
  }

  GetSerialMissingErrorMsg(): string {
    return AssetRules.getSerialMissingErrorMsg(this.category)
  }

  WhenEditing = this.modeFunctionWrapper(RuleMode.EDIT)
  WhenDisplaying = this.modeFunctionWrapper(RuleMode.DISPLAY)
}
