import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import { updateOperation } from '@/api/broker/v2/operations'
import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { ROUTES } from '@/constants/routes'
import { useAuth } from '@/contexts/auth'
import { BannerManager } from '@/contexts/banner'
import { useDrawer } from '@/contexts/interface'
import { ToastManager } from '@/contexts/toast'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { useOperations } from '@/hooks/queries/useOperations'
import { IOperation } from '@/types/operations'

import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<unknown> {
  case: IOperation
}
const CaseActionsView = (props: Props) => {
  const { setDrawer } = useDrawer()
  const { user } = useAuth()
  const { refetchOperations } = useOperations()
  const navigate = useNavigate()

  const handleRestoreCase = async () => {
    const res = await updateOperation(props.case.id, {
      ...props.case,
      status: 'ACTIVE',
    })
    if (res.error_code || res.error) {
      BannerManager.showBanner({
        variant: 'page',
        type: 'error',
        title: t('something_went_wrong'),
        description: ExtractErrorFrom(res),
      })
    } else {
      await refetchOperations()
      ToastManager.showToast({
        type: 'success',
        text: `Case '${props.case.name}' restored successfully.`,
      })
      navigate(ROUTES.CASES.INDEX)
    }
  }

  const handleArchiveCase = async () => {
    const res = await updateOperation(props.case.id, {
      ...props.case,
      status: 'ARCHIVED',
    })
    if (res.error_code || res.error) {
      BannerManager.showBanner({
        variant: 'page',
        type: 'error',
        title: t('something_went_wrong'),
        description: ExtractErrorFrom(res),
      })
    } else {
      await refetchOperations()
      ToastManager.showToast({
        type: 'success',
        text: `Case '${props.case.name}' archived successfully.`,
      })
      navigate('/cases')
    }
  }

  const handleNavigateToDetails = () => {
    navigate(
      ROUTES.CASES.DETAIL.replace(':case_id', props.case.id).replace(
        ':case_id',
        props.case.id
      )
    )
  }

  const handleEditCase = () => {
    setDrawer({
      id: 'EDIT_CASE',
      case: props.case,
      onUpdated: () => {
        document.querySelector('main')?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      },
    })
  }

  const handleAddAsset = () => {
    setDrawer({
      id: 'CASE_ADD_EXISTING_ASSETS',
      case: props.case,
      onUpdated: () => {
        document.querySelector('main')?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      },
    })
  }

  const { policies } = useAuth()
  const canEditCase = policies?.includes('CUSTODY.MANAGE_OPERATION')
  const canArchiveCase = policies?.includes('CUSTODY.ARCHIVE_OPERATION')

  const adminOptions =
    props.case.status === 'ARCHIVED'
      ? [
          ...(canArchiveCase
            ? [
                {
                  title: t('restore'),
                  testId: 'btn_restore',
                  icon: 'rotate-left',
                  onClick: handleRestoreCase,
                },
              ]
            : []),
        ]
      : [
          ...(canArchiveCase
            ? [
                {
                  title: t('archive_case'),
                  testId: 'btn_archive',
                  icon: 'box-archive',
                  onClick: handleArchiveCase,
                },
              ]
            : []),
          ...(canEditCase
            ? [
                {
                  title: t('edit_case'),
                  testId: 'btn_edit',
                  icon: 'edit',
                  onClick: handleEditCase,
                },
              ]
            : []),
          {
            title: t('view_details'),
            testId: 'btn_view_details',
            icon: 'eye',
            onClick: handleNavigateToDetails,
          },
        ]

  const userOptions =
    props.case.status === 'ARCHIVED'
      ? []
      : [
          ...(canEditCase
            ? [
                {
                  title: t('add_asset'),
                  testId: 'btn_add_asset',
                  icon: 'square-plus',
                  onClick: handleAddAsset,
                },
              ]
            : []),
          ...(canEditCase
            ? [
                {
                  title: t('edit_case'),
                  testId: 'btn_edit',
                  icon: 'edit',
                  onClick: handleEditCase,
                },
              ]
            : []),
          {
            title: t('view_details'),
            testId: 'btn_view_details',
            icon: 'eye',
            onClick: handleNavigateToDetails,
          },
        ]

  return (
    <DropdownView
      {...props}
      id={'CASE_ACTIONS'}
      items={(['super', 'admin'].includes(user?.role ?? '')
        ? adminOptions
        : userOptions
      ).map((v, i) => (
        <ListItem
          key={`item_${i}`}
          title={v.title}
          testId={v.testId}
          leading={
            <Icon
              size={'medium'}
              family={'sharp'}
              name={v.icon}
              variant={'solid'}
            />
          }
          className="pl-2 pr-2"
          onClick={v.onClick}
        />
      ))}
    />
  )
}
export default CaseActionsView
