import { t } from 'i18next'

import { updateAsset } from '@/api'
import { uploadFiles } from '@/api/files'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { key as QueryAssetKey } from '@/hooks/queries/useAsset'
import { ActionResponse } from '@/types/actions'
import { IAsset } from '@/types/asset.ts'
import { IFile } from '@/types/file'

export const AddAssetDocument = async (
  formData: FormData
): Promise<ActionResponse<IFile[]>> => {
  const assetStr = formData.get('original_asset')?.toString()
  // Validate some bits...
  if (!assetStr) {
    throw new Error('Invalid asset')
  }
  const asset = JSON.parse(assetStr) as IAsset

  let uploadedFiles: IFile[] = []

  // Grab the new files from the form and ignore any
  // files that are not valid
  const formFiles = [...formData.getAll('document_new_files')]?.filter(
    (file) => {
      return file && file instanceof File && file.size !== 0
    }
  ) as File[]

  // Check if we have any files to upload
  if (formFiles.length > 0) {
    // Upload the files and get back a list of keys
    uploadedFiles = await uploadFiles(formFiles)
  } else {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'document_new_files',
        error: t('no_new_files_attached'),
      },
    ])
  }

  // Build a collection of all files
  const allFiles = [...(asset?.files ?? []), ...uploadedFiles]
  const newAsset: IAsset = { ...asset, files: allFiles }
  const res = await updateAsset(newAsset)

  // Check if the call failed
  if (res.error_code || res.error) {
    throw new Error(ExtractErrorFrom(res))
  }

  await queryClient.invalidateQueries({
    queryKey: [QueryAssetKey, newAsset.id],
  })

  return {
    error: false,
    message:
      formFiles.length > 1
        ? t('new_documents_added_successfully')
        : t('new_document_added_successfully'),
    completion: 'COMPLETE',
    data: allFiles,
  }
}
