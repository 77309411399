import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import { IEscrowFile } from '@/types/escrows'
import { IFile, IFileKey, IFileMetadata } from '@/types/file'

export const getFileMetadata = async (
  fileKeys: string | string[],
  sessionToken?: string
) => {
  const client = await getClient(getConfig().BROKER_API_URL)
  let metadata: IFileMetadata[] = []

  // If we've been given a single string then lets place it into an array
  // so it follows the flow nicer
  if (!Array.isArray(fileKeys)) {
    fileKeys = [fileKeys]
  }

  // We might need to break the file keys up into groups but we'll
  // do that here so it doesn't need to be handled in the ui
  const keyChunks: string[][] = []
  const chunkSize: number = 50
  for (let i = 0; i < fileKeys.length; i += chunkSize) {
    keyChunks.push(fileKeys.slice(i, i + chunkSize))
  }

  // Run through each chunk
  for (const chunk of keyChunks) {
    await client
      .get<BaseResponse<IFileMetadata[]>>(
        `files?keys=${chunk.join(',')}`,
        sessionToken
          ? {
              headers: {
                'AR-Token': sessionToken,
              },
            }
          : {}
      )
      .then((res) => {
        const metadataRes = res.data
        if (Array.isArray(metadataRes.data)) {
          metadata = [...metadata, ...metadataRes.data]
        }
      })
  }

  // Run through and alter the url where necessary - this should only
  // be required whilst in test environments
  if (['localdev'].includes(import.meta.env.MODE)) {
    metadata = metadata.map((m) => {
      return {
        ...m,
        url: m.url.replace('http://localstack', 'http://localhost'),
      }
    })
  }

  // Return the final collection
  return metadata
}

export const uploadFiles = async (files: File[]): Promise<IFile[]> => {
  const keys: IFile[] = []

  // Check we have some files to return
  if (!files.length) return keys

  // Create the api client
  const client = await getClient(getConfig().BROKER_API_URL, undefined, {
    'Content-Type': 'multipart/form-data',
  })

  // Loop through each file and upload the file
  for (const file of files) {
    // Create the new formdata to push this file up
    const formData = new FormData()
    formData.append('file', file as File)

    // Upload the file
    const uploadRes = await client
      .post<BaseResponse<IFileKey>>(`files`, formData)
      .then((response) => {
        return response.data
      })

    // Check to make sure we uploaded ok
    if (uploadRes.error_code || uploadRes.error || uploadRes.data === null) {
      // Failed to upload this file - skip for now
      // We should likely report this to the user
      console.error('failed to upload file ', file.name)
      continue
    }

    // The type we send to the BE must be one of 'DOC', 'PDF' or
    // 'IMG' so we'll convert the blob type into this here.
    const getType = (blobType: string): 'DOC' | 'PDF' | 'IMG' => {
      const type = blobType.toLowerCase()
      if (type.includes('pdf')) {
        return 'PDF'
      } else if (type.includes('image')) {
        return 'IMG'
      } else {
        return 'DOC'
      }
    }

    // Otherwise we should be OK to push the uploaded key into
    // our 'success' collection for processing
    const metadata = await getFileMetadata(uploadRes.data.key)

    keys.push({
      id: '',
      key: uploadRes.data.key,
      name: file.name,
      type: getType(file.type),
      created_at: new Date().toISOString(),
      metadata: metadata[0],
    })
  }

  return keys
}

export const downloadFile = async (
  file: IFile | IEscrowFile,
  sessionToken?: string
) => {
  // Get metadata for the file if the file hasn't got it already
  if (!file.metadata || !file.metadata.url) {
    const metadata = await getFileMetadata(file.key, sessionToken)
    file.metadata = metadata[0]
  }

  // Check we have access to window
  if (!window) {
    return
  }

  // Download the file
  const fetchRes = await fetch(file.metadata.url)
  const blob = await fetchRes.blob()

  // Deliver the blob to the user
  const blobUrl = window.URL.createObjectURL(blob)
  const anchor = window.document.createElement('a')
  anchor.download = file.name
  anchor.href = blobUrl
  anchor.click()
  window.URL.revokeObjectURL(blobUrl)
}

// export const getFiles = async () => {
//   const client = await getClient((await getConfig()).BROKER_API_URL)
// }
