import { t } from 'i18next'
import { LoaderFunction } from 'react-router-dom'

import { getUser } from '@/api/auth'
import { AuthManager } from '@/contexts/auth'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'

export const loader: LoaderFunction = async () => {
  try {
    const res = await getUser()

    // Check for errors
    if (!res.data) {
      return ErrorHandler(404, ExtractErrorFrom(res, t('user_not_found')))
    }

    // Set auth user
    await AuthManager.setUser({
      ...res.data,
    })

    return { user: res.data }
  } catch (error: unknown) {
    const message = ExtractErrorFrom(error)
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
