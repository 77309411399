import { matchPath } from 'react-router-dom'

import { ROUTES } from '@/constants/routes'

type RouteObject = {
  [key: string]: string | RouteObject
}

export const isKnownRoute = (checkRoute: string) => {
  const validRoutes: string[] = []

  const flattenRoutes = (obj: RouteObject) => {
    Object.values(obj).forEach((value) => {
      if (typeof value === 'string') {
        validRoutes.push(value)
      } else if (typeof value === 'object') {
        flattenRoutes(value)
      }
    })
  }
  flattenRoutes(ROUTES)

  return validRoutes.some((route) => matchPath(route, checkRoute))
}
