import { LoaderFunction } from 'react-router-dom'

import { getAllCustodians } from '@/api/org'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'
import { ICustodian } from '@/types/custodian'

export type LoaderData = {
  custodians: ICustodian[]
} | null
export const loader: LoaderFunction = async () => {
  try {
    // Get any data we might require
    const res = await getAllCustodians()

    // Keep things neat by naming our res values
    const _custodians = res?.data || []
    // Push the data to the page
    return {
      custodians: _custodians,
    } satisfies LoaderData
  } catch (error: unknown) {
    console.log(error)
    const message = ExtractErrorFrom(error)
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
