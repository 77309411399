import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useRef,
  useState,
} from 'react'

import DropdownContext from '@/components/dropdown/components/context.dropdown'

import BasicListController from './controllers/basic-list.controller'
import CustodianActionsController from './controllers/custodian-actions.controller'
import SuggestionController from './controllers/suggestion.controller'

type Props = {
  dropdownContent: ReactElement
  maxHeightPx?: number
  maxWidthPx?: number
  offsetYPx?: number
  children:
    | ReactNode
    | (({
        isOpen,
        setIsOpen,
      }: {
        isOpen: boolean
        setIsOpen: Dispatch<SetStateAction<boolean>>
      }) => ReactNode)
}

export const Dropdown = ({
  dropdownContent,
  maxHeightPx,
  maxWidthPx,
  offsetYPx,
  children,
}: Props) => {
  const ref = useRef<HTMLElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {typeof children === 'function' ? (
        <span ref={ref}>{children({ isOpen, setIsOpen })}</span>
      ) : (
        <span onClick={() => setIsOpen(true)} ref={ref}>
          {children}
        </span>
      )}

      {isOpen && (
        <DropdownContext
          target={ref}
          maxHeightPx={maxHeightPx}
          maxWidthPx={maxWidthPx}
          offsetYPx={offsetYPx}
          onChangeVisibility={(newValue) => setIsOpen(newValue)}
        >
          {dropdownContent}
        </DropdownContext>
      )}
    </>
  )
}

export default {
  Controllers: {
    BasicList: BasicListController,
    Suggestion: SuggestionController,
    CustodianAction: CustodianActionsController,
  },
}
