import { FormattedMessage } from 'react-intl'

import { Item } from './Item'
import { Section } from './Section'
import { Title } from './Title'

interface Props {
  name: string
  date: string
  time: string
}

export const RequestedBy: React.FC<Props> = ({ name, date, time }) => {
  return (
    <div className="mb-6">
      <Title>
        <FormattedMessage defaultMessage="Requested by" id="eVpsU0" />
      </Title>
      <Section>
        <div className="mb-2">
          <Item icon="user-hair" text={name} />
        </div>
        <div className="flex">
          <div className="flex-1">
            <Item icon="calendar-alt" text={date} />
          </div>
          <div className="flex-1">
            <Item icon="clock" text={time} />
          </div>
        </div>
      </Section>
    </div>
  )
}
