import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIntl } from 'react-intl'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'
import Card from '@/components/card'
import { Dropdown } from '@/components/dropdown'
import BasicListController from '@/components/dropdown/controllers/basic-list.controller'
import BottomPadding from '@/components/footer/BottomPadding.tsx'
import Graphs from '@/components/graphs'
import { GraphData } from '@/components/graphs/types'
import { Group } from '@/components/group'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import { useAuth } from '@/contexts/auth'
import { useDrawer } from '@/contexts/interface'
import { isEnumValue } from '@/helpers/enums.ts'
import { useDigitalAssets } from '@/hooks/queries/useDigitalAssets'
import { useOrg } from '@/hooks/queries/useOrg'
import { usePhysicalAssets } from '@/hooks/queries/usePhysicalAssets'
import {
  AssetCategory,
  DigitalAssetType,
  TangibleTypeCollectibles,
  TangibleTypeElectronics,
  TangibleTypeJewellery,
} from '@/types/asset.ts'

export const Assets: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const intl = useIntl()
  const { setDrawer } = useDrawer()
  const { org } = useOrg()
  const { assets: digitalAssets } = useDigitalAssets('ANY_OPERATIONS')
  const { assets: physicalAssets } = usePhysicalAssets('ANY_OPERATIONS')
  const [_chartItems, setChartItems] = useState<GraphData[]>([])

  useEffect(() => {
    const assets = [...(digitalAssets ?? []), ...(physicalAssets ?? [])]
    let tokensValue = 0
    let nftsValue = 0
    let fiatValue = 0
    let automotiveValue = 0
    let realestateValue = 0
    let electronicsValue = 0
    let jewelryValue = 0
    let collectiblesValue = 0
    let unkownTypeValue = 0

    for (const asset of assets) {
      const assetPriceValue = asset.price?.value ?? 0
      const category = asset.category
      switch (category) {
        case AssetCategory.FIAT_CASH:
          fiatValue += assetPriceValue
          break
        case AssetCategory.DIGITAL:
          if (asset.type === DigitalAssetType.NFT) {
            nftsValue += assetPriceValue
          } else {
            tokensValue += assetPriceValue
          }
          break
        case AssetCategory.REAL_ESTATE:
          realestateValue += assetPriceValue
          break
        case AssetCategory.VEHICLE:
          automotiveValue += assetPriceValue
          break
        case AssetCategory.PERSONAL_PROPERTY:
          if (isEnumValue(TangibleTypeElectronics, asset.type)) {
            electronicsValue += assetPriceValue
          } else if (isEnumValue(TangibleTypeJewellery, asset.type)) {
            jewelryValue += assetPriceValue
          } else if (isEnumValue(TangibleTypeCollectibles, asset.type)) {
            collectiblesValue += assetPriceValue
          } else {
            unkownTypeValue += assetPriceValue
            console.error('unexpected category ' + category)
          }

          break
        default:
          unkownTypeValue += assetPriceValue
          console.error('unexpected category ' + category)
          break
      }
    }

    setChartItems([
      {
        label: t('tokens'),
        value: tokensValue,
        htmlColor: '#FF7124',
      },
      {
        label: t('nfts'),
        value: nftsValue,
        htmlColor: '#84B6E8',
      },
      {
        label: t('fiat_cash'),
        value: fiatValue,
        htmlColor: '#53AB64',
      },
      {
        label: t('automotive'),
        value: automotiveValue,
        htmlColor: '#909293',
      },
      {
        label: t('real_estate'),
        value: realestateValue,
        htmlColor: '#EC798B',
      },
      {
        label: t('electronics'),
        value: electronicsValue,
        htmlColor: '#FFC42D',
        hideIfZero: true,
      },
      {
        label: t('jewellery'),
        value: jewelryValue,
        htmlColor: '#FFA9A9',
      },
      {
        label: t('collectibles'),
        value: collectiblesValue,
        htmlColor: '#FFD7B5',
        hideIfZero: true,
      },
      {
        label: t('other'),
        value: unkownTypeValue,
        htmlColor: '#606293',
        hideIfZero: true,
      },
    ])
  }, [t, digitalAssets, physicalAssets])

  const { hasPolicy } = useAuth()
  const canCreateAsset = hasPolicy(
    [
      'CUSTODY.MANAGE_DIGITAL_ASSET',
      'CUSTODY.MANAGE_PHYSICAL_ASSET',
      'CUSTODY.MANAGE_DEFENDANT',
    ],
    true
  )
  const canImportAsset =
    canCreateAsset &&
    hasPolicy('CUSTODY.IMPORT_ASSET') &&
    ['staging', 'development', 'localdev'].includes(import.meta.env.MODE)

  return (
    <>
      <div className="flex flex-col p-6 pt-4 gap-8 desktop:px-8 desktop:py-4">
        <Paragraph
          title={intl.formatMessage({ defaultMessage: 'Assets', id: 'd1uESJ' })}
          description={t('assets_description')}
          spacerOverrides={{ description: [] }}
        />

        {canCreateAsset && canImportAsset ? (
          <Dropdown
            maxWidthPx={270}
            dropdownContent={
              <BasicListController
                closeOnItemClick
                items={[
                  <ListItem
                    key={'create_asset'}
                    leading={
                      <Icon
                        name={'plus'}
                        family={'sharp'}
                        variant={'solid'}
                        size={'medium'}
                      />
                    }
                    title={t('create_asset')}
                    className={'px-2'}
                    onClick={() => {
                      setDrawer({ id: 'NEW_ASSET' })
                    }}
                  />,
                  <ListItem
                    key={'import_assets'}
                    leading={
                      <Icon
                        name={'file-import'}
                        family={'sharp'}
                        variant={'solid'}
                        size={'medium'}
                      />
                    }
                    title={t('import_assets')}
                    className={'px-2'}
                    onClick={() => {
                      setDrawer({ id: 'IMPORT_ASSETS' })
                    }}
                  />,
                ]}
              />
            }
          >
            {({ setIsOpen }) => (
              <Button.Basic
                id="btn_add_asset"
                label={t('add_asset')}
                testId={'btn_add_new_asset'}
                icon={{
                  name: 'plus',
                }}
                onClick={() => {
                  setIsOpen(true)
                }}
                className="hidden tablet:flex"
              />
            )}
          </Dropdown>
        ) : canCreateAsset ? (
          <Button.Basic
            id="btn_add_asset"
            label={t('add_new_asset')}
            testId={'btn_add_new_asset'}
            icon={{
              name: 'plus',
            }}
            onClick={() => {
              setDrawer({ id: 'NEW_ASSET' })
            }}
            className="hidden tablet:flex"
          />
        ) : null}

        <div className={'flex max-w-[50rem] flex-col items-start gap-[16px]'}>
          <div
            className={
              'flex items-start content-start gap-[16px] self-stretch w-full flex-wrap'
            }
          >
            <Card.Overview
              value={
                [...(digitalAssets ?? []), ...(physicalAssets ?? [])].length
              }
              valueDescriptor={t('total_assets')}
              className={'flex flex-1 max-w-none desktop:max-w-none'}
            />
            <Card.Overview
              value={(physicalAssets ?? []).length}
              valueDescriptor={t('tangible')}
              className={
                'hidden tablet:flex flex-1 max-w-none desktop:max-w-none'
              }
            />
            <Card.Overview
              value={(digitalAssets ?? []).length}
              valueDescriptor={t('digital')}
              className={
                'hidden tablet:flex flex-1 max-w-none desktop:max-w-none'
              }
            />
          </div>
          <div className={'flex flex-col items-start gap-[16px] self-stretch'}>
            {_chartItems && (
              <Group
                className={twMerge(
                  'flex flex-col p-[32px] items-center content-center gap-[32px] self-stretch flex-wrap',
                  'tablet:flex-row'
                )}
                shadow={true}
              >
                <Graphs.Donut
                  data={_chartItems}
                  currency={org?.preferred_currency}
                  format={'CURRENCY'}
                  width={250}
                  height={250}
                  title={t('breakdown_of_value')}
                  showTotal={true}
                  showLegend={true}
                  className={'hidden tablet:flex'}
                />
                <Graphs.Bar
                  data={_chartItems}
                  currency={org?.preferred_currency}
                  format={'CURRENCY'}
                  zeroLabel={t('assets')}
                  showTotal={true}
                  showLegend={true}
                  className={'flex tablet:hidden'}
                />
              </Group>
            )}
          </div>
        </div>

        {[...(digitalAssets ?? []), ...(physicalAssets ?? [])].length > 0 && (
          <Table.Assets
            testId={'table_assets'}
            data={[...(digitalAssets ?? []), ...(physicalAssets ?? [])]}
          />
        )}

        <BottomPadding />

        {canCreateAsset && (
          <Dropdown
            dropdownContent={
              <BasicListController
                closeOnItemClick
                items={[
                  <ListItem
                    key={'create_asset'}
                    leading={
                      <Icon
                        name={'plus'}
                        family={'sharp'}
                        variant={'solid'}
                        size={'medium'}
                      />
                    }
                    title={t('create_asset')}
                    className={'px-2'}
                    onClick={() => {
                      setDrawer({ id: 'NEW_ASSET' })
                    }}
                  />,
                  <ListItem
                    key={'import_assets'}
                    leading={
                      <Icon
                        name={'file-import'}
                        family={'sharp'}
                        variant={'solid'}
                        size={'medium'}
                      />
                    }
                    title={t('import_assets')}
                    className={'px-2'}
                    onClick={() => {
                      setDrawer({ id: 'IMPORT_ASSETS' })
                    }}
                  />,
                ]}
              />
            }
          >
            {({ setIsOpen }) => (
              <Button.Fab
                id={'btn_fab_add_asset'}
                label={t('add_new_asset')}
                icon={{
                  name: 'plus',
                }}
                onClick={() => {
                  if (canCreateAsset && canImportAsset) {
                    setIsOpen(true)
                  } else {
                    setDrawer({ id: 'NEW_ASSET' })
                  }
                }}
              />
            )}
          </Dropdown>
        )}
      </div>
    </>
  )
}
