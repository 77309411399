import { ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { Props as AvatarProps } from '../../avatar'
import { Props as AvatarSingleProps } from '../../avatar/variants/single.avatar'
import { Props as BadgeProps } from '../../badge'
import { Props as ButtonProps } from '../../button'
import { Props as ButtonBasicProps } from '../../button/variants/basic.button'
import { Props as ButtonShapeProps } from '../../button/variants/shape.button'
import { Props as CheckboxProps } from '../../checkbox'
import { Props as IconProps } from '../../icon'
import { Props as RadioProps } from '../../radio'
import { Props as SwitchProps } from '../../switch'
import { ListItemLabel, Props as ListItemLabelProps } from './label.listitem'

type LeadingOption =
  | ReactElement<IconProps>
  | ReactElement<AvatarProps>
  | ReactElement<AvatarSingleProps>

type TrailingOption =
  | ReactElement<IconProps>
  | ReactElement<AvatarProps>
  | ReactElement<AvatarSingleProps>
  | ReactElement<ButtonProps>
  | ReactElement<ButtonBasicProps>
  | ReactElement<ButtonShapeProps>
  | ReactElement<BadgeProps>
  | ReactElement<CheckboxProps>
  | ReactElement<RadioProps<unknown>>
  | ReactElement<SwitchProps>

export interface Props<T> extends Omit<ListItemLabelProps, 'size' | 'state'> {
  id?: string
  value?: HTMLInputElement['value']
  leading?: LeadingOption
  trailing?: TrailingOption
  state?: 'default' | 'disabled' | 'selected' | 'error'
  onClick?: () => void
  data?: T
  className?: string
  testId?: string
}

export const ListItemBasic = <T,>(props: Props<T>) => {
  return (
    <div
      id={props.id}
      className={twMerge(
        'flex flex-1 flex-row rounded-md gap-4 items-center w-full h-12 min-h-12 border-[1px] border-solid border-transparent',
        'transition-[border-color,background-color] duration-300 ease-in-out',
        props.onClick &&
          !['disabled', 'error'].includes(props.state ?? '') &&
          'hover:bg-gray-100 hover:cursor-pointer active:bg-gray-100',
        props.state === 'disabled'
          ? 'border-transparent bg-transparent'
          : props.state === 'error'
            ? 'border-[#FFC42D] bg-[#FFC42D]'
            : props.state === 'selected'
              ? 'bg-gray-100 border-gray-100'
              : '',
        props.className
      )}
      onClick={props.onClick && props.onClick}
      data-testid={props.testId ?? 'dropdown-item'}
    >
      {props.leading && (
        <div className={'flex items-center justify-center'}>
          {props.leading}
        </div>
      )}
      <div className={'flex flex-1 overflow-hidden'}>
        <ListItemLabel
          title={props.title}
          description={props.description}
          trailingLabel={props.trailingLabel}
          size={'medium'}
          style={props.style}
          state={props.state === 'disabled' ? 'disabled' : 'default'}
          textAlignment={props.textAlignment}
          withClass={twMerge('flex-wrap', props.withClass)}
        />
      </div>
      {props.trailing && (
        <div className={twMerge('flex items-center justify-center')}>
          {props.trailing}
        </div>
      )}
    </div>
  )
}
