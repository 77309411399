import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Avatar } from '@/components/avatar'
import Button from '@/components/button'
import { Divider } from '@/components/divider'
import { Dropdown } from '@/components/dropdown'
import NavigationController from '@/components/dropdown/controllers/navigation.controller'
import ProfileController from '@/components/dropdown/controllers/profile.controller'
import { ListItem } from '@/components/list-item'
import { useAuth } from '@/contexts/auth'
import { useWorkspace } from '@/contexts/workspace'
import { useOrg } from '@/hooks/queries/useOrg'
import { useWindowSize } from '@/hooks/useWindowSize'

import Searchbar from './searchbar'

export const Header = () => {
  const { user } = useAuth()
  const { org } = useOrg()
  const { isTablet } = useWindowSize()
  const { selectedWorkspace } = useWorkspace()
  const [_mobileSearch, setMobileSearch] = useState<boolean>(false)

  useEffect(() => {
    if (_mobileSearch) {
      setMobileSearch(false)
    }
  }, [isTablet])

  return (
    <div
      className={twMerge(
        'max-h-[4.5] min-h-[4.5rem]',
        'sticky top-0 z-40 self-center',
        'px-4 2xl:px-8',
        'w-full max-w-[2160px]',
        'flex flex-col items-center bg-white'
      )}
      data-testid="sticky-header"
    >
      <div
        className={'flex flex-1 relative flex-row items-center w-full gap-4 '}
      >
        <div
          className={twMerge(
            'tablet:hidden',
            'flex flex-1 items-center justify-start',
            'tablet:flex-none'
          )}
        >
          <Dropdown dropdownContent={<NavigationController />}>
            {({ setIsOpen, isOpen }) => (
              <Avatar
                type={'icon'}
                size={'medium'}
                name={isOpen ? 'close' : 'bars'}
                family={'sharp'}
                iconVariant={'solid'}
                shape={'round'}
                onClick={() => {
                  setIsOpen(true)
                }}
                className={'flex 2xl:hidden bg-red-500'}
              />
            )}
          </Dropdown>
        </div>
        <div className={twMerge('hidden tablet:flex', 'flex-1')}>
          {user?.role !== 'super' && <Searchbar />}
        </div>
        <div className="flex items-center gap-x-4">
          <Dropdown
            maxHeightPx={800}
            offsetYPx={24}
            dropdownContent={<ProfileController />}
          >
            {({ setIsOpen }) => (
              <ListItem
                testId="user-menu"
                title={user?.name}
                description={selectedWorkspace?.name ?? org?.name ?? ''}
                style={'bold'}
                textAlignment={'right'}
                trailing={
                  <Avatar
                    type={'monogram'}
                    shape={'round'}
                    size={'medium'}
                    value={user?.name ?? ''}
                  />
                }
                onClick={() => {
                  setIsOpen(true)
                }}
                className={
                  'hover:cursor-pointer hover:bg-transparent active:bg-transparent max-w-[Min(60vw,15rem)]'
                }
              />
            )}
          </Dropdown>
        </div>
        {user?.role !== 'super' && (
          <>
            <Button.Shape
              layout={'icon'}
              shape={'round'}
              icon={{ name: 'search' }}
              hierarchy={'secondary'}
              onClick={() => setMobileSearch(true)}
              className={'tablet:hidden'}
            />
            <div
              className={twMerge(
                'absolute hidden inset-0 bg-white',
                'items-center gap-4',
                'tablet:hidden',
                _mobileSearch && 'flex'
              )}
            >
              <Searchbar onResultsHidden={() => setMobileSearch(false)} />
              <Button.Shape
                layout={'icon'}
                shape={'round'}
                icon={{ name: 'close' }}
                hierarchy={'secondary'}
                onClick={() => setMobileSearch(false)}
              />
            </div>
          </>
        )}
      </div>
      <Divider />
    </div>
  )
}

export default Header
