import { t } from 'i18next'
import { LoaderFunction, redirect } from 'react-router-dom'

import { getDigitalByCaseId, getPhysicalByCaseId } from '@/api/broker/v2/assets'
import { getOperation } from '@/api/broker/v2/operations'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'
import { IAsset } from '@/types/asset'
import { IOperation } from '@/types/operations'

export type LoaderData = {
  operation: IOperation
  assets: IAsset[]
} | null
export const loader: LoaderFunction = async ({ params }) => {
  try {
    if (!params.case_id) return ErrorHandler(404, t('missing_params'))

    // Get any data we might require
    const res = await Promise.allSettled([
      getDigitalByCaseId(params.case_id),
      getPhysicalByCaseId(params.case_id),
      getOperation(params.case_id),
    ])

    // We should only error out if we haven't managed to get the operation
    // data as this is required for the page - if the assets have failed
    // that OK - it'll confuse the user but theres no point failing the entire page
    if (res[2].status == 'rejected' || res[2].value.data === null) {
      return ErrorHandler(404, ExtractErrorFrom(res[2]))
    }

    // Grab out the values from our promises
    const _assets = [
      ...(res[0].status === 'fulfilled' && Array.isArray(res[0].value.data)
        ? res[0].value.data
        : []),
      ...(res[1].status === 'fulfilled' && Array.isArray(res[1].value.data)
        ? res[1].value.data
        : []),
    ]
    const _operation = res[2].value.data

    // Check the case is OK to be viewed
    if (_operation.status === 'ARCHIVED') {
      return redirect('/cases?filter=archived')
    }

    // Push the data to the page
    return {
      operation: _operation,
      assets: _assets,
    } satisfies LoaderData
  } catch (error: unknown) {
    const message = ExtractErrorFrom(error, t('failed_to_load_case'))
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
