import { ColDef } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { t } from 'i18next'

import Chip from '@/components/chip'
import { findLastStatus } from '@/helpers/status'
import { IAsset } from '@/types/asset'
import { IEscrow, IEscrowFee } from '@/types/escrows'
import { IOperation } from '@/types/operations'
import { IUser } from '@/types/user'
import { IWorkspaceMember } from '@/types/workspace'

import { ColDefOptions } from './_options.coldef'

interface Options<T> extends Omit<ColDefOptions<T>, 'field'> {
  context:
    | 'ESCROW'
    | 'ESCROW_FEE'
    | 'ASSET'
    | 'OPERATION'
    | 'USER'
    | 'WORKSPACE_MEMBER'
}

export const StatusColDef = <
  T extends
    | IAsset
    | IEscrow
    | IEscrowFee
    | IOperation
    | IUser
    | IWorkspaceMember,
>(
  options: Options<T>
): ColDef<T> => {
  /**
   *
   * The translations for Workspace Member + User are the same; both use 'user' as
   * the context - but we need both as context options here so we can determine where
   * to look for the data and to keep things typesafe.
   *
   * This function simply helps us back to using `USER`.
   *
   */
  const getContext = () => {
    return options.context === 'WORKSPACE_MEMBER' ? 'USER' : options.context
  }

  /**
   *
   * Fetches the Status based on the context provided - if no status can be found
   * on the expected prop then we'll return nothing and no status will be displayed.
   *
   */
  const getStatusName = (obj?: T): string | null => {
    let status: string | undefined
    try {
      if (obj) {
        if (options.context === 'ASSET') {
          // Handles Asset Statuses
          status = findLastStatus((obj as IAsset).statuses)?.name
        } else if (options.context === 'ESCROW') {
          // Handles Escrow
          status = (obj as IEscrow).status.at(-1)?.name
        } else if (options.context === 'ESCROW_FEE') {
          // Handles Escrow Fee
          status = (obj as IEscrow).fees?.at(-1)?.status.at(-1)?.name
          if (status !== 'AWAITING_APPROVAL') {
            // The only escrow fee status that wants displaying is 'awaiting approval'
            status = undefined
          }
        } else if (options.context === 'OPERATION') {
          // Handles Operations (cases)
          status = (obj as IOperation).status
        } else if (options.context === 'USER') {
          // Handles User
          status = (obj as IUser).disabled ? 'DISABLED' : 'ACTIVE'
        } else if (options.context === 'WORKSPACE_MEMBER') {
          // Handles User
          status = (obj as IWorkspaceMember).user?.disabled
            ? 'DISABLED'
            : 'ACTIVE'
        }
      }
    } catch (e) {
      /* empty */
    }
    return status ?? null
  }

  return {
    headerName: options?.headerName ?? t('status'),
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      const status = getStatusName(params.data)
      if (!status) {
        return ''
      } else {
        return t(
          `status_${getContext().toLowerCase()}_${status.toLowerCase()}`,
          '' // << Default if the translation cannot be found.
        )
      }
    },
    filterValueGetter: (params) => {
      const status = getStatusName(params.data)
      if (!status) {
        return ''
      } else {
        return t(
          `status_${getContext().toLowerCase()}_${status.toLowerCase()}`,
          '' // << Default if the translation cannot be found.
        )
      }
    },
    comparator: (a, b) => {
      return a.localeCompare(b)
    },
    cellRenderer: (cellProps: CustomCellRendererProps<T>) => {
      const status = getStatusName(cellProps.data)
      if (!status) {
        return ''
      } else {
        return (
          <Chip.Status
            size={'medium'}
            context={options.context}
            status={status}
          />
        )
      }
    },
    width: 190,
  }
}
