import { CustomCellRendererProps } from 'ag-grid-react'

import Button from '@/components/button'

interface Props extends CustomCellRendererProps {
  onClick: (id: string) => void
}

export const ReviewRenderer: React.FC<Props> = ({ data, onClick }) => {
  return (
    <div>
      <Button.Basic
        hierarchy="secondary"
        label="Review"
        size="small"
        onClick={() => onClick(data.id)}
      />
    </div>
  )
}
