import {
  Children,
  cloneElement,
  CSSProperties,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'

import { Props as TypographyProps, Typography } from '@/components/typography'

import { Divider, Props as DividerProps } from '../../divider'
import Input from '../../input'
import { ListItem, Props as ListItemProps } from '../../list-item'

/*
    Built by N.Palethorpe
    Wed 20th Dec

    This component is the list part of the dropdown.
    TODO: Need to add in the search input component as an optional
    row.
*/
export type RowTypes<T> = ListItemProps<T> | TypographyProps | DividerProps
export interface Props<T> {
  gap?: 'large' | 'medium' | 'none'
  id?: string
  dropdownId?: string
  innerId?: string // Id given to the full height container of the list
  onMouseEnter?: (e: MouseEvent<HTMLDivElement>) => void
  onMouseLeave?: (e: MouseEvent<HTMLDivElement>) => void
  onMouseClicked?: (e: MouseEvent<HTMLDivElement>) => void
  onItemClicked?: (props: RowTypes<T>) => void
  onHeightChanged?: (height: number) => void
  children?: ReactElement<RowTypes<T>> | ReactElement<RowTypes<T>>[]
  fixedHeader?: ReactNode
  fixedFooter?: ReactNode
  hideOverflow?: boolean
  className?: string
  style?: CSSProperties
  testId?: string
}

export const DropdownList = <T,>(props: Props<T>) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLDivElement>(null)
  const scrollerRef = useRef<HTMLDivElement>(null)
  const fixedHeaderRef = useRef<HTMLDivElement>(null)
  const fixedFooterRef = useRef<HTMLDivElement>(null)
  const [_isOverflowing, setIsOverflowing] = useState<boolean>(false)

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    props.onMouseClicked && props.onMouseClicked(e)
  }

  const onItemClick = (itemProps: RowTypes<T>) => {
    props.onItemClicked && props.onItemClicked(itemProps)
    if ('onClick' in itemProps) {
      itemProps.onClick && itemProps.onClick()
    }
  }

  const checkIfOverflowing = () => {
    setIsOverflowing(
      (scrollerRef.current?.offsetHeight ?? 0) <
        (scrollerRef.current?.scrollHeight ?? 0)
    )
  }

  const calculateHeight = () => {
    let height = 0
    let paddingTop = 0
    let paddingBottom = 0

    // Check for padding on the outer container
    if (containerRef.current) {
      const _styling = window.getComputedStyle(containerRef.current)
      paddingTop = parseInt(_styling.getPropertyValue('padding-top'), 10)
      paddingBottom = parseInt(_styling.getPropertyValue('padding-bottom'), 10)
      height += paddingTop + paddingBottom
    }

    // Check if we have a fixed header to account for
    if (fixedHeaderRef.current) {
      height += fixedHeaderRef.current.clientHeight
    }

    // Check for a fixed footer
    if (fixedFooterRef.current) {
      height += fixedFooterRef.current.clientHeight
    }

    // Check the height of the actual list
    if (innerRef.current) {
      height += innerRef.current?.clientHeight
    }

    // Throw the callback
    props.onHeightChanged && props.onHeightChanged(height)

    return height
  }

  const renderRow = useCallback(
    (row: ReactElement<RowTypes<T>>): ReactNode => {
      const components: ReactNode[] = []

      if (
        row.type === ListItem ||
        row.type === Divider ||
        row.type === Typography
      ) {
        components.push(
          cloneElement(row, {
            onClick: () => onItemClick(row.props),
          })
        )
      } else if (
        row.type === Input.Text ||
        row.type === Input.Email ||
        row.type === Input.Number
      ) {
        components.push(row)
      } else if ('children' in row.props) {
        const childComponents = Children.map(row.props.children, (childRow) =>
          renderRow(childRow as ReactElement<RowTypes<T>>)
        )
        components.push(...(childComponents || []))
      }

      return components
    },
    [props.children, onItemClick]
  )

  const memoizedChildren = useMemo(() => {
    if (props.children) {
      return Children.map(props.children, (row) => renderRow(row))
    } else {
      return null
    }
  }, [props.children, renderRow])

  useEffect(() => {
    calculateHeight()
    checkIfOverflowing()
  }, [memoizedChildren])

  return (
    <div
      id={props.id}
      className={twMerge(
        'flex flex-col items-start cursor-default z-50',
        'h-full w-full',
        // 'rounded-[12px] shadow-[0_0.5rem_1rem_0_rgba(40,38,35,0.15)]',
        props.className
      )}
      style={props.style}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={onClick}
      data-testid={props.testId ?? 'dropdown-list-container'}
    >
      {props.fixedHeader && (
        <div ref={fixedHeaderRef} className={'h-auto w-full'}>
          {props.fixedHeader}
        </div>
      )}
      {props.children &&
        Array.isArray(props.children) &&
        props.children.length > 0 && (
          <div
            ref={containerRef}
            className={twMerge(
              'flex flex-col self-stretch p-4 overflow-hidden h-full',
              'pb-[1.5rem] pt-[1.75rem] px-6 tablet:pb-[2.5rem] tablet:px-4 tablet:py-4',
              'select-none',
              props.fixedHeader && 'pt-0',
              props.fixedFooter && 'pb-0'
            )}
          >
            <div
              ref={scrollerRef}
              className={twMerge(
                'h-full w-full overflow-x-hidden overflow-y-auto',
                'transition-[padding-right] duration-50',
                'overscroll-contain',
                props.hideOverflow && 'overflow-y-hidden',
                !props.hideOverflow && _isOverflowing ? 'pr-4' : ''
              )}
            >
              <div
                ref={innerRef}
                id={props.innerId}
                className={twMerge(
                  'flex self-stretch flex-col items-start',
                  props.gap === 'none'
                    ? 'gap-0'
                    : props.gap === 'large'
                      ? 'gap-4'
                      : 'gap-2'
                )}
              >
                {memoizedChildren}
              </div>
            </div>
          </div>
        )}
      {props.fixedFooter && (
        <div ref={fixedFooterRef} className={'h-auto w-full'}>
          {props.fixedFooter}
        </div>
      )}
    </div>
  )
}
