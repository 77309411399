import { ColDef } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { t } from 'i18next'
import moment from 'moment'

import { Typography } from '@/components/typography'
import { dateToString } from '@/helpers/dateToString'

import { ColDefOptions } from './_options.coldef'

interface Options<T> extends ColDefOptions<T> {}

export const CreatedAtColDef = <T,>(options?: Options<T>) => {
  return {
    field: options?.field ?? 'created_at',
    headerName: options?.headerName ?? t('created'),
    filter: 'agDateColumnFilter',
    filterValueGetter: (params) => {
      return moment(
        params.getValue(options?.field?.toString() ?? 'created_at') ?? 0
      )
        .startOf('day')
        .toDate()
    },
    cellRenderer: (cellProps: CustomCellRendererProps) => {
      return (
        <Typography
          variant={'paragraph-small'}
          className={'truncate'}
          tooltip={cellProps.value}
        >
          {dateToString(cellProps.value)}
        </Typography>
      )
    },
    sort: 'desc',
    width: 200,
    initialWidth: 200,
  } as ColDef<T>
}
