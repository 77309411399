import { useState } from 'react'

import { DisposeAssetCompletedModal } from '@/components/dropdown/controllers/views/DisposeAssetCompletedModal'
import {
  DisposeAssetInitiateModal,
  useDisposeAssetInitiateMutation,
} from '@/components/dropdown/controllers/views/DisposeAssetInitiateModal'
import {
  DisposeAssetModalFormState,
  ReasonForDisposal,
} from '@/components/dropdown/controllers/views/DisposeAssetModal.types'
import {
  DisposeAssetValidateModal,
  useDisposeAssetValidateMutation,
} from '@/components/dropdown/controllers/views/DisposeAssetValidateModal'
import { useFileUpload } from '@/components/input/hooks/useFileUpload'
import { IAsset } from '@/types/asset'

type Props = {
  asset: IAsset
  onClose: () => void
}

export const DisposeAssetFlowController = ({ asset, onClose }: Props) => {
  const [formState, setFormState] = useState<DisposeAssetModalFormState>({
    amount: '',
    destinationAddress: '',
    beneficiaryName: '',
    firstName: '',
    lastName: '',
    reasonForDisposal: ReasonForDisposal.RETURN_TO_DEFENDANT,
    selectedVasp: null,
    accountType: 'organization',
    declarationOfOwnership: false,
  })

  const courtOrderFile = useFileUpload({
    allowedFileTypes: ['image', 'pdf', 'worddoc'],
  })

  const validateMutation = useDisposeAssetValidateMutation({
    onSuccess: (data) =>
      setFormState((prev) => ({
        ...prev,
        selectedVasp: data.data.detected_vasp ?? null,
      })),
  })

  const initiateMutation = useDisposeAssetInitiateMutation()

  if (!('ticker' in asset)) {
    return null
  }

  if (initiateMutation.initiationResult) {
    return <DisposeAssetCompletedModal onClose={onClose} />
  }

  if (validateMutation.validationResult) {
    return (
      <DisposeAssetInitiateModal
        formState={formState}
        setFormState={setFormState}
        initiateMutation={initiateMutation}
        asset={asset}
        onClose={onClose}
      />
    )
  }

  return (
    <DisposeAssetValidateModal
      formState={formState}
      setFormState={setFormState}
      courtOrderFile={courtOrderFile}
      validateMutation={validateMutation}
      asset={asset}
      onClose={onClose}
    />
  )
}
