import { ColDef, RowClickedEvent } from 'ag-grid-community'
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Button from '@/components/button'
import { Dropdown } from '@/components/dropdown'
import AssetActionsController from '@/components/dropdown/controllers/asset-actions.controller'
import { DisposeAssetFlowController } from '@/components/dropdown/controllers/views/DisposeAssetFlowController'
import { ROUTES } from '@/constants/routes'
import { useOperations } from '@/hooks/queries/useOperations'
import { IAsset } from '@/types/asset'

import styles from '../aggrid.module.css'
import { AssetCaseColDef } from '../colDefs/assetCase.coldef'
import { AssetIdentifierColDef } from '../colDefs/assetIdentifier.coldef'
import { AssetTypeColDef } from '../colDefs/assetType.coldef'
import { AssetValueColDef } from '../colDefs/assetValue.coldef'
import { CreatedAtColDef } from '../colDefs/createdAt.coldef'
import { StatusColDef } from '../colDefs/status.coldef'

export interface Props {
  data: IAsset[]
  testId?: string
  onRemoveFromCase?: (a: IAsset) => void
}

export const AssetsTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { operations } = useOperations()

  const [showDisposeModalForAsset, setShowDisposeModalForAsset] = useState<
    string | null
  >(null)

  const selectedAsset = props.data.find(
    ({ id }) => showDisposeModalForAsset === id
  )

  const colDefs = useMemo<ColDef<IAsset>[]>(
    () => [
      AssetTypeColDef(),
      AssetIdentifierColDef(),
      AssetCaseColDef(operations ?? []),
      AssetValueColDef(),
      CreatedAtColDef(),
      StatusColDef({
        context: 'ASSET',
      }),
      {
        cellRenderer: (cellProps: CustomCellRendererProps<IAsset>) => {
          return cellProps.data ? (
            <Dropdown
              dropdownContent={
                <AssetActionsController
                  asset={cellProps.data}
                  onRemoveFromCase={props.onRemoveFromCase}
                  onClickDispose={() =>
                    setShowDisposeModalForAsset(cellProps.data?.id ?? null)
                  }
                />
              }
            >
              {({ setIsOpen }) => (
                <Button.Shape
                  layout={'icon'}
                  shape={'square'}
                  size={'small'}
                  hierarchy={'tertiary'}
                  icon={{
                    name: 'ellipsis',
                  }}
                  onClick={(event) => {
                    event?.preventDefault()
                    setIsOpen(true)
                  }}
                />
              )}
            </Dropdown>
          ) : null
        },
        width: 70,
        resizable: false,
      },
    ],
    [t, props.onRemoveFromCase]
  )

  return (
    <>
      {selectedAsset ? (
        <DisposeAssetFlowController
          asset={selectedAsset}
          onClose={() => setShowDisposeModalForAsset(null)}
        />
      ) : null}

      <div
        className={`ag-theme-quartz ar-theme ${styles.grid} ${styles.no_cell_selection}`}
        style={{ position: 'relative' }}
        data-testid={props.testId}
      >
        <AgGridReact
          suppressContextMenu
          autoSizeStrategy={{
            type: 'fitGridWidth',
          }}
          gridOptions={{
            domLayout: 'autoHeight',
            rowHeight: 72,
          }}
          rowData={props.data}
          defaultColDef={{
            suppressHeaderMenuButton: true,
            suppressHeaderContextMenu: true,
          }}
          columnDefs={colDefs}
          onRowClicked={(e: RowClickedEvent<IAsset>) => {
            if (e.data && !e.event?.defaultPrevented) {
              navigate(
                ROUTES.ASSETS.DETAIL.replace(':asset_id', e.data.id).replace(
                  ':asset_id',
                  e.data.id
                ),
                {
                  state: {
                    asset: e.data,
                    category: e.data.category,
                  },
                }
              )
            }
          }}
        />
      </div>
    </>
  )
}
