import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { updateAsset } from '@/api'
import { updateOperationFiles } from '@/api/broker/v2/operations'
import { Button } from '@/components/button'
import Card from '@/components/card'
import Chip from '@/components/chip'
import { Dropdown } from '@/components/dropdown'
import BasicListController from '@/components/dropdown/controllers/basic-list.controller'
import { EventLogs } from '@/components/event-logs/event-logs'
import BottomPadding from '@/components/footer/BottomPadding.tsx'
import { Group } from '@/components/group'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import Page from '@/components/page'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import { useAuth } from '@/contexts/auth'
import { useDrawer } from '@/contexts/interface'
import { ToastManager } from '@/contexts/toast'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { formatValue } from '@/helpers/formatValue.ts'
import { useOrg } from '@/hooks/queries/useOrg.ts'
import { IAsset } from '@/types/asset'
import { IDefendant } from '@/types/defendants'
import { IFile } from '@/types/file.ts'
import { IOperation } from '@/types/operations'

import { LoaderData } from './loader'

export const CaseDetail: React.FC = (): JSX.Element => {
  const loader = useLoaderData() as LoaderData
  const { t } = useTranslation()
  const { revalidate } = useRevalidator()
  const { setDrawer } = useDrawer()
  const { org } = useOrg()

  const [_operation, setOperation] = useState<IOperation | undefined>(
    loader?.operation
  )
  const [_assets, setAssets] = useState<IAsset[] | undefined>(loader?.assets)

  const onDeleteCaseFile = async (file: File | IFile) => {
    if (_operation == null) {
      throw new Error('operation is required')
    }
    if ((file as IFile).id === undefined) {
      throw new Error('IFile expected')
    }

    const files =
      _operation.files?.filter((e) => e.id !== (file as IFile).id) ?? []
    const res = await updateOperationFiles(_operation.id, files)
    if (res.error_code || res.error) {
      throw new Error(ExtractErrorFrom(res))
    }
    ToastManager.showToast({
      text: t('file_successfully_deleted'),
      type: 'information',
    })
    const operation = { ..._operation, files }
    setOperation(operation)
  }

  const removeAssetFromCase = async (asset: IAsset) => {
    await updateAsset({
      ...asset,
      operation_id: '', // < Stick the operation Id as "" to remove the association
    })
    revalidate()
  }

  useEffect(() => {
    if (loader) {
      setOperation(loader.operation)
      setAssets(loader.assets)
    }
  }, [loader])

  const onCaseUpdated = () => {
    revalidate()
    document.querySelector('main')?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const { policies } = useAuth()
  const canEditCase = policies?.includes('CUSTODY.MANAGE_OPERATION')
  const canCreateAsset =
    policies?.includes('CUSTODY.MANAGE_DIGITAL_ASSET') &&
    policies?.includes('CUSTODY.MANAGE_PHYSICAL_ASSET')

  const caseDefendants = _operation?.defendants ?? []

  const hasAssets = _assets && _assets.length > 0
  let assetsTotalValue
  if (hasAssets) {
    assetsTotalValue =
      _assets?.map((a) => a?.price?.value ?? 0).reduce((p, n) => p + n) ?? 0
  } else {
    assetsTotalValue = 0
  }
  const assetsCurrency = org?.preferred_currency
  const assetsTotalValueFormatted = formatValue(
    Math.floor(assetsTotalValue),
    assetsCurrency,
    {
      notation: 'standard',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
  )

  const filesToShow = _operation?.files ?? []

  return (
    <div className={twMerge('flex flex-col')}>
      <Page.Navigation className={'px-6'} backLabel={t('back_to_cases')} />
      <div
        className={'flex flex-col gap-[32px] p-8 pt-4'}
        data-testid="case-detail-container"
      >
        <div
          className={twMerge(
            'flex flex-col tablet:flex-row gap-[16px]',
            'p-[16px] pl-[24px] self-stretch justify-between',
            'items-start tablet:items-center',
            'bg-[#F5F5F6] rounded-[12px]'
          )}
        >
          <Paragraph title={_operation?.name} spacerOverrides={{ title: [] }} />
          <div className={'flex flex-col tablet:flex-row self-stretch gap-4'}>
            {canCreateAsset && canEditCase ? (
              <Dropdown
                maxWidthPx={300}
                dropdownContent={
                  <BasicListController
                    closeOnItemClick={true}
                    items={[
                      <ListItem
                        key={'btn_add_asset_new'}
                        title={t('create_new_asset')}
                        leading={
                          <Icon
                            name={'plus'}
                            family={'sharp'}
                            variant={'solid'}
                          />
                        }
                        onClick={() => {
                          setDrawer({
                            id: 'NEW_ASSET',
                            preChosenOperation: _operation,
                            onUpdated: onCaseUpdated,
                          })
                        }}
                        className={'pl-2 pr-2'}
                      />,
                      <ListItem
                        key={'btn_add_asset_existing'}
                        title={t('add_existing_asset')}
                        leading={
                          <Icon
                            name={'pencil'}
                            family={'sharp'}
                            variant={'solid'}
                          />
                        }
                        onClick={() => {
                          if (_operation) {
                            setDrawer({
                              id: 'CASE_ADD_EXISTING_ASSETS',
                              selectedAssets: _assets ?? [],
                              case: _operation,
                              onUpdated: onCaseUpdated,
                            })
                          }
                        }}
                        className={'pl-2 pr-2'}
                      />,
                    ]}
                  />
                }
              >
                {({ setIsOpen }) => (
                  <Button
                    testId={'btn_add_asset'}
                    hierarchy={'secondary'}
                    size={'small'}
                    icon={{
                      name: 'plus',
                      family: 'sharp',
                      variant: 'solid',
                    }}
                    label={t('add_asset')}
                    onClick={() => {
                      setIsOpen(true)
                    }}
                    className={twMerge('self-end tablet:flex')}
                  />
                )}
              </Dropdown>
            ) : null}
            <Button
              testId={'btn_add_document'}
              hierarchy={'secondary'}
              size={'small'}
              icon={{
                name: 'plus',
                family: 'sharp',
                variant: 'solid',
              }}
              label={t('add_document')}
              onClick={() => {
                if (_operation) {
                  setDrawer({
                    id: 'NEW_OPERATION_DOCUMENT',
                    operation: _operation,
                    onFilesChanged: () => {},
                  })
                }
              }}
              className={twMerge('self-end tablet:flex')}
            />
            {canEditCase ? (
              <Button
                hierarchy={'secondary'}
                size={'small'}
                testId={'btn_show_edit_case_drawer'}
                icon={{
                  name: 'edit',
                  family: 'sharp',
                  variant: 'solid',
                }}
                label={t('edit_case')}
                onClick={() => {
                  if (_operation) {
                    setDrawer({
                      id: 'EDIT_CASE',
                      case: _operation,
                      onUpdated: onCaseUpdated,
                    })
                  }
                }}
                className={twMerge('self-end')}
              />
            ) : null}
          </div>
        </div>

        <div className={'flex flex-row gap-[24px] flex-wrap'}>
          <Group label={t('ar_reference')} inline={true}>
            <Input.IDNumber
              id={_operation?.platform_id ?? '-'}
              hideLabel={true}
            />
          </Group>
          <Group label={t('status')} inline={true}>
            <Chip.Status
              size={'large'}
              status={_operation?.status ?? ''}
              context={'OPERATION'}
              outline={false}
            />
          </Group>
        </div>

        <div
          className={
            'flex items-center content-center gap-4 self-stretch flex-wrap'
          }
        >
          <Card.Overview
            value={assetsTotalValueFormatted}
            valueDescriptor={t('total_value')}
          />
          <Card.Overview
            value={_assets?.filter((a) => a.category !== 'DIGITAL').length ?? 0}
            valueDescriptor={t('physical')}
            className={'hidden tablet:flex'}
          />
          <Card.Overview
            value={_assets?.filter((a) => a.category === 'DIGITAL').length ?? 0}
            valueDescriptor={t('digital')}
            className={'hidden tablet:flex'}
          />
          <Card.Overview
            value={_operation?.files?.length ?? 0}
            valueDescriptor={t('documents')}
            className={'hidden tablet:flex'}
          />
        </div>

        <Group label={t('subjects')}>
          {caseDefendants.length > 0 ? (
            <Table.Defendants
              showFilters={false}
              showSort={false}
              defaultSortBy={'az'}
              showLeftController={false}
              defendants={caseDefendants}
              onEditDefendant={(d: IDefendant) => {
                setDrawer({
                  id: 'EDIT_DEFENDANT',
                  defendant: d,
                })
              }}
            />
          ) : (
            <Paragraph>{}</Paragraph>
          )}
        </Group>

        <Group label={t('assets')}>
          {_assets && _assets.length > 0 ? (
            <Table.Assets
              testId={'table_assets'}
              data={_assets}
              onRemoveFromCase={removeAssetFromCase}
            />
          ) : (
            <Paragraph>{}</Paragraph>
          )}
        </Group>
        <Group label={t('documents')}>
          {_operation && filesToShow.length > 0 ? (
            <Table.Files
              testId={'table_documents'}
              data={filesToShow}
              onDeleteFile={onDeleteCaseFile}
            />
          ) : (
            <Paragraph>{}</Paragraph>
          )}
        </Group>

        {_operation?.id && !['main', 'demo'].includes(import.meta.env.MODE) ? (
          <Group label={t('event_logs')}>
            <EventLogs caseId={_operation.id} />
          </Group>
        ) : null}

        <BottomPadding />
      </div>
    </div>
  )
}
