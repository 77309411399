import { t } from 'i18next'
import { LoaderFunction } from 'react-router-dom'

import { getAllCustodians } from '@/api/org'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'
import { ICustodian } from '@/types/custodian'

export type LoaderData = {
  custodian: ICustodian
} | null

export const loader: LoaderFunction = async ({ params }) => {
  try {
    if (!params.custodian_id) return ErrorHandler(404, t('missing_params'))

    // Get any data we might require
    const res = await getAllCustodians()

    // Keep things neat by naming our res values
    const _custodians = res?.data || []
    const _custodian = _custodians.find(
      (_c) => _c.id === params.custodian_id
    ) as ICustodian
    // Push the data to the page

    return { custodian: _custodian }
  } catch (error: unknown) {
    console.log(error)
    const message = ExtractErrorFrom(error)
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
