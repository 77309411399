import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import {
  progressEscrow,
  rejectEscrow,
  submitEscrowDocuments,
} from '@/api/escrows/v1'
import { getFileMetadata, uploadFiles } from '@/api/files'
import Banner from '@/components/banner'
import Button from '@/components/button'
import Chip from '@/components/chip'
import { FileUpload } from '@/components/file/upload'
import { Group } from '@/components/group'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { InputElement } from '@/components/input/components/element.input'
import LoadingSpinner from '@/components/loading-spinner'
import { Modal } from '@/components/modal'
import { NoData } from '@/components/no-data'
import { PageBack } from '@/components/page-back'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import { ROUTES } from '@/constants/routes'
import { useAuth } from '@/contexts/auth'
import { useModal } from '@/contexts/interface'
import { userCanApproveFees } from '@/helpers/escrow'
import { isDefined } from '@/helpers/isDefined'
import { key as GetEscrowKey, useEscrow } from '@/hooks/queries/useEscrow'
import { key as GetEscrowsKey } from '@/hooks/queries/useEscrows'
import { IEscrowAsset } from '@/types/escrows'

type Props = { escrowId: string }

export const EscrowDetail = ({ escrowId }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { escrow, isLoading } = useEscrow(escrowId)
  const { user, hasPolicy } = useAuth()
  const { setModal } = useModal()

  const [escrowRejectionReason, setEscrowRejectionReason] = useState('')

  const [uploadDocumentsFormState, setUploadDocumentsFormState] = useState<
    File[]
  >([])
  const [isUploadDocumentsModalVisible, setIsUploadDocumentsModalVisible] =
    useState(false)

  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false)
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false)

  const {
    mutateAsync: uploadDocumentsToEscrow,
    isPending: isUploadingDocumentsToEscrow,
  } = useMutation({
    mutationFn: async (files: File[]) => {
      const uploadedFiles = await uploadFiles(files)
      const uploadedFilesMetadata = await getFileMetadata(
        uploadedFiles?.map(({ key }) => key) ?? []
      )

      const uploadedFilesWithMetadata = uploadedFiles.map((file) => ({
        name: file.name,
        metadata: uploadedFilesMetadata.find(
          ({ metadata }) => metadata.key === file.key
        ),
      }))

      await submitEscrowDocuments(
        escrowId,
        uploadedFilesWithMetadata
          .map(({ name, metadata }) =>
            metadata
              ? {
                  key: metadata.metadata.key,
                  name,
                  type: metadata.metadata.mimetype.startsWith('image/')
                    ? 'IMG'
                    : metadata.metadata.mimetype.endsWith('/pdf')
                      ? 'PDF'
                      : metadata.metadata.mimetype.startsWith('text/')
                        ? 'TXT'
                        : 'DOC',
                }
              : null
          )
          .filter(isDefined)
      )
      await Promise.all([
        queryClient.refetchQueries({ queryKey: [GetEscrowKey, escrowId] }),
        queryClient.refetchQueries({ queryKey: [GetEscrowsKey] }),
      ])
    },
  })

  const {
    mutateAsync: submitDocumensForReview,
    isSuccess: hasSuccessfullySubmittedForReview,
    isPending: isSubmittingForReview,
    reset: resetSubmitForReviewMutation,
  } = useMutation({
    mutationFn: async () => {
      await progressEscrow(escrowId)
      await Promise.all([
        queryClient.refetchQueries({ queryKey: [GetEscrowKey, escrowId] }),
        queryClient.refetchQueries({ queryKey: [GetEscrowsKey] }),
      ])
    },
  })

  const {
    mutateAsync: approveEscrow,
    isSuccess: hasSuccessfullyApprovedEscrow,
    isPending: isSubmittingApproval,
    reset: resetApproveMutation,
  } = useMutation({
    mutationFn: async () => {
      await progressEscrow(escrowId)
      await Promise.all([
        queryClient.refetchQueries({ queryKey: [GetEscrowKey, escrowId] }),
        queryClient.refetchQueries({ queryKey: [GetEscrowsKey] }),
      ])
    },
  })

  const { mutateAsync: reject, isPending: isRejecting } = useMutation({
    mutationFn: async () => {
      await rejectEscrow(escrowId, { reason: escrowRejectionReason })
      await Promise.all([
        queryClient.refetchQueries({ queryKey: [GetEscrowKey, escrowId] }),
        queryClient.refetchQueries({ queryKey: [GetEscrowsKey] }),
      ])
    },
  })

  const hasAlreadyApproved = escrow?.organizations
    .map(({ approvals }) => approvals)
    .flat()
    .some((approval) => approval?.by?.user_id === user?.id)

  if (isLoading) {
    return (
      <div className="min-h-[calc(100dvh-80px)] flex justify-center items-center">
        <LoadingSpinner className="text-black w-12 h-12" />
      </div>
    )
  }

  if (!escrow) {
    return <NoData description={t('escrow_not_found')} />
  }

  const escrowCurrentStatus = [...escrow.status].reverse()[0].name

  return (
    <>
      <div className={twMerge('flex flex-col')}>
        <PageBack url={ROUTES.ESCROW.INDEX} />

        <div className={'flex flex-col gap-8 p-8 pt-4'}>
          <div
            className={twMerge(
              'flex flex-col tablet:flex-row gap-[24px]',
              'p-[24px] self-stretch justify-between',
              'items-start tablet:items-center',
              'bg-[#F5F5F6] rounded-[12px]'
            )}
          >
            <Paragraph title={escrow?.name} spacerOverrides={{ title: [] }} />
            <div
              className={twMerge(
                'flex flex-col tablet:flex-row',
                'w-full tablet:w-auto',
                'gap-[16px] tablet:gap-[24px]'
              )}
            >
              {hasPolicy('ESCROW.INITIATOR') && (
                <Button.Basic
                  testId={'btn_submit_for_review'}
                  hierarchy="primary"
                  label={t('submit_for_review')}
                  onClick={() => submitDocumensForReview()}
                  state={
                    escrow.files?.length &&
                    ['PENDING', 'REJECTED'].includes(escrowCurrentStatus)
                      ? 'default'
                      : 'disabled'
                  }
                  className={'self-stretch tablet:self-auto'}
                />
              )}
              {hasPolicy('ESCROW.APPROVER') && (
                <>
                  <Button.Basic
                    testId={'btn_approve_escrow'}
                    hierarchy="primary"
                    icon={{
                      name: 'circle-check',
                    }}
                    label={t('approve')}
                    onClick={() => setIsApproveModalVisible(true)}
                    state={
                      ['AWAITING_APPROVAL'].includes(escrowCurrentStatus) &&
                      escrow.organizations.find(
                        (o) =>
                          o.id === user?.organization_id && o.require_approval
                      ) &&
                      !hasAlreadyApproved
                        ? 'default'
                        : 'disabled'
                    }
                    className={'self-stretch tablet:self-auto'}
                  />

                  <Button.Basic
                    testId={'btn_reject_escrow'}
                    hierarchy="secondary"
                    icon={{
                      name: 'circle-xmark',
                    }}
                    label={t('reject')}
                    onClick={() => setIsRejectModalVisible(true)}
                    state={
                      ['AWAITING_APPROVAL'].includes(escrowCurrentStatus) &&
                      escrow.organizations.find(
                        (o) =>
                          o.id === user?.organization_id && o.require_approval
                      ) &&
                      !hasAlreadyApproved
                        ? 'default'
                        : 'disabled'
                    }
                    className={'self-stretch tablet:self-auto'}
                  />
                </>
              )}
            </div>
          </div>

          <div className={'flex flex-row flex-wrap gap-[24px]'}>
            <Group label={t('ar_reference')} inline={true}>
              <Input.IDNumber id={escrow.platform_id ?? ''} hideLabel={true} />
            </Group>
            <Group label={t('status')} inline={true}>
              <Chip.Status
                status={[...escrow.status].reverse()[0].name}
                context={'ESCROW'}
                size={'large'}
              />
            </Group>
            <Group label={t('party_a')} inline={true}>
              <Input.Auction
                size={'medium'}
                className="max-w-48"
                value={escrow.organizations[0].name}
                icon={
                  <Icon
                    name={'buildings'}
                    variant="solid"
                    size="medium"
                    family={'sharp'}
                  />
                }
              />
            </Group>
            <Group label={t('party_b')} inline={true}>
              <Input.Auction
                size={'medium'}
                className="max-w-48"
                value={escrow.organizations[1].name}
                icon={
                  <Icon
                    name={'buildings'}
                    variant="solid"
                    size="medium"
                    family={'sharp'}
                  />
                }
              />
            </Group>
          </div>

          <Group
            shadow={true}
            className={'p-[24px] pt-[32px] min-h-[112px]'}
            label={t('assets')}
          >
            {escrow.digital_assets.length ? (
              <Table.EscrowAssets
                data={escrow.digital_assets}
                canSetDestinationAddress={false}
                onAddDestinationAddress={(asset: IEscrowAsset) => {
                  setModal({
                    id: 'ADD_BLOCKCHAIN_ADDRESS',
                    asset,
                    escrow,
                  })
                }}
              />
            ) : null}
          </Group>

          <Group
            shadow={true}
            className={'p-[24px] pt-[32px] min-h-[112px]'}
            label={t('documents')}
          >
            {escrow.files?.length ? (
              <Table.Files
                data={escrow.files ?? []}
                organizations={escrow.organizations}
              />
            ) : null}

            {hasPolicy('ESCROW.INITIATOR') &&
            ['PENDING'].includes(escrowCurrentStatus) ? (
              <Button.Basic
                testId={'btn_upload_instruction'}
                icon={{
                  name: 'plus',
                  family: 'sharp',
                  variant: 'solid',
                }}
                hierarchy="secondary"
                label={t('upload_instruction')}
                onClick={() => setIsUploadDocumentsModalVisible(true)}
              />
            ) : null}
          </Group>

          {hasPolicy('ESCROW.APPROVER') &&
            escrow.fees?.length &&
            escrow.fees[0].files.length && (
              <Group
                shadow={true}
                className={'p-[24px] pt-[32px] min-h-[112px]'}
                label={t('fees')}
              >
                {userCanApproveFees(user, escrow.fees).length > 0 && (
                  <Banner.StaticPage
                    type={'information'}
                    title={'Fee pending approval.'}
                    description={'You have a fee awaiting approval.'}
                  />
                )}

                <Table.EscrowFees data={escrow} mode={'approval'} />

                {userCanApproveFees(user, escrow.fees).length > 0 && (
                  <Button.Basic
                    hierarchy={'secondary'}
                    label={t('approve_fee')}
                    icon={{
                      name: 'circle-check',
                    }}
                    className="pl-2"
                    state={'default'}
                    onClick={async () => {
                      const fees = userCanApproveFees(user, escrow.fees)
                      if (fees.length) {
                        setModal({
                          id: 'ESCROW_FEE',
                          mode: 'approval',
                          escrow: escrow,
                          fee: fees[0],
                        })
                      }
                    }}
                  />
                )}
              </Group>
            )}

          {isUploadDocumentsModalVisible ? (
            <Modal
              onClose={() => setIsUploadDocumentsModalVisible(false)}
              visible
            >
              <div className={'flex flex-col p-6 gap-6'}>
                <Paragraph
                  title={t('upload_instruction')}
                  spacerOverrides={{
                    description: [],
                    title: ['bottom'],
                  }}
                />

                <FileUpload
                  id="escrow-docs"
                  innerText={t('accepts_pdf_doc_and_txt')}
                  maxFilesAllowed={10}
                  name="escrow-docs"
                  allowedFileTypes={['PDF', 'DOC', 'TXT']}
                  files={[]}
                  showFileList
                  onChange={(newFilesState) =>
                    setUploadDocumentsFormState(newFilesState)
                  }
                />

                <div className="flex gap-2">
                  <Button.Basic
                    hierarchy="primary"
                    label={t('upload')}
                    state={isUploadingDocumentsToEscrow ? 'loading' : undefined}
                    onClick={async () => {
                      setIsUploadDocumentsModalVisible(false)
                      await uploadDocumentsToEscrow(uploadDocumentsFormState)
                      setUploadDocumentsFormState([])
                    }}
                  />

                  <Button.Basic
                    hierarchy="tertiary"
                    label={t('cancel')}
                    state={
                      isUploadingDocumentsToEscrow ? 'disabled' : undefined
                    }
                    onClick={() => {
                      setIsUploadDocumentsModalVisible(false)
                      setUploadDocumentsFormState([])
                    }}
                  />
                </div>
              </div>
            </Modal>
          ) : null}

          {isSubmittingForReview || hasSuccessfullySubmittedForReview ? (
            <Modal visible>
              <div className={'flex flex-col p-6 gap-6'}>
                {hasSuccessfullySubmittedForReview ? (
                  <>
                    <Paragraph
                      title={t('successfully_submitted')}
                      description={t('post_escrow_submission_description')}
                      spacerOverrides={{
                        description: [],
                        title: ['bottom'],
                      }}
                    />

                    <div className="flex gap-2">
                      <Button.Basic
                        hierarchy="primary"
                        label={t('close')}
                        onClick={() => resetSubmitForReviewMutation()}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <Paragraph
                      title={t('submitting...')}
                      description={t('submission_description')}
                      spacerOverrides={{
                        description: [],
                        title: ['bottom'],
                      }}
                    />

                    <div className="flex gap-2 mb-4 justify-center">
                      <LoadingSpinner className="text-black w-8 h-8" />
                    </div>
                  </>
                )}
              </div>
            </Modal>
          ) : null}

          {hasSuccessfullyApprovedEscrow ? (
            <Modal visible>
              <div className={'flex flex-col p-6 gap-6'}>
                {escrowCurrentStatus === 'AWAITING_APPROVAL' ? (
                  <Paragraph
                    title={t('escrow_partial_approved')}
                    description={t('escrow_partial_approved_description')}
                    spacerOverrides={{
                      description: [],
                      title: ['bottom'],
                    }}
                  />
                ) : (
                  <Paragraph
                    title={t('escrow_fully_approved')}
                    description={t('escrow_fully_approved_description')}
                    spacerOverrides={{
                      description: [],
                      title: ['bottom'],
                    }}
                  />
                )}

                <div className="flex gap-2">
                  <Button.Basic
                    hierarchy="primary"
                    label={t('close')}
                    onClick={() => resetApproveMutation()}
                  />
                </div>
              </div>
            </Modal>
          ) : null}

          {isApproveModalVisible && !hasAlreadyApproved ? (
            <Modal visible className="w-[460px]">
              <div className={'flex flex-col p-6 gap-6'}>
                <div className="bg-green-500 rounded-lg py-3 px-4 text-white font-bold text-lg">
                  {t('confirm_approval')}
                </div>

                <Paragraph
                  description={t('confirm_approval_subtitle')}
                  spacerOverrides={{
                    description: [],
                  }}
                />

                <Group label={t('ar_reference')} inline={true}>
                  <Input.IDNumber
                    id={escrow.platform_id ?? ''}
                    hideLabel={true}
                  />
                </Group>

                <Group label={t('party_a')} inline={true}>
                  <Input.Auction
                    size={'medium'}
                    value={escrow.organizations[0].name}
                    icon={
                      <Icon
                        name={'buildings'}
                        variant="solid"
                        size="medium"
                        family={'sharp'}
                      />
                    }
                  />
                </Group>
                <Group label={t('party_b')} inline={true}>
                  <Input.Auction
                    size={'medium'}
                    value={escrow.organizations[1].name}
                    icon={
                      <Icon
                        name={'buildings'}
                        variant="solid"
                        size="medium"
                        family={'sharp'}
                      />
                    }
                  />
                </Group>

                <div className="flex gap-2">
                  <Button.Basic
                    icon={{
                      name: 'check',
                    }}
                    hierarchy="primary"
                    label={t('approve')}
                    onClick={() => approveEscrow()}
                    state={isSubmittingApproval ? 'loading' : undefined}
                  />

                  <Button.Basic
                    label={t('cancel')}
                    onClick={() => setIsApproveModalVisible(false)}
                    state={isSubmittingApproval ? 'disabled' : undefined}
                  />
                </div>
              </div>
            </Modal>
          ) : null}

          {isRejectModalVisible ? (
            <Modal visible className="w-[460px]">
              <div className={'flex flex-col p-6 gap-6'}>
                <div className="bg-red-500 rounded-lg py-3 px-4 text-white font-bold text-lg">
                  {t('confirm_rejection')}
                </div>

                <Group label={t('ar_reference')} inline={true}>
                  <Input.IDNumber
                    id={escrow.platform_id ?? ''}
                    hideLabel={true}
                  />
                </Group>

                <Group label="Enter reason" inline={true}>
                  <InputElement
                    variant="default"
                    onValueChanged={(event) =>
                      setEscrowRejectionReason(event.target.value)
                    }
                    input={{
                      key: 'escrow-rejection-reason',
                      type: 'text',
                      props: {
                        value: escrowRejectionReason,
                      },
                    }}
                  />
                </Group>

                <Group label={t('party_a')} inline={true}>
                  <Input.Auction
                    size={'medium'}
                    value={escrow.organizations[0].name}
                    icon={
                      <Icon
                        name={'buildings'}
                        variant="solid"
                        size="medium"
                        family={'sharp'}
                      />
                    }
                  />
                </Group>
                <Group label={t('party_b')} inline={true}>
                  <Input.Auction
                    size={'medium'}
                    value={escrow.organizations[1].name}
                    icon={
                      <Icon
                        name={'buildings'}
                        variant="solid"
                        size="medium"
                        family={'sharp'}
                      />
                    }
                  />
                </Group>

                <div className="flex gap-2">
                  <Button.Basic
                    icon={{
                      name: 'close',
                    }}
                    hierarchy="special"
                    label={t('reject')}
                    state={
                      isRejecting
                        ? 'loading'
                        : escrowRejectionReason
                          ? undefined
                          : 'disabled'
                    }
                    onClick={async () => {
                      await reject()
                      setIsRejectModalVisible(false)
                    }}
                  />

                  <Button.Basic
                    hierarchy="tertiary"
                    label={t('cancel')}
                    state={isRejecting ? 'disabled' : undefined}
                    onClick={() => setIsRejectModalVisible(false)}
                  />
                </div>
              </div>
            </Modal>
          ) : null}

          {false && (
            <Group
              shadow={true}
              className={'p-[24px] pt-[32px] min-h-[112px]'}
              label={t('activity')}
            ></Group>
          )}
        </div>
      </div>
    </>
  )
}
