/*

    Looks at the users policies
    Looks at the current url

    Determines if the user has permission to access the page:
    Returns:
    - TRUE if the user can access the page
    - FALSE if the user can NOT access the page

*/

import { matchPath } from 'react-router-dom'

import { ROUTES } from '@/constants/routes'
import { IOrganisation } from '@/types/organisation'
import { IPolicyKey } from '@/types/policies'
import { IUser } from '@/types/user'

export type AllowedState = 'HAS_ACCESS' | 'NO_ACCESS' | 'UNKNOWN_ROUTE'
export const HasAccess = (
  pathname: string,
  policies: IPolicyKey[],
  user: IUser | null,
  org: IOrganisation | null | undefined
): AllowedState => {
  // Check if the user is a super admin
  const isSuperAdmin = user?.role === 'super'

  // Function to work out if the current url matches a route - this
  // also allows us to work with dynamic routes
  const isMatchingRoute = (path: string) => {
    return matchPath(path, pathname)
  }

  // No one has access to the root
  if (pathname === '/') return 'NO_ACCESS'

  // Everyone has access to these...
  if (
    isMatchingRoute(ROUTES.AUTH.INDEX) ||
    isMatchingRoute(ROUTES.AUTH.RESET_PASSWORD) ||
    isMatchingRoute(ROUTES.AUTH.TWO_FACTOR) ||
    isMatchingRoute(ROUTES.AUTH.WORKSPACE) ||
    isMatchingRoute(ROUTES.REGISTER) ||
    isMatchingRoute(ROUTES.HEALTH.INDEX) ||
    isMatchingRoute(ROUTES.HEALTH.DETAIL) ||
    isMatchingRoute(ROUTES.PROFILE.INDEX) ||
    isMatchingRoute(ROUTES.PROFILE.SET_NEW_PASSWORD) ||
    isMatchingRoute(ROUTES.SHARES) ||
    isMatchingRoute(ROUTES.TRANSFERS)
  ) {
    return 'HAS_ACCESS'
  }

  // Only super users have access to these...
  if (
    isMatchingRoute(ROUTES.ADMIN.CUSTODIAN.INDEX) ||
    isMatchingRoute(ROUTES.ADMIN.CUSTODIAN.DETAIL) ||
    isMatchingRoute(ROUTES.ADMIN.ESCROW.INDEX) ||
    isMatchingRoute(ROUTES.ADMIN.ESCROW.DETAIL) ||
    isMatchingRoute(ROUTES.ADMIN.MESSAGES.INDEX) ||
    isMatchingRoute(ROUTES.ADMIN.ORGANIZATION.INDEX) ||
    isMatchingRoute(ROUTES.ADMIN.ORGANIZATION.DETAIL) ||
    isMatchingRoute(ROUTES.ADMIN.TEAM_MANAGEMENT.INDEX) ||
    isMatchingRoute(ROUTES.ADMIN.TEAM_MANAGEMENT.ORG) ||
    isMatchingRoute(ROUTES.ADMIN.DISPOSAL)
  ) {
    return isSuperAdmin ? 'HAS_ACCESS' : 'NO_ACCESS'
  }

  // From this point onward super admins do not have access
  // so lets route them out first
  if (isSuperAdmin) return 'NO_ACCESS'

  // Depending on the screen the user must have appropriate policies
  // in place
  if (
    isMatchingRoute(ROUTES.ASSETS.INDEX) ||
    isMatchingRoute(ROUTES.ASSETS.DETAIL)
  ) {
    return policies.includes('CUSTODY.VIEW_ASSET_DETAIL') &&
      policies.includes('CUSTODY.VIEW_OPERATION')
      ? 'HAS_ACCESS'
      : 'NO_ACCESS'
  }

  if (
    isMatchingRoute(ROUTES.CASES.INDEX) ||
    isMatchingRoute(ROUTES.CASES.DETAIL)
  ) {
    return policies.includes('CUSTODY.VIEW_OPERATION')
      ? 'HAS_ACCESS'
      : 'NO_ACCESS'
  }

  if (
    isMatchingRoute(ROUTES.SUBJECT.INDEX) ||
    isMatchingRoute(ROUTES.SUBJECT.DETAIL)
  ) {
    return policies.includes('CUSTODY.MANAGE_DEFENDANT')
      ? 'HAS_ACCESS'
      : 'NO_ACCESS'
  }

  if (isMatchingRoute(ROUTES.TEAM_MANAGEMENT)) {
    return policies.includes('TEAM.MANAGE_ORG') ? 'HAS_ACCESS' : 'NO_ACCESS'
  }

  if (
    isMatchingRoute(ROUTES.ESCROW.INDEX) ||
    isMatchingRoute(ROUTES.ESCROW.DETAIL)
  ) {
    return org?.escrow_enabled === true &&
      (policies.includes('ESCROW.APPROVER') ||
        policies.includes('ESCROW.INITIATOR'))
      ? 'HAS_ACCESS'
      : 'NO_ACCESS'
  }

  // Otherwise its not a known route
  return 'UNKNOWN_ROUTE'
}
