import { FormattedMessage, useIntl } from 'react-intl'

import { ButtonBasic } from '@/components/button/variants/basic.button'
import { Icon } from '@/components/icon'

interface Props {
  status: 'approved' | 'declined'
  onClose: () => void
}

const statusMap = {
  approved: {
    icon: 'circle-check',
    text: <FormattedMessage defaultMessage="Approved" id="6XFO/C" />,
    color: '#53AB64',
  },
  declined: {
    icon: 'circle-xmark',
    text: <FormattedMessage defaultMessage="Denied" id="5kp1Ie" />,
    color: '#E01F3D',
  },
}

export const Confirmation: React.FC<Props> = ({
  status: statusKey,
  onClose,
}) => {
  const intl = useIntl()

  const status = statusMap[statusKey]

  return (
    <div
      className={`h-[304px] rounded-xl p-9 flex flex-col items-center text-white justify-between bg-[${status.color}]`}
    >
      <Icon family="sharp" variant="solid" size="xxl" name={status.icon} />
      <h2 className="text-3xl font-extrabold">{status.text}</h2>
      <ButtonBasic
        hierarchy="secondary-inverted"
        label={intl.formatMessage({ defaultMessage: 'Dismiss', id: 'TDaF6J' })}
        className="self-auto"
        onClick={onClose}
      />
    </div>
  )
}
