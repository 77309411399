import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useRevalidator } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import { Group } from '@/components/group'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Paragraph } from '@/components/paragraph'
import { Radio } from '@/components/radio'
import { Switch } from '@/components/switch'
import { DEFAULT_USER_ROLE, MAX_ALLOWED_OWNERS } from '@/constants/auth'
import { useAuth } from '@/contexts/auth'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { useWorkspace } from '@/contexts/workspace'
import { roleDescriptionTranslated, roleTitleTranslated } from '@/helpers/roles'
import { useGroups } from '@/hooks/queries/useGroups'
import { useOrg } from '@/hooks/queries/useOrg'
import { useRoles } from '@/hooks/queries/useRoles'
import { useWorkspaceMembers } from '@/hooks/queries/useWorkspaceMembers'
import { ActionResponse } from '@/types/actions'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {}

export const NewTeamUserDrawer = (props: Props) => {
  const { user } = useAuth()
  const { org } = useOrg()
  const { roles } = useRoles()
  const { groups } = useGroups()
  const { selectedWorkspace } = useWorkspace()
  const { revalidate } = useRevalidator()
  const { members } = useWorkspaceMembers()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const [_canAssignOwnerRole, setAssignOwnerRole] = useState<boolean>(false)
  const [_isAccountOwner, setIsAccountOwner] = useState<boolean>(false)
  const [_isActionUserAnAccountOwner, setIsActionUserAnAccountOwner] =
    useState<boolean>(false)

  useEffect(() => {
    let _canSetOwner: boolean = false // default to false for safety
    let _isActionUserAnAccountOwner: boolean = false

    // The owner roles can only be set:
    // + If the user is an owner
    // + If there are not already MAX_ALLOWED set

    // Find the owner group and count how many users are inside
    const ownerGroup = groups?.find((g) => g.name.toLowerCase() === 'owner')
    if (ownerGroup) {
      // Check if the user that is attempting to edit is an account owner
      _isActionUserAnAccountOwner =
        (ownerGroup.users ?? []).find((u) => u.id === user?.id) !== undefined

      _canSetOwner =
        (ownerGroup.users ?? []).length < MAX_ALLOWED_OWNERS &&
        _isActionUserAnAccountOwner
    }

    // Set the value
    setAssignOwnerRole(_canSetOwner)
    setIsActionUserAnAccountOwner(_isActionUserAnAccountOwner)
  }, [groups, user])

  const clearSubmitting = () => setSubmitting(null)

  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  const onSuccess = () => {
    revalidate()
    ToastManager.showToast({
      type: 'success',
      text: t('user_created_successfully'),
    })

    props.onClose()
  }

  return (
    <Drawer
      title={t('create_user')}
      minHeight={700}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        buttons={[
          <Button.Basic
            key={`team_user_create_btn`}
            testId={'btn_create_user'}
            label={t('create_user')}
            state={_submitting ? 'loading' : 'default'}
            withAttributes={{
              name: 'intent',
              type: 'submit',
              value: INTENTS.CREATE_USER,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
      >
        <div className={'flex flex-col gap-8'}>
          <input
            type="hidden"
            name="workspace_id"
            value={selectedWorkspace?.id ?? ''}
          />
          <input type="hidden" name="language" value={org?.language} />

          <Group label={t('identification')}>
            <Paragraph
              description={t('user_details_description')}
              spacerOverrides={{ description: [] }}
            />

            <div className={'flex flex-col gap-6'}>
              <Input.Text
                label={t('full_name')}
                placeholder={t('full_name_hint')}
                testId={'user_name'}
                id="name"
                name="name"
              />
              <Input.Email
                label={t('email')}
                testId={'user_email'}
                placeholder={t('email_address_placeholder')}
                id="email"
                name="email"
              />
            </div>

            <div className={'flex flex-col gap-6 max-w-[23.4375rem]'}>
              <ListItem
                title={t('platform_access')}
                description={t('status_description')}
                style={'bold'}
                trailing={
                  <Switch name={'status'} enabled={true} size={'large'} />
                }
              />
            </div>
          </Group>

          <Group label={t('roles')}>
            <Paragraph
              description={t('permissions_description')}
              spacerOverrides={{ description: [] }}
            />

            {false &&
              members &&
              members.filter((m) => m.role.name.toLowerCase() === 'signer')
                .length < 2 && (
                <Input.Component.Hint
                  style={'message'}
                  label={t('permissions_hint', {
                    SIGNER_COUNT: members.filter(
                      (u) => u.role.name.toLowerCase() === 'signer'
                    ).length,
                  })}
                />
              )}

            <div className="flex flex-col w-full gap-6 max-w-[23.4375rem]">
              {_isActionUserAnAccountOwner && (
                <ListItem
                  title={t('role_title_owner')}
                  description={t('role_description_owner')}
                  style={'bold'}
                  state={!_canAssignOwnerRole ? 'disabled' : 'default'}
                  trailing={
                    <Switch
                      name={'is_owner'}
                      state={!_canAssignOwnerRole ? 'disabled' : 'default'}
                      enabled={false}
                      size={'large'}
                      onChanged={(enabled: boolean) =>
                        setIsAccountOwner(enabled)
                      }
                    />
                  }
                />
              )}

              {!_isAccountOwner && (
                <div
                  className={'flex flex-col gap-6 max-w-[23.4375rem]'}
                  data-testid="checkbox-group"
                >
                  {roles.map(
                    (role) =>
                      role.name.toLowerCase() !== 'owner' && (
                        <ListItem
                          testId={`row_role_${role.name.toLowerCase()}`}
                          key={role.id}
                          title={roleTitleTranslated(role)}
                          description={roleDescriptionTranslated(role)}
                          style={'bold'}
                          withClass="whitespace-normal overflow-visible"
                          trailing={
                            <Radio
                              id={'role_id'}
                              groupId={'role_id'}
                              defaultChecked={
                                DEFAULT_USER_ROLE === role.name.toLowerCase()
                              }
                              value={role.id}
                            />
                          }
                        />
                      )
                  )}
                </div>
              )}
            </div>
          </Group>
        </div>
      </Form>
    </Drawer>
  )
}
