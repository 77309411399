import {
  ColDef,
  IRowNode,
  RowClickedEvent,
  ValueGetterParams,
} from 'ag-grid-community'
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import React, { MouseEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Button from '@/components/button'
import { Dropdown } from '@/components/dropdown'
import CaseActionsController from '@/components/dropdown/controllers/case-actions.controller'
import { Icon } from '@/components/icon'
import { Typography } from '@/components/typography'
import { ROUTES } from '@/constants/routes'
import { formatValue } from '@/helpers/formatValue'
import { useOrg } from '@/hooks/queries/useOrg'
import { IOperation } from '@/types/operations'

import styles from '../aggrid.module.css'
import { CreatedAtColDef } from '../colDefs/createdAt.coldef'
import { NumberColDef } from '../colDefs/number.coldef'
import { StatusColDef } from '../colDefs/status.coldef'

export interface Props {
  data: IOperation[]
  testId?: string
}

export const CasesTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { org } = useOrg()

  const colDefs = useMemo<ColDef<IOperation>[]>(
    () => [
      {
        field: 'name',
        headerName: t('case_name'),
        filter: 'agTextColumnFilter',
        cellRenderer: (cellProps: CustomCellRendererProps<IOperation>) => {
          return (
            <div className={'flex flex-row items-center gap-[16px] w-full'}>
              <div
                className={
                  'min-w-[50px] max-w-[50px] flex items-center justify-center'
                }
              >
                <Icon
                  name={'folders'}
                  family={'sharp'}
                  size={'medium'}
                  variant="solid"
                  className={'min-w-0'}
                />
              </div>
              <Typography variant={'label-small'} className={'truncate'}>
                {cellProps.value}
              </Typography>
            </div>
          )
        },
        flex: 1,
        width: 320,
        initialWidth: 320,
        minWidth: 200,
      },
      NumberColDef({
        headerName: t('subjects'),
        field: 'defendants.length',
      }),
      {
        headerName: t('assets'),
        type: 'rightAligned',
        filter: 'agNumberColumnFilter',
        valueGetter: (params) => {
          return (
            (params.data?.number_of_digital_assets ?? 0) +
            (params.data?.number_of_physical_assets ?? 0)
          )
        },
        comparator: (a, b) => {
          return a - b
        },
        cellRenderer: (cellProps: CustomCellRendererProps<IOperation>) => {
          return (
            <div className={'flex flex-row justify-end w-full'}>
              <Typography
                variant={'paragraph-small'}
                className={'text-right truncate'}
              >
                {(cellProps.data?.number_of_digital_assets ?? 0) +
                  (cellProps.data?.number_of_physical_assets ?? 0)}
              </Typography>
            </div>
          )
        },
        width: 120,
      },
      {
        headerName: t('value'),
        type: 'rightAligned',
        filter: 'agNumberColumnFilter',
        filterValueGetter: (params: ValueGetterParams<IOperation>) => {
          return (
            (params.data?.total_price_of_digital_assets.value ?? 0) +
            (params.data?.total_price_of_physical_assets.value ?? 0)
          )
        },
        comparator: (
          _a,
          _b,
          nodeA: IRowNode<IOperation>,
          nodeB: IRowNode<IOperation>
        ) => {
          return (
            (nodeA.data?.total_price_of_digital_assets.value ?? 0) +
            (nodeA.data?.total_price_of_physical_assets.value ?? 0) -
            ((nodeB.data?.total_price_of_digital_assets.value ?? 0) +
              (nodeB.data?.total_price_of_physical_assets.value ?? 0))
          )
        },
        cellRenderer: (cellProps: CustomCellRendererProps<IOperation>) => {
          return (
            <div className={'flex flex-row justify-end w-full'}>
              <Typography
                variant={'paragraph-small'}
                className={'text-right truncate'}
              >
                {formatValue(
                  (cellProps.data?.total_price_of_digital_assets.value ?? 0) +
                    (cellProps.data?.total_price_of_physical_assets.value ?? 0),
                  cellProps.data?.total_price_of_digital_assets.currency ??
                    org?.preferred_currency
                )}
              </Typography>
            </div>
          )
        },
        width: 200,
      },
      CreatedAtColDef(),
      StatusColDef({
        context: 'OPERATION',
      }),
      {
        cellRenderer: (cellProps: CustomCellRendererProps<IOperation>) => {
          return (
            <Dropdown
              maxWidthPx={270}
              dropdownContent={
                cellProps.data ? (
                  <CaseActionsController case={cellProps.data} />
                ) : (
                  <></>
                )
              }
            >
              {({ setIsOpen }) => (
                <Button.Shape
                  layout={'icon'}
                  shape={'square'}
                  size={'small'}
                  hierarchy={'tertiary'}
                  icon={{
                    name: 'ellipsis',
                  }}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    if (cellProps.data) {
                      e.preventDefault()
                      setIsOpen(true)
                    }
                  }}
                />
              )}
            </Dropdown>
          )
        },
        width: 70,
        resizable: false,
      },
    ],
    [t]
  )

  return (
    <div
      className={`ag-theme-quartz ar-theme ${styles.grid} ${styles.no_cell_selection}`}
      style={{ position: 'relative' }}
      data-testid={props.testId}
    >
      <AgGridReact
        suppressContextMenu
        autoSizeStrategy={{
          type: 'fitGridWidth',
        }}
        gridOptions={{
          domLayout: 'autoHeight',
          rowHeight: 72,
        }}
        rowData={props.data}
        defaultColDef={{
          suppressHeaderMenuButton: true,
          suppressHeaderContextMenu: true,
        }}
        columnDefs={colDefs}
        onRowClicked={(e: RowClickedEvent<IOperation>) => {
          if (
            e.data &&
            !e.event?.defaultPrevented &&
            e.data.status === 'ACTIVE'
          ) {
            navigate(ROUTES.CASES.DETAIL.replace(':case_id', e.data.id))
          }
        }}
      />
    </div>
  )
}
