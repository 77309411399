import fs from 'fs'
import path from 'path'

import { Config } from '../contexts/config/types'

const requiredKeys = ['AUTH_API_URL', 'BROKER_API_URL', 'BROKER_API_URL_V2']
const storageKey = 'appConfig'

// fetch from the config.json file synchronously and block so that the app can't start without it
function fetchConfigSync(): Config {
  // use xhr for a synchronous request, if we do async fetch, multiple calls to the URL can be made
  const xhr = new XMLHttpRequest()
  xhr.open('GET', '/config.json', false) // Synchronous request
  xhr.send(null)

  if (xhr.status !== 200) {
    throw new Error(`Failed to load config.json: ${xhr.statusText}`)
  }

  return JSON.parse(xhr.responseText) as Config
}

function validateConfig(config: Config): void {
  for (const key of requiredKeys) {
    if (!(key in config)) {
      throw new Error(`Missing required config key: ${key}`)
    }
  }
}

function loadConfig(): Config {
  let config: Config | null = null

  // when vite loads the config, window is not defined, so we need to load it from the file system
  if (typeof window == 'undefined') {
    const configPath = path.resolve(__dirname, '../../public/config.json')
    config = JSON.parse(fs.readFileSync(configPath, 'utf8'))
    if (config) {
      validateConfig(config)
    } else {
      throw new Error('Config is not present in pub/config.json')
    }
    return config
  }

  // Try to load config from session storage,
  // if we loaded the config once when the app started we would not have to do this
  const cachedConfig = window.sessionStorage.getItem(storageKey)
  if (cachedConfig) {
    try {
      config = JSON.parse(cachedConfig)
      if (config) {
        validateConfig(config)
      }
      if (config) {
        return config
      }
      throw new Error('Config is null')
    } catch (error) {
      console.warn(
        'Invalid config in session storage, fetching from server...',
        error
      )
    }
  }

  // Fetch config from server synchronously
  config = fetchConfigSync() as Config
  validateConfig(config)

  // Cache config in session storage for next time this function is called
  window.sessionStorage.setItem(storageKey, JSON.stringify(config))

  return config
}

export default loadConfig
