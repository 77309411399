import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { Button } from '@/components/button'
import { EventLogs } from '@/components/event-logs/event-logs'
import BottomPadding from '@/components/footer/BottomPadding.tsx'
import Graphs from '@/components/graphs'
import { Group } from '@/components/group'
import Input from '@/components/input'
import { PageBack } from '@/components/page-back'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import { Tabs } from '@/components/tabs'
import { ROUTES } from '@/constants/routes'
import { useDrawer } from '@/contexts/interface'
import { useOrg } from '@/hooks/queries/useOrg'
import { IAsset } from '@/types/asset'
import { IDefendant } from '@/types/defendants'
import { IOperation } from '@/types/operations'

import { LoaderData } from './loader'

type tabs = 'assets' | 'cases' | 'documents'

interface GraphData {
  label: string
  value: number
  htmlColor: string
}

export const SubjectDetail = (): JSX.Element => {
  const loader = useLoaderData() as LoaderData
  const { setDrawer } = useDrawer()
  const { t } = useTranslation()
  const { org } = useOrg()
  const [_operations] = useState<IOperation[]>(loader?.operations)
  const [_assets] = useState<IAsset[]>(loader?.assets)
  const [_defendant, setDefendant] = useState<IDefendant>(loader?.defendant)
  const [_selectedTab, setSelectedTab] = useState<tabs>('assets')
  const [_graphData, setGraphData] = useState<GraphData[]>([])

  useEffect(() => {
    let digitalAmount = 0
    let tangibleAmount = 0
    let financialAmount = 0

    // Run through the assets and add up the price of each asset
    for (const a of _assets) {
      if (a.category === 'DIGITAL') {
        digitalAmount += a.price?.value ?? 0
      } else if (a.category === 'FIAT_CASH') {
        financialAmount += a.price?.value ?? 0
      } else {
        tangibleAmount += a.price?.value ?? 0
      }
    }

    // Set the graph data
    setGraphData([
      {
        label: t('digital'),
        value: digitalAmount,
        htmlColor: `#FF7124`,
      },
      {
        label: t('tangible'),
        value: tangibleAmount,
        htmlColor: `#C32AA3`,
      },
      {
        label: t('financial'),
        value: financialAmount,
        htmlColor: `#53AB64`,
      },
    ])
  }, [_assets, t])

  const onTabChanged = (id: string) => setSelectedTab(id as tabs)

  useEffect(() => {
    setDefendant(loader?.defendant)
  }, [loader?.defendant])

  if (loader.error) return <></>

  return (
    <div className={twMerge('flex flex-col')}>
      <PageBack url={ROUTES.SUBJECT.INDEX} />

      <div
        className={'flex flex-col gap-8 p-8 pt-4'}
        data-testid="defendant-detail-container"
      >
        <div
          className={twMerge(
            'flex flex-col tablet:flex-row gap-[16px]',
            'p-[16px] pl-[24px] self-stretch justify-between',
            'items-start tablet:items-center',
            'bg-[#F5F5F6] rounded-[12px]'
          )}
        >
          <Paragraph title={_defendant.name} spacerOverrides={{ title: [] }} />
          <div className={'flex flex-row gap-[24px]'}>
            <Button
              testId={'btn_show_edit_subject_drawer'}
              hierarchy={'secondary'}
              size={'small'}
              icon={{
                name: 'edit',
                family: 'sharp',
                variant: 'solid',
              }}
              label={t('edit_subject')}
              onClick={() => {
                setDrawer({
                  id: 'EDIT_DEFENDANT',
                  defendant: _defendant,
                })
              }}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <Group label={t('ar_reference')} inline={true}>
            <Input.IDNumber
              testId={'subject-ar-reference-code'}
              id={_defendant?.platform_id ?? '-'}
              hideLabel={true}
            />
          </Group>
        </div>

        <Group shadow={false} className={'p-[16px]'}>
          <Graphs.Bar
            data={_graphData}
            currency={org?.preferred_currency}
            format={'CURRENCY'}
            showTotal={true}
            showLegend={true}
          />
        </Group>

        <Group shadow={false} className={'p-[32px]'}>
          <Tabs
            testId={'tabs_defendant_options'}
            type={'text'}
            size={'medium'}
            fill={true}
            values={[
              {
                id: 'assets',
                testId: 'btn_tab_assets',
                text: `${_assets.length} ${t('assets')}`,
              },
              {
                id: 'cases',
                testId: 'btn_tab_operations',
                text: `${_operations.length} ${t('cases')}`,
              },
            ]}
            onSelectionChanged={onTabChanged}
          />

          <div className={'flex flex-col gap-4'}>
            <div
              className={'flex flex-1 flex-col'}
              data-testid="defendant-tables-group"
            >
              {_selectedTab === 'assets' ? (
                <Table.Assets data={_assets} testId={'table_assets'} />
              ) : _selectedTab === 'cases' ? (
                <Table.Cases data={_operations} />
              ) : null}
            </div>
          </div>
        </Group>

        {_defendant?.id && !['main', 'demo'].includes(import.meta.env.MODE) ? (
          <Group label={t('event_logs')}>
            <EventLogs defendantId={_defendant.id} />
          </Group>
        ) : null}
      </div>

      <BottomPadding />
    </div>
  )
}
