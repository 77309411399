import { t } from 'i18next'

import { updateOperation } from '@/api/broker/v2/operations'
import { queryClient } from '@/contexts'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { key as QueryOperationsKey } from '@/hooks/queries/useOperations'
import { ActionResponse } from '@/types/actions'
import { IOperation } from '@/types/operations'

export const RestoreCase = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  // Grab data from the form
  const case_original = formData.get('case_original')?.toString()

  // Validate the form
  if (!case_original) {
    throw new Error(t('invalid_case_object'))
  }

  // Parse out the original case object as we'll use that as our base
  const operation: IOperation = JSON.parse(case_original)

  // Update our operation
  const o_res = await updateOperation(operation.id, {
    ...operation,
    status: 'ACTIVE',
  })
  if (o_res.error_code || o_res.error) {
    throw new Error(ExtractErrorFrom(o_res))
  }

  // Update cache
  await queryClient.invalidateQueries({ queryKey: [QueryOperationsKey] })

  return {
    error: false,
    message: `${t('case')} '${operation.name}' ${t('restored_successfully')}`,
    completion: 'COMPLETE',
    data: true,
  }
}
