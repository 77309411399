import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { Avatar } from '@/components/avatar'
import { Divider } from '@/components/divider'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import AssetIcon from '@/components/table/rows/assetIcon.tsx'
import { Typography } from '@/components/typography'
import { ROUTES } from '@/constants/routes'
import { useAuth } from '@/contexts/auth'
import { useDrawer } from '@/contexts/interface'
import { getAssetTypeDescriptionForColumn } from '@/helpers/assetTypeDescription.ts'
import { getFileIcon } from '@/helpers/getFileIcon.ts'
import { useDefendants } from '@/hooks/queries/useDefendants'
import { useDigitalAssets } from '@/hooks/queries/useDigitalAssets'
import { useOperations } from '@/hooks/queries/useOperations'
import { usePhysicalAssets } from '@/hooks/queries/usePhysicalAssets'
import { useWorkspaceMembers } from '@/hooks/queries/useWorkspaceMembers'
import { IAsset, IDigitalAsset, IPhysicalAsset } from '@/types/asset'
import { IDefendant } from '@/types/defendants'
import { IFile } from '@/types/file'
import { IOperation } from '@/types/operations'
import { IWorkspaceMember } from '@/types/workspace'

// import useDebounce from '@/hooks/useDebounce'

interface Props {
  onResultsHidden?: () => void
}

interface FileWrapper {
  ownerType: 'ASSET' | 'OPERATION'
  ownerId: string
  file: IFile
}

type MatchBasic = string | number | boolean
type MatchArray = (MatchBasic | MatchObject | MatchArray)[]
type MatchObject = {
  [key: string]: MatchBasic | MatchArray | MatchObject
}

const Searchbar = (props: Props) => {
  const { t } = useTranslation()
  const { hasPolicy } = useAuth()
  const { assets: digitalAssets } = useDigitalAssets()
  const { assets: physicalAssets } = usePhysicalAssets()
  const { members: workspaceMembers } = useWorkspaceMembers()
  const { defendants } = useDefendants()
  const { operations } = useOperations()
  const { setDrawer } = useDrawer()
  const navigate = useNavigate()
  const visibililityDebounce = useRef<NodeJS.Timeout>()

  const [_visible, setVisible] = useState(false)
  const [_searchString, setSearchString] = useState<string>('')
  const [_searchTerms, setSearchTerms] = useState<string[]>([])
  const [_resultDigAssets, setResultDigAssets] = useState<IDigitalAsset[]>([])
  const [_resultPhyAssets, setResultPhyAssets] = useState<IPhysicalAsset[]>([])
  const [_resultMembers, setResultMembers] = useState<IWorkspaceMember[]>([])
  const [_resultDefendants, setResultDefendants] = useState<IDefendant[]>([])
  const [_resultOperations, setResultOperations] = useState<IOperation[]>([])
  const [_resultFiles, setResultFiles] = useState<FileWrapper[]>([])

  const search = (value: string) => {
    setSearchString(value.toLowerCase())
    setSearchTerms(value.toLowerCase().trim().replace(/ +/g, ' ').split(' '))
  }

  useEffect(() => {
    // Clear the result values if value is nothing
    if (_searchString.length <= 0) {
      setResultDigAssets([])
      setResultPhyAssets([])
      setResultMembers([])
      setResultDefendants([])
      setResultOperations([])
      setResultFiles([])
      return
    }

    // Otherwise trigger a filter on the original data arrays
    setResultDigAssets((digitalAssets ?? []).filter(filterAssets))
    setResultPhyAssets((physicalAssets ?? []).filter(filterAssets))
    setResultDefendants((defendants ?? []).filter(filterDefendants))
    setResultMembers((workspaceMembers ?? []).filter(filterWorkspaceMembers))
    setResultOperations((operations ?? []).filter(filterOperations))
  }, [_searchString])

  // Watch for changes in digital assets
  useEffect(() => {
    setResultDigAssets((digitalAssets ?? []).filter(filterAssets))
  }, [digitalAssets])

  // Watch for changes in physical assets
  useEffect(() => {
    setResultPhyAssets((physicalAssets ?? []).filter(filterAssets))
  }, [physicalAssets])

  // Watch for changes in defendants
  useEffect(() => {
    setResultDefendants((defendants ?? []).filter(filterDefendants))
  }, [defendants])

  // Watch for changes in workspace members
  useEffect(() => {
    setResultMembers((workspaceMembers ?? []).filter(filterWorkspaceMembers))
  }, [defendants])

  // Watch for changes in operations
  useEffect(() => {
    setResultOperations((operations ?? []).filter(filterOperations))
  }, [operations])

  /*
    Used to recursively dig through objects/arrays to find the property at the keys end and
    check to see if the value matches the matchAgainst value.

    The key should be a string with a fullstop "." used to break up nested properties. We use
    the "." regardless of the type of nest (array or object).
  */
  const isMatch = (
    obj: MatchBasic | MatchArray | MatchObject,
    key: string,
    matchAgainst: string
  ): boolean => {
    try {
      // Handle arrays
      if (Array.isArray(obj)) {
        return obj.some((item) => isMatch(item, key, matchAgainst))
      }

      // Handle objects
      if (typeof obj === 'object') {
        const splitKeys = key.split('.')

        if (splitKeys.length > 0 && splitKeys[0] in obj) {
          return isMatch(
            obj[splitKeys[0]],
            splitKeys.length > 1 ? splitKeys.slice(1).join('.') : '',
            matchAgainst
          )
        }

        return false
      }

      // Anything here will/should be a 'basic' type (string/number/bool)
      return obj.toString().toLowerCase().includes(matchAgainst.toLowerCase())
    } catch (e) {
      return false
    }
  }

  const filterAssets = (asset: IAsset) => {
    return (
      _searchTerms.length > 0 &&
      _searchTerms.every((term) => {
        try {
          return (
            isMatch('assets', '', term) ||
            [
              'category',
              'defendant.name',
              'defendant.platform_id',
              'platform_id',
              'statuses.name',
              'notes.content',
              'files.name',
              'external_ref',
              'type',
              'ticker',
              'network',
              'currency',
              'serial_number',
              'description',
              'make',
              'model',
              'owner_name',
              'vin',
              'plate',
              'color',
              'chassis',
              'engine',
              'contract_address',
              'wallet.address',
              'custodian.provider',
            ].some((value) =>
              isMatch(asset as unknown as MatchObject, value, term)
            )
          )
        } catch (e) {
          return false
        }
      })
    )
  }

  const filterDefendants = (defendant: IDefendant) => {
    return (
      _searchTerms.length > 0 &&
      _searchTerms.every((term) => {
        try {
          return (
            isMatch('defendants', '', term) ||
            [
              'id',
              'name',
              'platform_id',
              'external_ref',
              'location.address',
              'location.city',
              'location.country',
              'files.name',
            ].some((value) =>
              isMatch(defendant as unknown as MatchObject, value, term)
            )
          )
        } catch (e) {
          return false
        }
      })
    )
  }

  const filterWorkspaceMembers = (member: IWorkspaceMember) => {
    return (
      _searchTerms.length > 0 &&
      _searchTerms.every((term) => {
        try {
          return (
            isMatch('members', '', term) ||
            ('disabled'.includes(term) && member.user?.disabled === true) ||
            ('enabled'.includes(term) && member.user?.disabled === false) ||
            ['user_id', 'user.name', 'user.email', 'user.phone'].some((value) =>
              isMatch(member as unknown as MatchObject, value, term)
            )
          )
        } catch (e) {
          return false
        }
      })
    )
  }

  const filterOperations = (operation: IOperation) => {
    return (
      _searchTerms.length > 0 &&
      _searchTerms.every((term) => {
        try {
          return (
            isMatch('operations', '', term) ||
            isMatch('cases', '', term) ||
            [
              'status',
              'name',
              'id',
              'platform_id',
              'external_ref',
              'files.name',
              'defendants.name',
              'defendants.platform_id',
              'seizures.name',
              'seizures.external_ref',
              'seizures.description',
              'seizures.defendants.name',
              'seizures.defendants.platform_id',
            ].some((value) =>
              isMatch(operation as unknown as MatchObject, value, term)
            )
          )
        } catch (e) {
          return false
        }
      })
    )
  }

  const showResults = () => {
    clearTimeout(visibililityDebounce.current)
    setVisible(true)
  }

  const hideResults = (instant?: boolean) => {
    if (instant === true) {
      setVisible(false)
    } else {
      clearTimeout(visibililityDebounce.current)
      visibililityDebounce.current = setTimeout(() => {
        setVisible(false)
        props.onResultsHidden && props.onResultsHidden()
      }, 50)
    }
  }

  const renderOperations = () => {
    if (_resultOperations.length <= 0) {
      return null
    } else {
      return (
        <div className={'flex flex-col w-full gap-2'}>
          <Typography variant="label-small">{t('cases')}</Typography>
          <Divider />
          <div className={'flex flex-col w-full'}>
            {_resultOperations.map((c) => (
              <ListItem
                key={c.id}
                value={c.id}
                title={c.name}
                leading={<Icon name="folders" variant="solid" size="medium" />}
                onClick={() => {
                  navigate(`/cases/${c.id}`)
                  hideResults(true)
                }}
                className={'pl-2 pr-2'}
              />
            ))}
          </div>
        </div>
      )
    }
  }

  const renderMembers = () => {
    if (_resultMembers.length <= 0 && _resultDefendants.length <= 0) {
      return null
    } else {
      return (
        <div className={'flex flex-col w-full gap-2'}>
          <Typography variant="label-small">{t('people')}</Typography>
          <Divider />
          <div className={'flex flex-col w-full gap-2'}>
            {_resultMembers.map((m) => (
              <ListItem
                key={m.user_id}
                value={m.user_id}
                title={m.user?.name ?? '[Name not found]'}
                leading={
                  <Avatar
                    type={'monogram'}
                    value={m.user?.name ?? '[Name not found]'}
                    size={'small'}
                  />
                }
                trailingLabel={t('team_member')}
                onClick={() => {
                  if (hasPolicy('TEAM.MANAGE_ORG')) {
                    setDrawer({
                      id: 'EDIT_TEAM_USER',
                      userId: m.user_id,
                    })
                    hideResults(true)
                  }
                }}
                className={'pl-2 pr-2'}
              />
            ))}
            {_resultDefendants.map((d) => (
              <ListItem
                key={d.id}
                value={d.id}
                title={d.name}
                description={d.platform_id}
                leading={
                  <Avatar type={'monogram'} value={d.name} size={'small'} />
                }
                trailingLabel={t('subject')}
                onClick={
                  hasPolicy('CUSTODY.MANAGE_DEFENDANT')
                    ? () => {
                        navigate(
                          ROUTES.SUBJECT.DETAIL.replace(':subject_id', d.id)
                        )
                        hideResults(true)
                      }
                    : undefined
                }
                className={'pl-2 pr-2'}
              />
            ))}
          </div>
        </div>
      )
    }
  }

  const renderAssets = () => {
    if (_resultDigAssets.length <= 0 && _resultPhyAssets.length <= 0) {
      return null
    } else {
      return (
        <div className={'flex flex-col w-full gap-2'}>
          <Typography variant="label-small">{t('seized_assets')}</Typography>
          <Divider />
          <div className={'flex flex-col w-full gap-2'}>
            {[..._resultDigAssets, ..._resultPhyAssets].map((a) => (
              <ListItem
                key={a.id}
                value={a.id}
                title={a.external_ref ?? getAssetTypeDescriptionForColumn(a)}
                description={a.platform_id}
                leading={<AssetIcon asset={a} />}
                trailingLabel={getAssetTypeDescriptionForColumn(a)}
                onClick={() => {
                  navigate(ROUTES.ASSETS.DETAIL.replace(':asset_id', a.id))
                  hideResults(true)
                }}
                className={'pl-2 pr-2'}
              />
            ))}
          </div>
        </div>
      )
    }
  }

  const renderFiles = () => {
    if (_resultFiles.length <= 0) {
      return null
    } else {
      return (
        <div className={'flex flex-col w-full gap-2'}>
          <Typography variant="label-small">{t('files')}</Typography>
          <Divider />
          <div className={'flex flex-col w-full'}>
            {_resultFiles.map((f) => (
              <ListItem
                key={f.file.id}
                value={f.file.id}
                title={f.file.name}
                leading={
                  <Icon
                    name={getFileIcon(f.file)}
                    variant="solid"
                    size="medium"
                  />
                }
                onClick={() => {
                  hideResults(true)
                }}
                className={'pl-2 pr-2'}
              />
            ))}
          </div>
        </div>
      )
    }
  }

  const renderResults = () => {
    if (
      _resultDigAssets.length <= 0 &&
      _resultPhyAssets.length <= 0 &&
      _resultMembers.length <= 0 &&
      _resultDefendants.length <= 0 &&
      _resultOperations.length <= 0
    ) {
      return <span className="text-primary-text">{t('no_results')}</span>
    }

    return (
      <div className={'flex flex-col w-full gap-4'}>
        {renderOperations()}
        {renderMembers()}
        {renderAssets()}
        {renderFiles()}
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-1">
        <form
          className="flex w-full items-center bg-white rounded-[40px] border-2 tablet:border-0 border-transparent"
          action="#"
          method="GET"
        >
          <Input.Search
            onChange={search}
            placeholder={t('search_placeholder')}
            hideClearButton={true}
            className="max-w-none"
            elementClassName="tablet:border-0 tablet:p-0"
            onFocus={showResults}
          />
        </form>
      </div>

      <div
        className={twMerge(
          'absolute hidden top-[72px] left-0 right-0 h-[100vh] bg-[rgba(255,255,255,0.5)]',
          'w-[100vw] left-[-32px]',
          _visible && _searchString.length > 0 && 'flex animate-fade-in'
        )}
        onClick={() => hideResults(true)}
      ></div>

      <div
        className={twMerge(
          `absolute top-[72px] bg-white transition-all duration-300 mt-3 p-5 pr-2`,
          'max-h-[min(777px,calc(100dvh-220px))]',
          'hidden rounded-[12px] border-[1px] border-[rgba(33,36,39,0.10)]',
          'z-50 shadow-[0_8px_16px_1px_rgba(40,38,35,0.10)]',
          'overflow-hidden w-full',
          'tablet:max-w-[calc(100%-32px)]',
          'desktop:max-w-[900px]',
          _visible && _searchString.length > 0 && 'flex'
        )}
      >
        <div className={'flex flex-1 overflow-y-auto pr-2'}>
          <div className={'w-full'}>{renderResults()}</div>
        </div>
      </div>
    </>
  )
}

export default Searchbar
