import { ColDef, RowClickedEvent } from 'ag-grid-community'
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from '@/components/avatar'
import AgGridCell from '@/components/table/components/cell.aggrid'
import { Typography } from '@/components/typography'
import { IOrganisation } from '@/types/organisation'

import styles from '../aggrid.module.css'
import { CreatedAtColDef } from '../colDefs/createdAt.coldef'

export type SortBy = 'az' | 'latest' | 'value'
export type GroupBy = 'all' | 'active' | 'archived'

export interface Props {
  data: IOrganisation[]
  onEditOrganization: (organization: IOrganisation) => void
}

export const OrganizationTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const defaultColDef = useMemo<ColDef>(
    () => ({
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
    }),
    []
  )

  const colDefs = useMemo<ColDef<IOrganisation>[]>(
    () => [
      {
        field: 'name',
        headerName: t('organization_name'),
        cellRenderer: (props: CustomCellRendererProps<IOrganisation>) => {
          return (
            <div className={'flex flex-row gap-[16px]'}>
              <Avatar
                type={'icon'}
                size={'medium'}
                shape={'square'}
                iconClassName={'text-black'}
                name={'buildings'}
                iconVariant={'solid'}
                family={'sharp'}
              />
              <div className={'flex flex-col gap-[4px] justify-center'}>
                <Typography variant={'label-small'}>{props.value}</Typography>
              </div>
            </div>
          )
        },
        flex: 1,
        minWidth: 400,
      },
      {
        field: 'id',
        headerName: t('id'),
        cellRenderer: AgGridCell,
        width: 400,
      },
      CreatedAtColDef(),
    ],
    [t]
  )

  return (
    <div
      className={`ag-theme-quartz ar-theme ${styles.grid} ${styles.no_cell_selection} h-full`}
    >
      <AgGridReact
        suppressContextMenu
        autoSizeStrategy={{
          type: 'fitGridWidth',
        }}
        gridOptions={{
          rowHeight: 72,
        }}
        rowData={props.data}
        onRowClicked={(e: RowClickedEvent<IOrganisation>) => {
          if (e.data) {
            props.onEditOrganization(e.data)
          }
        }}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
      />
    </div>
  )
}
