import { useIntl } from 'react-intl'

import Button from '@/components/button'
import { copyToClipboard } from '@/helpers/copyToClipboard'

interface Props {
  value: string
}

export const Address: React.FC<Props> = ({ value }) => {
  const intl = useIntl()

  return (
    <div className="flex items-center">
      {value}
      <Button.Shape
        hierarchy="tertiary"
        layout="icon"
        size="xsmall"
        icon={{
          name: 'copy',
        }}
        onClick={() =>
          copyToClipboard(
            value,
            intl.formatMessage({ defaultMessage: 'Address', id: 'e6Ph5+' })
          )
        }
      />
    </div>
  )
}
