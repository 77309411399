import { FormattedMessage, useIntl } from 'react-intl'

import { Button } from '@/components/button'
import { Icon } from '@/components/icon'
import { Modal } from '@/components/modal'

type Props = {
  onClose: () => void
}

export const DisposeAssetCompletedModal = ({ onClose }: Props) => {
  const intl = useIntl()

  return (
    <Modal portal visible className="max-w-[500px]">
      <div className="px-[60px] py-[100px] flex flex-col gap-6 text-center items-center">
        <div>
          <Icon name="circle-check" variant="solid" size="xxlarge" />
        </div>
        <div className="font-extrabold text-3xl">
          <FormattedMessage defaultMessage="Request sent" id="LJZGVb" />
        </div>
        <div className="font-medium">
          <FormattedMessage
            defaultMessage="Your Disposal request has been sent to an approver. Once they action the request you will be notified."
            id="bi2YWh"
          />
        </div>

        <Button
          className="self-center"
          hierarchy="secondary"
          label={intl.formatMessage({
            defaultMessage: 'Dismiss',
            id: 'TDaF6J',
          })}
          onClick={onClose}
        />
      </div>
    </Modal>
  )
}
