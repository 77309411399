import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'
import Dropdown from '@/components/dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import { useAuth } from '@/contexts/auth'
import { useDrawer, useDropdown, useModal } from '@/contexts/interface'
import { useWorkspaceMembers } from '@/hooks/queries/useWorkspaceMembers'

export const TeamManagement: React.FC = (): JSX.Element => {
  const { hasPolicy } = useAuth()
  const { members } = useWorkspaceMembers()
  const { t } = useTranslation()
  const { setDrawer } = useDrawer()
  const { setModal } = useModal()
  const { setDropdown } = useDropdown()

  const btnCreateNewIcon = 'plus'
  const btnCreateNewTitle = t('create-new-user')
  const btnCreateNewHandler = () => {
    setDrawer({
      id: 'NEW_TEAM_USER',
    })
  }
  const btnAddExistingIcon = 'user'
  const btnAddExistingTitle = t('add_existing_user')
  const btnAddExistingOnClick = () => {
    setModal({
      id: 'WORKSPACE_INVITE',
    })
  }
  const showMobileAddActions = () => {
    setDropdown({
      target: `fab-button`,
      maxWidthPx: 300,
      controller: (
        <Dropdown.Controllers.BasicList
          closeOnItemClick={true}
          items={[
            <ListItem
              key={'btn_create_new'}
              title={btnCreateNewTitle}
              leading={
                <Icon
                  name={btnCreateNewIcon}
                  family={'sharp'}
                  variant={'solid'}
                />
              }
              onClick={btnCreateNewHandler}
              className={'pl-2 pr-2'}
            />,
            <ListItem
              key={'btn_add_existing'}
              title={btnAddExistingTitle}
              leading={
                <Icon
                  name={btnAddExistingIcon}
                  family={'sharp'}
                  variant={'solid'}
                />
              }
              onClick={btnAddExistingOnClick}
              className={'pl-2 pr-2'}
            />,
          ]}
        />
      ),
    })
  }

  return (
    <>
      <div
        className={twMerge(
          'flex flex-1 flex-col p-8 pt-4 desktop:px-8 desktop:py-4',
          'min-h-[calc(100dvh-80px)]'
        )}
      >
        <Paragraph
          title={
            hasPolicy('TEAM.MANAGE_ORG') ? t('team_permissions') : t('team')
          }
          description={
            hasPolicy('TEAM.MANAGE_ORG') ? t('team_management_description') : ''
          }
          spacerOverrides={{
            description: [],
          }}
        />

        <div className="flex flex-1 flex-col gap-[32px] pt-[42px]">
          {hasPolicy('TEAM.MANAGE_ORG') && (
            <div className="gap-5 pb-[32px] hidden tablet:flex">
              <Button.Basic
                label={btnCreateNewTitle}
                testId={'btn_show_new_user_drawer'}
                icon={{
                  name: btnCreateNewIcon,
                }}
                onClick={btnCreateNewHandler}
              />

              <Button.Basic
                testId={'btn_show_existing_user_drawer'}
                hierarchy="secondary"
                label={btnAddExistingTitle}
                icon={{
                  name: btnAddExistingIcon,
                }}
                onClick={btnAddExistingOnClick}
              />
            </div>
          )}

          <Table.AgGrid.Wrapper>
            <Table.WorkspaceMembers data={members.filter((m) => m.user)} />
          </Table.AgGrid.Wrapper>
        </div>

        {hasPolicy('TEAM.MANAGE_ORG') && (
          <Button.Fab
            label={t('add_user')}
            icon={{
              name: 'plus',
            }}
            onClick={showMobileAddActions}
          />
        )}
      </div>
    </>
  )
}
