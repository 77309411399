import { ColDef, RowClickedEvent } from 'ag-grid-community'
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from '@/components/avatar'
import AgGridCell from '@/components/table/components/cell.aggrid'
import { Typography } from '@/components/typography'
import { useDrawer } from '@/contexts/interface'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { IUser } from '@/types/user'

import styles from '../aggrid.module.css'
import { CreatedAtColDef } from '../colDefs/createdAt.coldef'
import { EmailColDef } from '../colDefs/email.coldef'
import { PhoneColDef } from '../colDefs/phone.coldef'
import { StatusColDef } from '../colDefs/status.coldef'

export interface Props {
  data: IUser[]
}

export const TeamTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { setDrawer } = useDrawer()

  const defaultColDef = useMemo<ColDef>(
    () => ({
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
    }),
    []
  )

  const colDefs = useMemo<ColDef<IUser>[]>(
    () => [
      {
        field: 'name',
        headerName: t('username'),
        cellRenderer: (props: CustomCellRendererProps<IUser>) => {
          return (
            <div className={'flex flex-row gap-[16px] items-center w-full'}>
              <div>
                <Avatar
                  type={'monogram'}
                  size={'medium'}
                  shape={'round'}
                  value={props.value}
                />
              </div>
              <Typography variant={'label-small'} className={'truncate'}>
                {props.value}
              </Typography>
            </div>
          )
        },
        flex: 1,
        minWidth: 200,
        width: 400,
        initialWidth: 400,
      },
      EmailColDef(),
      PhoneColDef(),
      {
        field: 'role',
        headerName: t('role'),
        cellRenderer: (cellProps: CustomCellRendererProps<IUser>) =>
          AgGridCell({
            ...cellProps,
            value: capitalizeFirstLetter(cellProps.value),
            type: 'text',
          }),
        width: 190,
      },
      StatusColDef({
        context: 'USER',
      }),
      CreatedAtColDef(),
    ],
    [t]
  )

  return (
    <div
      className={`ag-theme-quartz ar-theme ${styles.grid} ${styles.no_cell_selection} h-full`}
    >
      <AgGridReact
        suppressContextMenu
        autoSizeStrategy={{
          type: 'fitGridWidth',
        }}
        gridOptions={{
          rowHeight: 72,
        }}
        rowData={props.data}
        onRowClicked={(e: RowClickedEvent<IUser>) => {
          if (!e.event?.defaultPrevented && e.data) {
            setDrawer({
              id: 'EDIT_SUPER_ADMIN_USER',
              user: e.data,
            })
          }
        }}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
      />
    </div>
  )
}
