import { CustomCellRendererProps } from 'ag-grid-react'

import Button from '@/components/button'
import { copyToClipboard } from '@/helpers/copyToClipboard'

export const AddressRenderer: React.FC<CustomCellRendererProps> = ({
  value,
}) => {
  return (
    <div className="flex justify-between items-center w-full">
      <div className="truncate">{value}</div>
      <Button.Shape
        hierarchy="tertiary"
        layout="icon"
        size="xsmall"
        icon={{
          name: 'copy',
        }}
        onClick={() => copyToClipboard(value, 'Address')}
      />
    </div>
  )
}
