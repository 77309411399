export const ROUTES = {
  ADMIN: {
    CUSTODIAN: {
      DETAIL: '/admin/custodian/:custodian_id',
      INDEX: '/admin/custodian',
    },
    ESCROW: {
      DETAIL: '/admin/escrow/:escrow_id',
      INDEX: '/admin/escrow',
    },
    MESSAGES: {
      INDEX: '/admin/messages',
    },
    ORGANIZATION: {
      DETAIL: '/admin/organization/:org_id',
      INDEX: '/admin/organization',
    },
    TEAM_MANAGEMENT: {
      INDEX: '/admin/team-management',
      ORG: '/admin/team-management/:org_id',
    },
    DISPOSAL: '/admin/disposal',
  },
  ASSETS: {
    DETAIL: '/assets/:asset_id',
    INDEX: '/assets',
  },
  AUTH: {
    INDEX: '/auth',
    RESET_PASSWORD: '/reset-password',
    TWO_FACTOR: '/auth/2fa',
    WORKSPACE: '/auth/workspace',
  },
  CASES: {
    DETAIL: '/cases/:case_id',
    INDEX: '/cases',
  },
  SUBJECT: {
    DETAIL: '/subjects/:subject_id',
    INDEX: '/subjects',
  },
  ESCROW: {
    DETAIL: '/escrow/:escrow_id',
    INDEX: '/escrow',
  },
  HEALTH: {
    DETAIL: '/health/:health_id',
    INDEX: '/health',
  },
  HELP: 'https://7639856.hs-sites.com/knowledge',
  PROFILE: {
    INDEX: '/profile',
    SET_NEW_PASSWORD: '/profile/set-new-password',
  },
  REGISTER: '/register',
  SHARES: '/shares',
  TRANSFERS: '/transfers/:transfer_id',
  TEAM_MANAGEMENT: '/team-management',
  REPORTS: '/reports',
  AGREEMENT: '/agreement',
}

export type Routes = keyof typeof ROUTES
