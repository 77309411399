import { useMutation } from '@tanstack/react-query'
import { Dispatch, SetStateAction } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { ButtonBasic } from '@/components/button/variants/basic.button'
import { Checkbox } from '@/components/checkbox'
import { DisposeAssetModalFormState } from '@/components/dropdown/controllers/views/DisposeAssetModal.types'
import Input from '@/components/input'
import { InputDropdown } from '@/components/input/variants/dropdown.input'
import { ListItem } from '@/components/list-item'
import { Modal } from '@/components/modal'
import { Radio } from '@/components/radio'
import { IAsset } from '@/types/asset'

type ApiResponse = {
  error: boolean
  message: null | string
  data: {
    beneficiary: {
      jurisdiction: string
    }
    asset_id: string
    organization_id: string
    destination_wallet: {
      address: string
    }
    amount: string
    file: {
      key: string
      name: string
      type: string
    }
  }
}

export const useDisposeAssetInitiateMutation = ({
  onSuccess,
}: {
  onSuccess?: (data: ApiResponse) => void
} = {}) => {
  const mutation = useMutation({
    mutationFn: async () => {
      // TODO: uncomment when API is ready
      // const client = await getClient((await getConfig()).BROKER_API_URL_V2)

      // return client
      //   .post(`/tx/validate`, {
      //     beneficiary: {
      //       jurisdiction: 'string',
      //     },
      //     asset_id: 'string',
      //     organization_id: 'string',
      //     destination_wallet: {
      //       address: 'string',
      //     },
      //     amount: 'string',
      //     file: {
      //       key: 'string',
      //       name: 'string',
      //       type: 'string',
      //     },
      //   })
      //   .then((response) => {
      //     return response.data
      //   })

      await new Promise((resolve) => setTimeout(resolve, 2000))

      const mockedResult = {
        error: false,
        message: 'string',
        data: {
          beneficiary: {
            jurisdiction: 'string',
          },
          asset_id: 'string',
          organization_id: 'string',
          destination_wallet: {
            address: 'string',
          },
          amount: 'string',
          file: {
            key: 'string',
            name: 'string',
            type: 'string',
          },
        },
      }

      onSuccess?.(mockedResult)

      return mockedResult
    },
  })

  return {
    initiate: mutation.mutateAsync,
    isInitiating: mutation.isPending,
    initiationResult: mutation.data,
  }
}

type Props = {
  asset: IAsset
  formState: DisposeAssetModalFormState
  setFormState: Dispatch<SetStateAction<DisposeAssetModalFormState>>
  initiateMutation: ReturnType<typeof useDisposeAssetInitiateMutation>
  onClose: () => void
}

export const DisposeAssetInitiateModal = ({
  asset,
  formState,
  setFormState,
  initiateMutation,
  onClose,
}: Props) => {
  const intl = useIntl()

  if (!('ticker' in asset)) {
    return null
  }

  const getVaspMessage = (selectedVasp: string | null) => {
    switch (selectedVasp) {
      case 'coinbase':
        return intl.formatMessage({
          defaultMessage: 'Coinbase',
          id: 'AjU/pP',
        })

      default:
        return intl.formatMessage({
          defaultMessage: 'No VASP',
          id: 'DU4Dck',
        })
    }
  }

  const hasFormErrors =
    (formState.accountType === 'organization'
      ? !formState.beneficiaryName
      : !formState.firstName || !formState.lastName) ||
    (!formState.selectedVasp && !formState.declarationOfOwnership)

  return (
    <Modal portal visible className="max-w-[500px]">
      <div className="p-6 flex flex-col gap-6">
        <div>
          <InputDropdown
            name="selectedVasp"
            label={intl.formatMessage({
              defaultMessage: 'Virtual Asset Service Provider (VASP)',
              id: '6tXnon',
            })}
            text={getVaspMessage(formState.selectedVasp)}
            items={[
              <ListItem
                key="no_vsap"
                title={getVaspMessage(null)}
                onClick={() => {
                  setFormState((prev) => ({
                    ...prev,
                    selectedVasp: null,
                  }))
                }}
                className={'pl-2 pr-2'}
              />,
              <ListItem
                key="coinbase"
                title={getVaspMessage('coinbase')}
                onClick={() => {
                  setFormState((prev) => ({
                    ...prev,
                    selectedVasp: 'coinbase',
                  }))
                }}
                className={'pl-2 pr-2'}
              />,
            ]}
          />
        </div>

        <div className="bg-gray-100 pt-[16px] pb-[24px] px-[24px] rounded-xl flex flex-col gap-4">
          <div className="flex flex-col gap-1 relative">
            <button
              className="flex items-center text-left"
              onClick={() =>
                setFormState((prev) => ({
                  ...prev,
                  accountType: 'organization',
                }))
              }
            >
              <div className="flex-col flex grow">
                <div className="text-sm font-extrabold">
                  <FormattedMessage defaultMessage="Organisation" id="ZA6cIU" />
                </div>
                <div className="text-sm font-medium text-gray-500">
                  <FormattedMessage
                    defaultMessage="Company or business"
                    id="WDzmHE"
                  />
                </div>
              </div>

              <div className="pointer-events-none select-none">
                <Radio
                  groupId=""
                  checked={formState.accountType === 'organization'}
                  id=""
                  value="organization"
                />
              </div>
            </button>
          </div>

          <button
            className="flex items-center text-left"
            onClick={() =>
              setFormState((prev) => ({ ...prev, accountType: 'person' }))
            }
          >
            <div className="flex-col flex grow">
              <div className="text-sm font-extrabold">
                <FormattedMessage defaultMessage="Individual" id="IgB6GU" />
              </div>
              <div className="text-sm font-medium text-gray-500">
                <FormattedMessage defaultMessage="A person" id="YW7kTf" />
              </div>
            </div>

            <div className="pointer-events-none select-none">
              <Radio
                groupId=""
                checked={formState.accountType === 'person'}
                id=""
                value="person"
              />
            </div>
          </button>

          {formState.accountType === 'organization' ? (
            <>
              <Input.Text
                label={intl.formatMessage({
                  defaultMessage: 'Beneficiary details',
                  id: 'GN24c9',
                })}
                placeholder={intl.formatMessage({
                  defaultMessage: 'e.g. Venkman and Spengler Ltd.',
                  id: 'mIdKb0',
                })}
                value={formState.beneficiaryName}
                onChange={(event) =>
                  setFormState((prev) => ({
                    ...prev,
                    beneficiaryName: event.target.value ?? '',
                  }))
                }
              />
            </>
          ) : formState.accountType === 'person' ? (
            <>
              <Input.Text
                label={intl.formatMessage({
                  defaultMessage: 'Beneficiary details',
                  id: 'GN24c9',
                })}
                placeholder={intl.formatMessage({
                  defaultMessage: 'First name',
                  id: 'pONqz8',
                })}
                value={formState.firstName}
                onChange={(event) =>
                  setFormState((prev) => ({
                    ...prev,
                    firstName: event.target.value ?? '',
                  }))
                }
              />
              <Input.Text
                placeholder={intl.formatMessage({
                  defaultMessage: 'Last name (surname)',
                  id: 't06iJg',
                })}
                value={formState.lastName}
                onChange={(event) =>
                  setFormState((prev) => ({
                    ...prev,
                    lastName: event.target.value ?? '',
                  }))
                }
              />
            </>
          ) : null}

          {formState.selectedVasp ? null : (
            <div>
              <div className="text-[0.875rem] font-[800] flex flex-1 text-[#212427]">
                <FormattedMessage
                  defaultMessage="Declaration of ownership"
                  id="ptcZ06"
                />
              </div>

              <button
                onClick={() =>
                  setFormState((prev) => ({
                    ...prev,
                    declarationOfOwnership: !prev.declarationOfOwnership,
                  }))
                }
                className="flex items-center mt-2 w-full bg-white pr-2 rounded-md text-sm font-medium"
              >
                <Checkbox
                  id=""
                  checked={
                    formState.declarationOfOwnership ? 'checked' : 'unchecked'
                  }
                />

                <FormattedMessage
                  defaultMessage="The beneficiary owns this wallet"
                  id="AGimUA"
                />
              </button>
            </div>
          )}
        </div>

        <div className="flex gap-2">
          <ButtonBasic
            onClick={() => initiateMutation.initiate()}
            state={
              initiateMutation.isInitiating
                ? 'loading'
                : hasFormErrors
                  ? 'disabled'
                  : undefined
            }
            label={intl.formatMessage({
              defaultMessage: 'Confirm',
              id: 'N2IrpM',
            })}
          />
          <ButtonBasic
            onClick={onClose}
            hierarchy="tertiary"
            label={intl.formatMessage({
              defaultMessage: 'Cancel',
              id: '47FYwb',
            })}
          />
        </div>
      </div>
    </Modal>
  )
}
