import { ColDef } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { t } from 'i18next'

import { Typography } from '@/components/typography'
import { formatAssetPrice } from '@/helpers/formatValue'
import { IAsset } from '@/types/asset'

export const AssetValueColDef = () => {
  return {
    headerName: t('value'),
    type: 'rightAligned',
    filter: 'agNumberColumnFilter',
    filterValueGetter: (params) => {
      return params.data?.price?.value ?? 0
    },
    comparator: (_a, _b, nodeA, nodeB) => {
      return (nodeA.data?.price?.value ?? 0) - (nodeB.data?.price?.value ?? 0)
    },
    cellRenderer: (cellProps: CustomCellRendererProps<IAsset>) => {
      return (
        <div className={'flex flex-row justify-end w-full'}>
          <Typography
            variant={'paragraph-small'}
            className={'text-right truncate'}
          >
            {formatAssetPrice(cellProps.data?.price)}
          </Typography>
        </div>
      )
    },
    width: 200,
    initialWidth: 200,
  } as ColDef<IAsset>
}
