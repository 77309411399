import { t } from 'i18next'

import { Badge } from '@/components/badge'
import { Icon } from '@/components/icon'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { IAssetStatus } from '@/types/asset'

import { Chip, Props as ChipProps } from '../index'

interface Props {
  size: ChipProps['size']
  status: string | IAssetStatus
  context?:
    | 'ESCROW'
    | 'ESCROW_FEE'
    | 'ASSET'
    | 'OPERATION'
    | 'USER'
    | 'WORKSPACE_MEMBER'
  outline?: boolean
}

const getStatus = (status: string | IAssetStatus) => {
  const statusName = typeof status === 'string' ? status : status?.name
  return statusName || 'PENDING'
}

export const StatusChip = (props: Props) => {
  const status = getStatus(props.status).toUpperCase()

  // Grab the color based on the status
  const color = [
    'ACTIVE',
    'AWARDED',
    'RETURNED',
    'LIQUIDATED',
    'READY',
    'TRANSFERRED',
    'COMPLETED',
  ].includes(status)
    ? 'green'
    : ['SEIZED', 'ARCHIVED', 'DELETED', 'REJECTED', 'DISABLED'].includes(status)
      ? 'red'
      : [
            'PENDING',
            'AWAITING_CHECK',
            'AWAITING_APPROVAL',
            'AWAITING_RELEASE',
          ].includes(status)
        ? 'yellow'
        : ['ADOPTED', 'AWARDED', 'FORFEITED'].includes(status)
          ? 'blue'
          : ['FAILED'].includes(status)
            ? 'error'
            : 'white'

  // Grab the leading component based on status
  const leading =
    status === 'FAILED' || status === 'ERROR' ? (
      <Icon name={'circle-exclamation'} variant={'solid'} />
    ) : (
      <Badge
        size={'small'}
        type={'dot'}
        color={color === 'error' ? 'yellow' : color}
      />
    )

  return (
    <Chip
      label={
        props.context
          ? t(
              `status_${(props.context === 'WORKSPACE_MEMBER' ? 'USER' : props.context).toLowerCase()}_${status.toLowerCase()}`
            )
          : capitalizeFirstLetter(t(status.toLowerCase())).replace(/_/g, ' ')
      }
      leading={leading}
      size={props.size}
      type={props.outline ? 'outline' : 'filled'}
      colour={color}
      className="rounded-full"
    />
  )
}
