import { useIntl } from 'react-intl'

import { Confirmation } from './Confirmation'
import { Header } from './Header'
import { RequestedBy } from './RequestedBy'

interface Props {
  status: 'approved' | 'declined'
  onClose: () => void
}

export const ConfirmationScreen: React.FC<Props> = ({ status, onClose }) => {
  const intl = useIntl()

  return (
    <div>
      <Header
        ticker="BTC"
        value={53.11}
        reason={intl.formatMessage({
          defaultMessage: 'Return to defendant',
          id: '+J1Zy3',
        })}
      />
      <RequestedBy
        name="Everett Wiegand"
        date="14/06/2023"
        time="12:45:16 PM"
      />
      <Confirmation status={status} onClose={onClose} />
    </div>
  )
}
