import { ColDef } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { t } from 'i18next'

import { Typography } from '@/components/typography'
import { IAsset } from '@/types/asset'
import { IOperation } from '@/types/operations'

export const AssetCaseColDef = (operations: IOperation[]) => {
  return {
    headerName: t('case'),
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      return (
        operations?.find((o) => o.id === params.data?.operation_id)?.name ?? ''
      )
    },
    filterValueGetter: (params) => {
      return (
        operations?.find((o) => o.id === params.data?.operation_id)?.name ?? ''
      )
    },
    cellRenderer: (cellProps: CustomCellRendererProps<IAsset>) => {
      return (
        <div className={'flex flex-row w-full'}>
          <Typography
            variant={'paragraph-small'}
            className={'truncate'}
            tooltip={cellProps.value}
          >
            {cellProps.value}
          </Typography>
        </div>
      )
    },
    width: 200,
    initialWidth: 200,
  } as ColDef<IAsset>
}
