import { t } from 'i18next'

import { createWorkspace } from '@/api/workspaces/v1'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { WorkspaceManager } from '@/contexts/workspace/manager'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { IsNot } from '@/helpers/test'
import { key as QueryOrgWorkspacesKey } from '@/hooks/queries/useOrgWorkspaces'
import { ActionResponse } from '@/types/actions'
import { IWorkspace } from '@/types/workspace'

export const CreateWorkspace = async (
  formData: FormData
): Promise<ActionResponse<IWorkspace>> => {
  const name = formData.get('name')?.toString()?.trim() ?? ''
  const description = formData.get('description')?.toString()

  // Check basic details
  if (IsNot(name?.trim(), REGEX.IS_WORKSPACE_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'name',
        error: t('invalid_workspace_name_provided'),
      },
    ])
  } else if (
    WorkspaceManager.workspaces.find(
      (w) => w.name.toLowerCase() === name.toLowerCase()
    )
  ) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'name',
        error: t('name_used_in_existing_workspace'),
      },
    ])
  }

  // Create the new workspace
  const newWorkspaceRes = await createWorkspace({
    name,
    description,
  })

  // Check if the call failed
  if (newWorkspaceRes.error_code || newWorkspaceRes.error) {
    throw new Error(ExtractErrorFrom(newWorkspaceRes))
  }

  // If everything succeeded we can update our workspaces
  await WorkspaceManager.updateWorkspaces()

  // Invalidate the org workspaces
  await queryClient.invalidateQueries({
    queryKey: [QueryOrgWorkspacesKey],
  })

  return {
    error: false,
    message: `${t('new_workspace')} '${name}' ${t('added_successfully')}`,
    completion: 'COMPLETE',
    data: newWorkspaceRes.data, // New workspace Id
  }
}
