import { ColDef } from 'ag-grid-community'
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from '@/components/avatar'
import Button from '@/components/button'
import Chip from '@/components/chip'
import AgGridCell from '@/components/table/components/cell.aggrid'
import { Typography } from '@/components/typography'
import { useAuth } from '@/contexts/auth'
import { getCryptoImageUrl } from '@/helpers/assetTitleAndIcon'
import { copyToClipboard } from '@/helpers/copyToClipboard'
import { IEscrowAsset } from '@/types/escrows'

import styles from '../aggrid.module.css'

export interface Props {
  data: IEscrowAsset[]
  canSetDestinationAddress?: boolean
  onAddDestinationAddress?: (asset: IEscrowAsset) => void
}

export const AssetsEscrowTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const defaultColDef = useMemo<ColDef>(
    () => ({
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
    }),
    []
  )

  const colDefs = useMemo<ColDef<IEscrowAsset>[]>(
    () => [
      {
        field: 'ticker',
        headerName: t('token'),
        cellRenderer: (props: CustomCellRendererProps<IEscrowAsset>) => {
          return (
            <div className={'flex flex-row gap-[16px]'}>
              <Avatar
                type={'user'}
                size={'medium'}
                image={getCryptoImageUrl(props.data)}
                fallbackImage={getCryptoImageUrl()}
              />
              <div className={'flex flex-col gap-[4px] justify-center'}>
                <Chip.Basic
                  type={'outline'}
                  colour={'grey'}
                  label={props.value}
                  size={'small'}
                />
              </div>
            </div>
          )
        },
        flex: 1,
        width: 150,
        minWidth: 150,
      },
      {
        field: 'wallet.address',
        headerName: t('custody_address'),
        cellRenderer: (cellProps: CustomCellRendererProps<IEscrowAsset>) => {
          return (
            <div className={'flex flex-row gap-[16px] items-center w-full'}>
              <Typography
                variant={'paragraph-small'}
                className={'text-right truncate'}
              >
                {cellProps.value ?? '-'}
              </Typography>
              <Button.Shape
                layout={'icon'}
                size={'xsmall'}
                shape={'square'}
                hierarchy={'tertiary'}
                icon={{ name: 'copy' }}
                onClick={() => {
                  copyToClipboard(cellProps.value, t('custody_address'))
                }}
              />
            </div>
          )
        },
        width: 300,
      },
      {
        headerName: t('destination_address'),
        cellRenderer: (cellProps: CustomCellRendererProps<IEscrowAsset>) => {
          if (cellProps.data?.destination_wallet?.address) {
            return (
              <div className={'flex flex-row gap-[16px] items-center w-full'}>
                <Typography
                  variant={'paragraph-small'}
                  className={'text-right truncate'}
                >
                  {cellProps.data.destination_wallet.address ?? '-'}
                </Typography>
                <Button.Shape
                  layout={'icon'}
                  size={'xsmall'}
                  shape={'square'}
                  hierarchy={'tertiary'}
                  icon={{ name: 'copy' }}
                  onClick={() => {
                    if (cellProps.data?.destination_wallet?.address) {
                      copyToClipboard(
                        cellProps.data.destination_wallet.address,
                        t('destination_address')
                      )
                    }
                  }}
                />
              </div>
            )
          } else if (props.canSetDestinationAddress && user?.role === 'super') {
            return (
              <Button.Shape
                layout={'icon'}
                size={'small'}
                shape={'square'}
                hierarchy={'secondary'}
                icon={{ name: 'plus' }}
                onClick={() => {
                  props.onAddDestinationAddress &&
                    props.onAddDestinationAddress(cellProps.data!)
                }}
              />
            )
          } else {
            return AgGridCell({ ...cellProps, value: '-', type: 'text' })
          }
        },
        width: 300,
      },
      {
        field: 'balance',
        headerName: t('balance'),
        cellRenderer: (cellProps: CustomCellRendererProps<IEscrowAsset>) => {
          return (
            <Typography variant={'paragraph-small'} className={'text-right'}>
              {cellProps.value ?? '-'}
            </Typography>
          )
        },
        width: 140,
      },
    ],
    [t, props.onAddDestinationAddress]
  )

  return (
    <div
      className={`ag-theme-quartz ar-theme ${styles.grid} ${styles.no_cell_selection} ${styles.no_row_selection}`}
      style={{ position: 'relative' }}
    >
      <AgGridReact
        suppressContextMenu
        autoSizeStrategy={{
          type: 'fitGridWidth',
        }}
        gridOptions={{
          domLayout: 'autoHeight',
          rowHeight: 72,
        }}
        rowData={props.data}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
      />
    </div>
  )
}
