import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import Card from '@/components/card'
import { Form } from '@/components/form'
import { Group } from '@/components/group'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Paragraph } from '@/components/paragraph'
import { Radio } from '@/components/radio'
import { Switch } from '@/components/switch'
import { MAX_ALLOWED_OWNERS } from '@/constants/auth'
import { useAuth } from '@/contexts/auth'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { useWorkspace } from '@/contexts/workspace'
import { roleDescriptionTranslated, roleTitleTranslated } from '@/helpers/roles'
import { useGroups } from '@/hooks/queries/useGroups'
import { useOrg } from '@/hooks/queries/useOrg'
import { useRoles } from '@/hooks/queries/useRoles'
import { useWorkspaceMembers } from '@/hooks/queries/useWorkspaceMembers'
import { ActionResponse } from '@/types/actions'
import { IWorkspaceMember } from '@/types/workspace'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  userId: string
}
export const EditTeamUserDrawer = (props: Props) => {
  const { user } = useAuth()
  const { org } = useOrg()
  const { roles } = useRoles()
  const { selectedWorkspace } = useWorkspace()
  const { groups } = useGroups()
  const { members } = useWorkspaceMembers()
  const [_member, setMember] = useState<IWorkspaceMember>()
  const [_loading, setLoading] = useState<boolean>(true)
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const [_hasLoadedOwnership, setHasLoadedOwnership] = useState<boolean>(false)
  const [_ownerCount, setOwnerCount] = useState<number>(0)
  const [_isEditedUserAPrimaryOwner, setIsEditedUserAPrimaryOwner] =
    useState<boolean>(false)
  const [_isEditedUserAnOrgOwner, setIsEditedUserAnOrgOwner] =
    useState<boolean>(false)
  const [
    _isEditedUserAnOrgOwnerOriginally,
    setIsEditedUserAnOrgOwnerOriginally,
  ] = useState<boolean>(false)
  const [_isActionUserAnAccountOwner, setIsActionUserAnAccountOwner] =
    useState<boolean>(false)
  const isCurrentUser = user?.id === _member?.user_id

  useEffect(() => {
    if (members) {
      // Find the user
      const member = members?.find((member) => member.user_id === props.userId)
      if (!member) {
        BannerManager.showBanner({
          variant: 'page',
          type: 'error',
          title: t('user_not_found'),
        })
        return props.onClose()
      }

      // Set the found user
      setMember(member)
      setLoading(false)
    }
  }, [members])

  useEffect(() => {
    let _isActionUserAnAccountOwner: boolean = false
    let _isEditedUserAnAccountOwner: boolean = false
    let _ownerCount: number = 0

    // The owner roles can only be edited:
    // + If the actioning user is an owner
    // + If the edited user is not the root org owner
    // + If there are not already MAX_ALLOWED set

    // Check if the user is the orgs primary owner
    setIsEditedUserAPrimaryOwner(org?.primary_owner.id === props.userId)

    // Find the owner group and count how many users are inside
    const ownerGroup = groups?.find((g) => g.name.toLowerCase() === 'owner')
    if (ownerGroup) {
      // Check if the user that is attempting to edit is an account owner
      _isActionUserAnAccountOwner =
        (ownerGroup.users ?? []).find((u) => u.id === user?.id) !== undefined

      // Check if the user that we're editing is an account owner
      _isEditedUserAnAccountOwner =
        (ownerGroup.users ?? []).find((u) => u.id === props.userId) !==
        undefined

      _ownerCount = (ownerGroup.users ?? []).length
    }

    // Set the account owner
    setIsActionUserAnAccountOwner(_isActionUserAnAccountOwner)
    setIsEditedUserAnOrgOwner(_isEditedUserAnAccountOwner)
    setIsEditedUserAnOrgOwnerOriginally(_isEditedUserAnAccountOwner)

    // Set the owner count
    setOwnerCount(_ownerCount)

    // Set that we've loaded the ownership information
    setHasLoadedOwnership(true)
  }, [groups, user])

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = () => {
    ToastManager.showToast({
      type: 'success',
      text: t('user_updated_successfully'),
    })

    props.onClose()
  }

  // const deleteUser = () => {
  //   if (_member && _member.user) {
  //     setModal({
  //       id: 'DELETE_USER',
  //       user: _member.user,
  //       onCancel: () => {},
  //       onSuccess: () => {
  //         props.onClose()
  //       },
  //     })
  //   }
  // }

  return (
    <Drawer
      title={t('user_details')}
      visible={props.visible}
      minHeight={700}
      onClose={props.onClose}
    >
      {_loading ? (
        <Card.PageState
          type={'loading'}
          title={t('fetching_data')}
          description={t('please_wait')}
        />
      ) : (
        <Form<string, INTENTS>
          type={'other'}
          buttons={[
            <Button.Basic
              key={`team_user_apply_changed_btn`}
              testId={'btn_update_user'}
              label={t('apply_changes')}
              state={
                !groups || groups.length < 1
                  ? 'disabled'
                  : _submitting
                    ? 'loading'
                    : 'default'
              }
              withAttributes={{
                name: 'intent',
                type: 'submit',
                value: INTENTS.EDIT_USER,
              }}
            />,
            // <Button.Basic
            //   key={`team_user_delete_user_btn`}
            //   label={t('delete_user')}
            //   hierarchy={'tertiary'}
            //   onClick={deleteUser}
            //   state={isCurrentUser ? 'disabled' : undefined}
            // />,
          ]}
          onSubmit={onSubmit}
          onSuccess={onSuccess}
          onException={onException}
          onFailed={clearSubmitting}
          onUnknownResponse={clearSubmitting}
        >
          <div className={'flex flex-col gap-8'}>
            <input
              type="hidden"
              name="workspace_id"
              value={selectedWorkspace?.id ?? ''}
            />
            <input
              type="hidden"
              name="user_obj"
              value={JSON.stringify(_member?.user ?? {})}
            />

            <Group label={t('identification')}>
              <Paragraph
                description={t('user_details_description')}
                spacerOverrides={{ description: [] }}
              />

              <div className={'flex flex-col gap-6'}>
                <Input.Text
                  label={t('full_name')}
                  id="name"
                  testId={'user_name'}
                  name="name"
                  defaultValue={_member?.user?.name ?? ''}
                />

                <Input.Email
                  label={t('email')}
                  testId={'user_email'}
                  id="email"
                  name="email"
                  dynamicInput
                  defaultValue={_member?.user?.email ?? ''}
                  state={'readonly'}
                />
              </div>

              <div className={'flex flex-col gap-6 max-w-[23.4375rem]'}>
                <ListItem
                  title={t('platform_access')}
                  description={t('status_description')}
                  style={'bold'}
                  trailing={
                    <Switch
                      name={'status'}
                      enabled={_member?.user?.disabled === false}
                      size={'large'}
                      state={isCurrentUser ? 'disabled' : undefined}
                    />
                  }
                />
              </div>
            </Group>

            <Group label={t('roles')}>
              <Paragraph
                description={t('permissions_description')}
                spacerOverrides={{ description: [] }}
              />

              {false &&
                members &&
                members.filter((m) => m.role.name.toLowerCase() === 'signer')
                  .length < 2 && (
                  <Input.Component.Hint
                    style={'message'}
                    label={t('permissions_hint', {
                      SIGNER_COUNT: members.filter(
                        (u) => u.role.name.toLowerCase() === 'signer'
                      ).length,
                    })}
                  />
                )}

              <div className="flex flex-col w-full gap-6 max-w-[23.4375rem]">
                {(_isActionUserAnAccountOwner || _isEditedUserAnOrgOwner) && (
                  <ListItem
                    title={t('role_title_owner')}
                    description={t('role_description_owner')}
                    style={'bold'}
                    trailing={
                      <Switch
                        name={'is_owner'}
                        state={
                          _isEditedUserAPrimaryOwner ||
                          _isEditedUserAnOrgOwnerOriginally || // don't allow others to change it
                          !_isActionUserAnAccountOwner ||
                          (_ownerCount >= MAX_ALLOWED_OWNERS &&
                            !_isEditedUserAnOrgOwner)
                            ? 'disabled'
                            : 'default'
                        }
                        enabled={_isEditedUserAnOrgOwner}
                        size={'large'}
                        onChanged={(enabled: boolean) => {
                          setIsEditedUserAnOrgOwner(enabled)
                          setOwnerCount((c) => {
                            return enabled ? c + 1 : c - 1
                          })
                        }}
                      />
                    }
                  />
                )}

                {_hasLoadedOwnership && !_isEditedUserAnOrgOwner && (
                  <div
                    className={'flex flex-col gap-6 max-w-[23.4375rem]'}
                    data-testid="checkbox-group"
                  >
                    {roles.map(
                      (role) =>
                        role.name.toLowerCase() !== 'owner' && (
                          <ListItem
                            key={role.id}
                            title={roleTitleTranslated(role)}
                            description={roleDescriptionTranslated(role)}
                            style={'bold'}
                            withClass="whitespace-normal overflow-visible"
                            trailing={
                              <Radio
                                id={'role_id'}
                                groupId={'role_id'}
                                defaultChecked={
                                  _member?.role.name.toLowerCase() ===
                                  role.name.toLowerCase()
                                }
                                value={role.id}
                              />
                            }
                          />
                        )
                    )}
                  </div>
                )}
              </div>
            </Group>
          </div>
        </Form>
      )}
    </Drawer>
  )
}
