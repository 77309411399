import { t } from 'i18next'

import { recoverPasswordForEmail } from '@/api/auth'
import { REGEX } from '@/constants/regex'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { Is } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'

export const RecoverPassword = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const email = formData.get('forgotten_password_email')?.toString()?.trim()

  // Validate the fields
  const isEmailValid = Is(email, REGEX.IS_EMAIL)

  // Throw any errors for the invalid fields
  if (!isEmailValid) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'forgotten_password_email',
        error: t('invalid_email_address_provided'),
      },
    ])
  }

  // Make the call to recover the email
  const res = await recoverPasswordForEmail(email)

  // Check the response
  if (res.error_code || res.error) {
    throw new Error(ExtractErrorFrom(res))
  }

  // Otherwise the email has been sent so send a happy response
  return {
    error: false,
    completion: 'COMPLETE',
    message: t('password_reset'),
    data: true,
  }
}
