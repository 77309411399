import { CustomCellRendererProps } from 'ag-grid-react'
import moment from 'moment'

export const CreatedRenderer: React.FC<CustomCellRendererProps> = ({
  value,
}) => {
  return (
    <div className="leading-5">
      <div>{moment(value).format('DD MMM YYYY')}</div>
      <div className="text-[#585B5F]">{moment(value).format('HH:mm:ss')}</div>
    </div>
  )
}
