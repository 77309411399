import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import {
  IAsset,
  IAssetCost,
  IAssetNote,
  IAssetStatus,
  ICreateAsset,
  IDigitalAsset,
  IFiatCashAsset,
  IHighValuePersonalPropertyAsset,
  IPhysicalAsset,
  IRealEstateAsset,
  IVehicleAsset,
} from '@/types/asset'
import { AuditLog } from '@/types/audit-log'
import { ITransferRequest } from '@/types/transfer'

interface IGetPhysicalAssetsRealEstate {
  real_estate: IRealEstateAsset
}

interface IGetPhysicalAssetsVehicle {
  vehicle: IVehicleAsset
}

interface IGetPhysicalAssetsHighValue {
  high_value_personal_property: IHighValuePersonalPropertyAsset
}

interface IGetPhysicalAssetsFiatCash {
  fiat_cash: IFiatCashAsset
}

type IGetPhysicalAssets = Array<
  | IGetPhysicalAssetsRealEstate
  | IGetPhysicalAssetsVehicle
  | IGetPhysicalAssetsHighValue
  | IGetPhysicalAssetsFiatCash
>

export type IAssetOperationState =
  | 'ANY_OPERATIONS'
  | 'HAS_OPERATIONS'
  | 'HAS_NO_OPERATIONS'

/**
 * get digital_assets by operation_id
 * @param opId operation_id
 * @returns IDigitalAsset[]
 */
export const getDigitalByCaseId = async (
  opId: string
): Promise<BaseResponse<IDigitalAsset[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client.get(`/operations/${opId}/assets/digital`).then((response) => {
    return response.data
  })
}

/**
 * get physical_assets by operation_id
 * @param opId operation_id
 * @returns IPhysicalAsset[]
 */
export const getPhysicalByCaseId = async (
  opId: string
): Promise<BaseResponse<IPhysicalAsset[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client.get(`/operations/${opId}/assets/physical`).then((response) => {
    return convertPhysicalAssetsToSimpleArray(response.data)
  })
}

const convertPhysicalAssetsToSimpleArray = (
  res: BaseResponse<IGetPhysicalAssets>
): BaseResponse<IPhysicalAsset[]> => {
  if (!res.data) {
    return {
      error_code: res.error_code,
      error: res.error,
      message: res.message,
      data: [],
    }
  }

  // The data returned here is weird but we can't mod the model from the BE due to legacy
  // reasons... so we're going to simplify it here so the rest of the site doesn't suffer!
  const high_value_assets: IHighValuePersonalPropertyAsset[] = res.data
    .filter((a) => 'high_value_personal_property' in a)
    .map((a) => (a as IGetPhysicalAssetsHighValue).high_value_personal_property)
  const real_estate_assets: IRealEstateAsset[] = res.data
    .filter((a) => 'real_estate' in a)
    .map((a) => (a as IGetPhysicalAssetsRealEstate).real_estate)
  const vehicle_assets: IVehicleAsset[] = res.data
    .filter((a) => 'vehicle' in a)
    .map((a) => (a as IGetPhysicalAssetsVehicle).vehicle)
  const fiat_cash_assets: IFiatCashAsset[] = res.data
    .filter((a) => 'fiat_cash' in a)
    .map((a) => (a as IGetPhysicalAssetsFiatCash).fiat_cash)

  return {
    error_code: res.error_code,
    error: res.error,
    message: res.message,
    data: [
      ...high_value_assets,
      ...real_estate_assets,
      ...vehicle_assets,
      ...fiat_cash_assets,
    ],
  }
}

/**
 * get all digital_assets
 * @returns IDigitalAsset[]
 */
export const getDigitalAssets = async (
  withOperationState?: IAssetOperationState
): Promise<BaseResponse<IDigitalAsset[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .get<
      BaseResponse<IDigitalAsset[]>
    >(`/assets/digital${withOperationState === 'HAS_NO_OPERATIONS' ? '?by_operation_exists=false' : withOperationState === 'HAS_OPERATIONS' ? '?by_operation_exists=true' : ''}`)
    .then((response) => {
      return response.data
    })
}

/**
 * get all physical_assets
 * @returns IPhysicalAsset[]
 */
export const getPhysicalAssets = async (
  withOperationState?: IAssetOperationState
): Promise<BaseResponse<IPhysicalAsset[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .get<
      BaseResponse<IGetPhysicalAssets>
    >(`/assets/physical${withOperationState === 'HAS_NO_OPERATIONS' ? '?by_operation_exists=false' : withOperationState === 'HAS_OPERATIONS' ? '?by_operation_exists=true' : ''}`)
    .then((response) => {
      return convertPhysicalAssetsToSimpleArray(response.data)
    })
}

/**
 * Creating an asset (digital/physical)
 * @param data IAsset
 * @returns
 */
export const createAsset = async (
  data: ICreateAsset
): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .post(`/assets`, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data
    })
}

/**
 * Creating an asset (digital/physical)
 * @param data IAsset
 * @returns
 */
export const updateAsset = async (
  data: IAsset
): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client.put(`/assets/${data.id}`, data).then((response) => {
    return response.data
  })
}

/**
 * Create/Update Note
 * @param assetId string -> asset_id to update Note
 * @param data IAssetNote -> Notes data
 * @param isEditing boolean -> false: create, true: edit
 * @returns asset with created/modified Note
 */
export const createAssetNote = async (
  assetId: string,
  data: IAssetNote
): Promise<BaseResponse<IAsset>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .post(`/assets/${assetId}/notes`, data)
    .then((response) => {
      return response.data
    })
}

export const updateAssetNote = async (
  assetId: string,
  data: IAssetNote
): Promise<BaseResponse<IAsset>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .put(`/assets/${assetId}/notes/${data.id}`, data)
    .then((response) => {
      return response.data
    })
}

/**
 * Delete Note
 * @param assetId string -> asset_id to update Note
 * @param data IAssetNote -> Note data
 * @returns asset with deleted Note
 */
export const deleteAssetNote = async (
  assetId: string,
  noteId: string
): Promise<BaseResponse<boolean>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .delete<BaseResponse<boolean>>(`/assets/${assetId}/notes/${noteId}`)
    .then((response) => {
      return response.data
    })
}

/**
 * Create/Update Cost
 * @param assetId string -> asset_id to update Cost
 * @param data IAssetNote -> Cost data
 * @param isEditing boolean -> false: create, true: edit
 * @returns asset with created/modified Cost
 */
export const createAssetCosts = async (
  assetId: string,
  data: IAssetCost
): Promise<BaseResponse<IAsset>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .post<BaseResponse<IAsset>>(`/assets/${assetId}/costs`, data)
    .then((response) => {
      return response.data
    })
}

export const updateAssetCost = async (
  assetId: string,
  data: IAssetCost
): Promise<BaseResponse<IAsset>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .put<BaseResponse<IAsset>>(`/assets/${assetId}/costs/${data.id}`, data)
    .then((response) => {
      return response.data
    })
}

/**
 * Delete Cost
 * @param assetId string -> asset_id to update Cost
 * @param data IAssetNote -> Cost data
 * @returns asset with deleted Cost
 */
export const deleteAssetCost = async (
  assetId: string,
  costId: string
): Promise<BaseResponse<boolean>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .delete<BaseResponse<boolean>>(`/assets/${assetId}/costs/${costId}`)
    .then((response) => {
      return response.data
    })
}

/**
 * Create/Update Status
 * @param assetId string -> asset_id to update Status
 * @param data IAssetNote -> Status data
 * @param isEditing boolean -> false: create, true: edit
 * @returns asset with created/modified Status
 */
export const createAssetStatus = async (
  assetId: string,
  data: IAssetStatus,
  isEditing?: boolean
): Promise<BaseResponse<IAsset>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  const method = isEditing ? 'put' : 'post'
  const url = isEditing
    ? `/assets/${assetId}/statuses`
    : `/assets/${assetId}/statuses/${data.id}`
  return await client[method]?.(url, data).then((response) => {
    return response.data
  })
}

/**
 * Delete Status
 * @param assetId string -> asset_id to update Status
 * @param data IAssetNote -> Status data
 * @returns asset with deleted Status
 */
export const deleteAssetStatus = async (
  assetId: string,
  data: IAssetStatus
): Promise<BaseResponse<IAsset>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .delete(`/assets/${assetId}/statuses/${data.id}`)
    .then((response) => {
      return response.data
    })
}

/**
 * Import assets via a file
 * @param type string - representing asset type
 * @param file File - the xlsx file to be imported
 * @param organisationId string - the org id
 * @param workspaceId string - the workspace id
 * @returns null
 */
export const importAssets = async (
  type:
    | 'digital'
    | 'vehicle'
    | 'real-estate'
    | 'high-value-personal-property'
    | 'fiat-cash',
  file: File,
  organisationId: string,
  workspaceId: string
): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)

  // Create a formdata obj to hold the file
  const formData = new FormData()
  formData.append('myFile', file)

  // Post away!
  return await client
    .post(
      `/assets/${type}/import?organization_id=${organisationId}&workspace_id=${workspaceId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((response) => {
      return response.data
    })
}

/**
 * Shares asset
 * @param data IAsset
 * @returns
 */
export const shareAsset = async (
  email: string,
  message: string,
  assetIds: string[]
): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .post(`/assets/share`, {
      email,
      message,
      asset_ids: assetIds,
    })
    .then((response) => {
      return response.data
    })
}

/**
 * Shares assets
 * @param data IAsset
 * @returns
 */
export const getSharedAssets = async (
  token: string
): Promise<BaseResponse<IAsset[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .get(`/assets/share`, {
      headers: {
        'AR-Token': token,
      },
    })
    .then((response) => {
      return response.data
    })
}

/**
 * Transfers asset
 * @param data IAsset
 * @returns transferRequestId
 */
export const transferAsset = async (
  toWorkspaceId: string,
  fromWorkspaceId: string,
  assetIds: string[]
): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .post(`/transfer-requests`, {
      to_workspace_id: toWorkspaceId,
      from_workspace_id: fromWorkspaceId,
      asset_ids: assetIds,
    })
    .then((response) => {
      return response.data
    })
}

/**
 * Get Transfers asset requests
 * @param data IAsset
 * @returns
 */
export const getTransferRequests = async (
  id: string
): Promise<BaseResponse<ITransferRequest>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client.get(`/transfer-requests/${id}`).then((response) => {
    return response.data
  })
}

/**
 * Approve Transfer Request
 * @param data IAsset
 * @returns
 */
export const approveTransferRequest = async (
  id: string
): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .post(`/transfer-requests/${id}/approve`, {})
    .then((response) => {
      return response.data
    })
}

/**
 * Decline Transfer Request
 * @param data IAsset
 * @returns
 */
export const declineTransferRequest = async (
  id: string
): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .post(`/transfer-requests/${id}/decline`, {})
    .then((response) => {
      return response.data
    })
}

/*
 * Get an asset by id (digital/physical)
 * @param assetId: string
 * @returns
 */
export const getAssetById = async (
  assetId: string
): Promise<BaseResponse<IAsset>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client.get(`/assets/${assetId}`).then((response) => {
    return response.data
  })
}

/**
 * Request a synchronous balance update for a digital asset
 */
export const postRequestDigitalAssetBalanceUpdate = async ({
  assetId,
  assetSource,
}: {
  assetId: string
  assetSource: 'CUSTODY' | 'ESCROW'
}): Promise<BaseResponse<void>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .post<BaseResponse<void>>(`/jobs/balances/assets/${assetId}`, {
      asset_source: assetSource,
    })
    .then((response) => {
      return response.data
    })
}

/*
 * Get audit logs for asset
 */
export const getAuditLogsForAsset = async (params: { assetId: string }) => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .get<
      BaseResponse<AuditLog[]>
    >(`/audits?${new URLSearchParams({ asset_id: params.assetId, limit: String(100000) }).toString()}`)
    .then((response) => response.data)
}

/*
 * Get audit logs for defendant
 */
export const getAuditLogsForDefendant = async (params: {
  defendantId: string
}) => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .get<
      BaseResponse<AuditLog[]>
    >(`/audits?${new URLSearchParams({ defendant_id: params.defendantId, limit: String(100000) }).toString()}`)
    .then((response) => response.data)
}

/*
 * Get audit logs for case
 */
export const getAuditLogsForCase = async (params: { caseId: string }) => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return await client
    .get<
      BaseResponse<AuditLog[]>
    >(`/audits?${new URLSearchParams({ operation_id: params.caseId, limit: String(100000) }).toString()}`)
    .then((response) => response.data)
}
