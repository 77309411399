import {
  ChangeEvent,
  ClipboardEvent,
  createRef,
  FocusEvent,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'

import { InputElement } from '../components/element.input'
import { InputField } from '../components/field.input'
import { InputFrame, Props as FrameProps } from '../components/frame.input'
import { InputHint, Props as HintProps } from '../components/hint.input'
import { InputLabel, Props as LabelProps } from '../components/label.input'

export type Props = {
  length: number
  name: string
  state?: 'default' | 'disabled' | 'error' | 'readonly'
  label?: string | ReactElement<LabelProps>
  hint?: string | ReactElement<HintProps>
  onComplete?: (pin: string) => void
  className?: string
} & Omit<FrameProps, 'state'>

export const PinInput = (props: Props) => {
  const inputRefs = Array.from({ length: props.length }).map(() =>
    createRef<HTMLInputElement>()
  )
  const [_pin, setPin] = useState<string[]>(
    Array.from({ length: props.length }).map(() => '')
  )
  const currIndex = useRef<number>(-1)

  const onValueChange = (value: string, index: number) => {
    const currPin = [..._pin]
    currPin[index] = value
    setPin(currPin)

    // Make sure the current index is set correctly
    currIndex.current = index

    // Move focus to another input
    if (value.length > 0 && index < props.length - 1) {
      inputRefs[index + 1].current?.focus()
    }

    // Check if we've got a full pin
    if (currPin.filter((p) => p.trim().length <= 0).length <= 0) {
      // We havent found an empty pin so we should be complete
      props.onComplete && props.onComplete(currPin.join(''))
    }
  }

  const onFocus = (_e: FocusEvent<HTMLInputElement>, index: number) => {
    currIndex.current = index
  }

  const onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const value = e.clipboardData.getData('text')
    if (value.trim().split('').length === props.length) {
      setPin(value.split(''))
    }
  }

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const _currIndex = currIndex.current ?? -1

    if (e.code === 'ArrowLeft' && _currIndex > 0) {
      e.preventDefault()
      inputRefs[_currIndex - 1].current?.focus()
      setTimeout(() => {
        inputRefs[_currIndex - 1].current?.select()
      }, 0)
    } else if (e.code === 'ArrowRight' && _currIndex < props.length - 1) {
      e.preventDefault()
      inputRefs[_currIndex + 1].current?.focus()
      setTimeout(() => {
        inputRefs[_currIndex + 1].current?.select()
      }, 0)
    } else if (e.code === 'Backspace' && _currIndex > 0) {
      setTimeout(() => {
        inputRefs[_currIndex - 1].current?.select()
      }, 0)
    }
  }

  useEffect(() => {
    inputRefs[0].current?.focus()
  }, [])

  return (
    <>
      <input type={'hidden'} name={props.name} value={_pin.join('')} />
      <InputField
        // className={twMerge('flex flex-row', props.className)}
        elementContainerClassName={'flex-row gap-[0.4rem]'}
        inputName={props.name}
        label={
          typeof props.label === 'string' ? (
            <InputLabel
              state={props.state === 'disabled' ? 'disabled' : 'default'}
              text={props.label}
              size={'medium'}
            />
          ) : props.label !== undefined ? (
            props.label
          ) : undefined
        }
        hint={
          typeof props.hint === 'string' ? (
            <InputHint
              state={props.state === 'disabled' ? 'disabled' : 'default'}
              label={props.hint}
              style={'hint'}
            />
          ) : props.hint !== undefined ? (
            props.hint
          ) : undefined
        }
        element={inputRefs.map((_ref, i) => (
          <InputElement
            key={`pin_element_${i}`}
            variant={'pin'}
            state={props.state ?? 'default'}
            className="h-[3.2rem]"
            input={
              <InputFrame
                {...props}
                value={_pin[i]}
                text={_pin[i]}
                setRef={_ref}
                size={'large'}
                state={'default'}
                maxLength={1}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onValueChange(e.target.value, i)
                }}
                style={{
                  textAlign: 'center',
                }}
                onPaste={onPaste}
                onKeyDown={onKeyDown}
                onFocus={(e: FocusEvent<HTMLInputElement>) => {
                  onFocus(e, i)
                }}
              />
            }
          />
        ))}
      />
    </>
  )
}
