import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { Avatar } from '@/components/avatar'
import { Button } from '@/components/button'
import { Dropdown } from '@/components/dropdown'
import DefendantActionsController from '@/components/dropdown/controllers/defendant-actions.controller'
import { ROUTES } from '@/constants/routes'
import { useAuth } from '@/contexts/auth'
import { IDefendant } from '@/types/defendants'

import Cell from '../components/cell.table'
import Row from '../components/row.table'
import { BaseTableProps } from '../types'
import { BaseTable } from './base.table'

type SortBy = 'az' | 'latest' | 'value'
type GroupBy = 'all' | 'active' | 'archived'

export interface Props extends BaseTableProps {
  defendants: IDefendant[]
  onEditDefendant: (defendant: IDefendant) => void
  defaultSortBy?: SortBy
}

export const DefendantTable: React.FC<Props> = (props: Props) => {
  const defaultSortBy: SortBy = props.defaultSortBy ?? 'latest'
  // const defaultGroupBy: GroupBy = 'all'
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [_sortBy, setSortBy] = useState<SortBy>(defaultSortBy)
  const [_defendants, setDefendants] = useState<IDefendant[]>(props.defendants)

  // Monitor for incoming changes to the raw cases data
  useEffect(() => {
    setDefendants([...sort(filter(props.defendants))])
  }, [props.defendants])

  // Monitor for sortBy changes + update
  useEffect(() => {
    setDefendants([...sort(_defendants)])
  }, [_sortBy])

  // Update our visibility and sort
  const onSortChanged = (id: SortBy) => setSortBy(id)

  // Sorts the provided data
  const sort = (defendants: IDefendant[]): IDefendant[] => {
    switch (_sortBy) {
      case 'latest':
        return defendants.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      case 'az':
        return defendants.sort((a, b) => a.name.localeCompare(b.name))
      case 'value':
        return defendants.sort(
          (a, b) =>
            b.number_of_digital_assets +
            b.number_of_physical_assets -
            (a.number_of_digital_assets + a.number_of_physical_assets)
        )
      default:
        return defendants
    }
  }

  const filter = (defendants: IDefendant[]): IDefendant[] => {
    return defendants
  }

  const { policies } = useAuth()
  const canManageDefendant = policies?.includes('CUSTODY.MANAGE_DEFENDANT')

  const renderRow = (row: IDefendant, index: number) => {
    const handleNavigateToDetails = () => {
      if (!canManageDefendant) return
      navigate(ROUTES.SUBJECT.DETAIL.replace(':subject_id', row.id), {
        state: {
          row,
        },
      })
    }

    return (
      <Row
        key={`${row.id}_${index}`}
        onClick={handleNavigateToDetails}
        className={twMerge(
          canManageDefendant ? 'cursor-pointer' : 'cursor-default'
        )}
      >
        <Cell.Icon>
          <Avatar type={'monogram'} value={row.name} />
        </Cell.Icon>
        <Cell.Text>{row.name}</Cell.Text>
        <Cell.Number>
          {row.number_of_digital_assets + row.number_of_physical_assets}
        </Cell.Number>
        <Cell.Number>{row.number_of_operations}</Cell.Number>
        <Cell.Button>
          {canManageDefendant ? (
            <Dropdown
              maxWidthPx={270}
              dropdownContent={<DefendantActionsController defendant={row} />}
            >
              {({ setIsOpen }) => (
                <Button
                  layout={'icon'}
                  variant={'shape'}
                  shape={'square'}
                  size={'small'}
                  hierarchy={'tertiary'}
                  icon={{
                    name: 'ellipsis',
                  }}
                  onClick={() => {
                    setIsOpen(true)
                  }}
                />
              )}
            </Dropdown>
          ) : null}
        </Cell.Button>
      </Row>
    )
  }

  return (
    <BaseTable<IDefendant, SortBy, null, GroupBy>
      testId={props.testId}
      showLeftController={props.showLeftController}
      showFilters={props.showFilters}
      showSort={props.showSort}
      sort={{
        options: [
          {
            id: 'az',
            value: 'az',
            label: t('a-z'),
            icon: 'user-circle',
          },
          {
            id: 'latest',
            value: 'latest',
            label: t('latest'),
            icon: 'calendar',
            iconVariant: 'regular',
          },
        ],
        onSortByChanged: onSortChanged,
        defaultSelectedId: defaultSortBy,
      }}
      headers={[
        {
          label: t('name'),
          colSpan: 2,
        },
        {
          label: t('assets'),
          alignment: 'center',
        },
        {
          label: t('cases'),
          alignment: 'center',
        },
        {
          label: '',
        },
      ]}
      data={_defendants}
      onRenderRow={renderRow}
    />
  )
}
