import { ColDef } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { t } from 'i18next'

import { Typography } from '@/components/typography'

import { ColDefOptions } from './_options.coldef'

interface Options<T> extends ColDefOptions<T> {}

export const PhoneColDef = <T,>(options?: Options<T>) => {
  return {
    field: options?.field ?? 'phone',
    headerName: options?.headerName ?? t('mobile'),
    cellRenderer: (cellProps: CustomCellRendererProps<T>) => {
      return (
        <Typography variant={'paragraph-small'} className={'truncate'}>
          {cellProps.value ?? ''}
        </Typography>
      )
    },
    width: 190,
    initialWidth: 190,
  } as ColDef<T>
}
