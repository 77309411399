import { Icon } from '@/components/icon'

interface Props {
  icon: string
  text: string
}

export const Item: React.FC<Props> = ({ icon, text }) => {
  return (
    <div className="flex items-center">
      <Icon name={icon} family="sharp" variant="solid" className="mr-2" />
      {text}
    </div>
  )
}
