import { t } from 'i18next'

import { deleteOrgUser } from '@/api'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { key as QueryOrgUsersKey } from '@/hooks/queries/useOrgUsers'
import { ActionResponse } from '@/types/actions'
import { IUser } from '@/types/user'

export const DeleteUser = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const user = JSON.parse(formData.get('user_obj')?.toString() ?? '{}') as IUser
  const confirmation = formData.get('confirmation_value')?.toString()

  // First lets check the confirmation value
  if (confirmation?.toLowerCase() !== user.name.toLowerCase()) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'confirmation_value',
        error: t('invalid_confirmation_value'),
      },
    ])
  }

  // Make the request to delete the user
  const res = await deleteOrgUser(user.id)

  // Check the response
  if (res.error_code || res.error) {
    throw new Error(ExtractErrorFrom(res))
  }

  // Invalidate cache
  await queryClient.invalidateQueries({
    queryKey: [QueryOrgUsersKey, user.organization_id],
  })

  // Otherwise all good!
  return {
    error: false,
    message: t('user_deleted_successfully'),
    completion: 'COMPLETE',
    data: true,
  }
}
