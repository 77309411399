import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import { refreshSession } from '@/api'
import { Divider } from '@/components/divider'
import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { AuthManager, useAuth } from '@/contexts/auth'
import { useDrawer } from '@/contexts/interface'
import { useWorkspace } from '@/contexts/workspace'
import { roleTitleTranslated } from '@/helpers/roles'
import i18n from '@/locale'

import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<unknown> {}
const ProfileView = (props: Props) => {
  const { workspaces, selectedWorkspace, setWorkspace } = useWorkspace()
  const { setDrawer } = useDrawer()
  const { user, hasPolicy } = useAuth()
  const navigator = useNavigate()

  const changeWorkspace = async (workspaceId: string) => {
    setWorkspace(workspaceId)
    await refreshSession()
    window.location.reload()
  }

  return (
    <DropdownView
      {...props}
      id={'PROFILE'}
      testId={'dropdown_view_profile'}
      items={[
        ...(user?.role === 'super'
          ? []
          : workspaces.length <= 3
            ? workspaces
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((w, i) => (
                  <ListItem
                    key={`item_workspace_${i}`}
                    testId={`workspaces-nav-${i}`}
                    title={w.name}
                    description={w.role ? roleTitleTranslated(w.role) : '-'}
                    leading={
                      <Icon
                        name={'sitemap'}
                        family={'sharp'}
                        variant={'solid'}
                      />
                    }
                    {...(selectedWorkspace &&
                      selectedWorkspace.id === w.id && {
                        trailing: (
                          <Icon
                            name="circle-check"
                            family={'sharp'}
                            variant={'solid'}
                            size={'medium'}
                          />
                        ),
                      })}
                    onClick={() => changeWorkspace(w.id)}
                    className={'pl-2 pr-2'}
                  />
                ))
            : [
                <ListItem
                  key={`item_workspace_nav`}
                  testId="workspaces-nav-1"
                  title={
                    selectedWorkspace ? selectedWorkspace.name : t('workspaces')
                  }
                  description={
                    selectedWorkspace
                      ? (selectedWorkspace.role?.name ?? '')
                      : ''
                  }
                  leading={
                    <Icon name={'sitemap'} family={'sharp'} variant={'solid'} />
                  }
                  trailing={
                    <Icon
                      name="arrow-right"
                      family={'sharp'}
                      variant={'solid'}
                      size={'small'}
                    />
                  }
                  onClick={() => {
                    props.goToView?.('WORKSPACE_SELECT')
                  }}
                  className={'pl-2 pr-2'}
                />,
              ]),
        ...(user?.role !== 'super' && hasPolicy('TEAM.MANAGE_WORKSPACE')
          ? [
              <ListItem
                key={`li_add_workspace`}
                title={t('add_a_workspace')}
                style={'bold'}
                testId="add-workspace"
                leading={
                  <Icon
                    name={'plus'}
                    size={'medium'}
                    family={'sharp'}
                    variant={'solid'}
                  />
                }
                onClick={() => {
                  setDrawer({ id: 'NEW_WORKSPACE' })
                  props.close?.()
                }}
                className={'pl-2 pr-2'}
              />,
            ]
          : []),
        ...(user?.role !== 'super'
          ? [<Divider key={`item_4`} size={'small'} />]
          : []),
        <ListItem
          key={`li_user_settings`}
          title={t('user_settings')}
          style={'bold'}
          testId="user-settings"
          leading={
            <Icon
              name={'user-cog'}
              size={'medium'}
              family={'sharp'}
              variant={'solid'}
            />
          }
          onClick={() => {
            if (window.location.pathname !== '/profile') {
              navigator(`profile?from=${window.location.pathname}`)
            } else {
              const from = new URLSearchParams(window.location.search).get(
                'from'
              )
              navigator(`profile?from=${from}`)
            }
            props.close?.()
          }}
          className={'pl-2 pr-2'}
        />,
        <ListItem
          key={`li_language_selection`}
          title={t(i18n.language)}
          style={'bold'}
          trailing={
            <Icon
              name={'arrow-right'}
              size={'medium'}
              family={'sharp'}
              variant={'solid'}
            />
          }
          leading={
            <Icon
              name={'globe'}
              size={'medium'}
              family={'sharp'}
              variant={'regular'}
            />
          }
          onClick={() => {
            props.goToView?.('LANGUAGE')
          }}
          className={'pl-2 pr-2'}
        />,
        // <ListItem
        //   key={`li_nightmode_selection`}
        //   title={t('dark_mode')}
        //   style={'bold'}
        //   leading={
        //     <Icon
        //       name={'moon-stars'}
        //       size={'medium'}
        //       family={'sharp'}
        //       variant={'solid'}
        //     />
        //   }
        //   trailing={
        //     <Switch
        //       id={'night_mode_switch'}
        //       size={'large'}
        //       enabled={true}
        //       state={'default'}
        //     />
        //   }
        //   onClick={() => {}}
        //   className={'pl-2 pr-2'}
        // />,
        <Divider key={`li_divider_2`} size={'small'} />,
        <ListItem
          key={`li_sign_out`}
          title={t('signout')}
          style={'bold'}
          leading={
            <Icon
              name={'arrow-right-from-bracket'}
              size={'medium'}
              family={'sharp'}
              variant={'solid'}
            />
          }
          onClick={() => {
            AuthManager.logout()
            props.close?.()
          }}
          className={'pl-2 pr-2'}
        />,
      ]}
    />
  )
}
export default ProfileView
