import { t } from 'i18next'

import { updateDefendant } from '@/api/broker/v2/defendants'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { IsNot } from '@/helpers/test'
import { key as QueryDefendantsKey } from '@/hooks/queries/useDefendants'
import { ActionResponse } from '@/types/actions'
import { IDefendant } from '@/types/defendants'

export const EditDefendant = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const name = formData.get('name')?.toString()?.trim() ?? ''
  const email = formData.get('email')?.toString()
  const phone = formData.get('phone')?.toString()
  const originalDefendant = formData.get('original_defendant')?.toString()

  if (IsNot(originalDefendant, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_subject_object'),
      },
    ])
  } else if (IsNot(name, REGEX.IS_DEFENDANT_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'name',
        error: t('subject_name_must_be_provided'),
      },
    ])
  } else if (email && IsNot(email, REGEX.IS_EMAIL)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'email',
        error: t('email_address_is_invalid'),
      },
    ])
  } else if (phone && IsNot(phone, REGEX.IS_PHONE_NUMBER)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'phone',
        error: t('phone_number_is_invalid'),
      },
    ])
  }

  // Attempt to parse the original defenant object
  const parsedDefendant = JSON.parse(originalDefendant) as IDefendant

  // Make the call to update the subject
  const res = await updateDefendant({
    ...parsedDefendant,
    name,
    contact_details: {
      email,
      phone,
    },
  })

  // Check if the call failed
  if (res.error_code || res.error) {
    throw new Error(ExtractErrorFrom(res))
  }

  // Invalidate the cache
  await queryClient.invalidateQueries({ queryKey: [QueryDefendantsKey] })

  return {
    error: false,
    message: `${t('subject')} '${name}' ${t('updated_successfully')}`,
    completion: 'COMPLETE',
    data: true,
  }
}
