import { useMutation } from '@tanstack/react-query'
import { Dispatch, SetStateAction } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { twMerge } from 'tailwind-merge'

import { Button } from '@/components/button'
import { ButtonBasic } from '@/components/button/variants/basic.button'
import Chip from '@/components/chip'
import {
  DisposeAssetModalFormState,
  ReasonForDisposal,
} from '@/components/dropdown/controllers/views/DisposeAssetModal.types'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { FileUploadState } from '@/components/input/hooks/useFileUpload'
import { InputDropdown } from '@/components/input/variants/dropdown.input'
import { ListItem } from '@/components/list-item'
import { Modal } from '@/components/modal'
import { getFileIcon } from '@/helpers/getFileIcon'
import { IAsset } from '@/types/asset'

type ApiResponse = {
  error: boolean
  message: null
  data: {
    travel_rule_required: boolean
    detected_vasp: string
    vasps: (null | string)[]
  }
}

export const useDisposeAssetValidateMutation = ({
  onSuccess,
}: {
  onSuccess?: (data: ApiResponse) => void
}) => {
  const mutation = useMutation({
    mutationFn: async () => {
      // TODO: uncomment when API is ready
      // const client = await getClient((await getConfig()).BROKER_API_URL_V2)

      // return client
      //   .post(`/tx/validate`, {
      //     beneficiary: {
      //       jurisdiction: 'string',
      //     },
      //     asset_id: 'string',
      //     organization_id: 'string',
      //     destination_wallet: {
      //       address: 'string',
      //     },
      //     amount: 'string',
      //     file: {
      //       key: 'string',
      //       name: 'string',
      //       type: 'string',
      //     },
      //   })
      //   .then((response) => {
      //     return response.data
      //   })

      await new Promise((resolve) => setTimeout(resolve, 1000))

      const mockedResult = {
        error: false,
        message: null,
        data: {
          travel_rule_required: true,
          detected_vasp: 'coinbase',
          vasps: [null, 'coinbase'],
        },
      }

      onSuccess?.(mockedResult)

      return mockedResult
    },
  })

  return {
    validate: mutation.mutateAsync,
    isValidating: mutation.isPending,
    validationResult: mutation.data,
  }
}

type Props = {
  asset: IAsset
  formState: DisposeAssetModalFormState
  setFormState: Dispatch<SetStateAction<DisposeAssetModalFormState>>
  courtOrderFile: FileUploadState
  validateMutation: ReturnType<typeof useDisposeAssetValidateMutation>
  onClose: () => void
}

export const DisposeAssetValidateModal = ({
  asset,
  formState,
  setFormState,
  courtOrderFile,
  validateMutation,
  onClose,
}: Props) => {
  const intl = useIntl()

  const getReasonForDisposalMessage = (
    reasonForDisposal: ReasonForDisposal
  ) => {
    switch (reasonForDisposal) {
      case ReasonForDisposal.RETURN_TO_DEFENDANT:
        return intl.formatMessage({
          defaultMessage: 'Return to defendant',
          id: '+J1Zy3',
        })
      case ReasonForDisposal.LIQUIDATION:
        return intl.formatMessage({
          defaultMessage: 'Liquidation',
          id: 'Oj4si1',
        })
      case ReasonForDisposal.TRANSFER_TO_CUSTODIAN:
        return intl.formatMessage({
          defaultMessage: 'Transfer to custodian',
          id: 'AQD/OF',
        })
      case ReasonForDisposal.SWAP_OF_ASSET:
        return intl.formatMessage({
          defaultMessage: 'Swap of asset',
          id: 'MT+k+X',
        })
      default:
        return ''
    }
  }

  if (!('ticker' in asset)) {
    return null
  }

  return (
    <Modal portal visible className="max-w-[500px]">
      <div className="p-6 flex flex-col gap-4">
        <div className="flex gap-3 items-center">
          <Icon className="w-10 h-10 max-w-10 max-h-10" crypto={asset.ticker} />

          <div className="flex flex-col grow">
            <div className="font-extrabold text-lg">
              <FormattedMessage
                defaultMessage="{amount} {assetTicker}"
                values={{
                  amount: asset.amount,
                  assetTicker: asset.ticker,
                }}
                id="QTt2Hu"
              />
            </div>
            <div className="text-sm">{asset.network}</div>
          </div>

          <Chip.Status size="medium" status={asset.statuses?.[0]} />
        </div>

        <div className="bg-[#EAF2FB] p-6 rounded-xl flex flex-col px-6 pb-6 pt-4 gap-4">
          <div className="flex flex-col gap-1 relative">
            <Input.Currency
              id="amount"
              name="amount"
              label={intl.formatMessage({
                defaultMessage: 'Amount',
                id: '/0TOL5',
              })}
              placeholder={intl.formatMessage({
                defaultMessage: 'Enter amount to dispose',
                id: 'x2pjpt',
              })}
              decimalsLimit={60}
              currencyCode={asset.ticker}
              value={formState.amount}
              onValueChange={(newValue) =>
                setFormState((prev) => ({
                  ...prev,
                  amount: newValue ?? '',
                }))
              }
            />

            <div className="flex justify-between">
              <ButtonBasic
                className="py-1 min-h-0 h-auto px-2 underline"
                hierarchy="tertiary"
                label={intl.formatMessage({
                  defaultMessage: 'Max',
                  id: 'kkjl2v',
                })}
                onClick={() =>
                  setFormState((prev) => ({
                    ...prev,
                    amount: String(asset.amount),
                  }))
                }
              />

              {Number(formState.amount) > (asset.amount ?? 0) ? (
                <div className="text-red-500 text-xs mr-1 font-bold">
                  <FormattedMessage
                    defaultMessage="Amount cannot be more than balance"
                    id="o++ggK"
                  />
                </div>
              ) : null}
            </div>
          </div>

          <Input.IDNumber id={asset.platform_id ?? ''} />

          <Input.Text
            label={intl.formatMessage({
              defaultMessage: 'Send to',
              id: 'ngyB8X',
            })}
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter destination address',
              id: 'stXfkU',
            })}
            value={formState.destinationAddress}
            onChange={(event) =>
              setFormState((prev) => ({
                ...prev,
                destinationAddress: event.target.value ?? '',
              }))
            }
          />
        </div>

        <div
          className={twMerge(
            'bg-gray-100 p-3 pl-6 rounded-xl text-sm flex gap-4 items-center relative',
            courtOrderFile.hasFileDraggingOver ? 'outline-dashed outline-2' : ''
          )}
        >
          <input
            {...courtOrderFile.fileInputProps}
            className="absolute top-0 bottom-0 left-0 right-0 opacity-0 cursor-pointer"
            type="file"
          />

          {courtOrderFile.file ? (
            <button
              className={twMerge(
                'flex gap-2 items-center grow',
                courtOrderFile.hasFileDraggingOver ? '' : 'relative'
              )}
              onClick={(event) => {
                event?.stopPropagation()

                if (courtOrderFile.file) {
                  const anchor = window.document.createElement('a')
                  anchor.download = courtOrderFile.file.file.name
                  anchor.href = courtOrderFile.file.temporaryUrl
                  anchor.click()
                }
              }}
            >
              <Icon
                name={getFileIcon(courtOrderFile.file.file)}
                variant={'solid'}
                size={'md'}
              />

              <div className="grow flex underline">
                {courtOrderFile.file.file.name}
              </div>

              <Button
                layout="icon"
                variant="shape"
                shape="square"
                size="small"
                hierarchy="tertiary"
                icon={{
                  name: 'close',
                }}
                onClick={() => courtOrderFile.clear()}
              />
            </button>
          ) : (
            <>
              <div className="grow font-medium">
                <FormattedMessage
                  defaultMessage="Attach the court order for this disposal"
                  id="e+LyDQ"
                />
              </div>

              <ButtonBasic
                hierarchy="secondary"
                label={intl.formatMessage({
                  defaultMessage: 'Upload file',
                  id: '6oOCCL',
                })}
                size="small"
                className="pointer-events-none select-none"
              />
            </>
          )}
        </div>

        <div className="bg-gray-100 px-[24px] pb-[24px] pt-[16px] rounded-xl">
          <InputDropdown
            name="reason_for_disposal"
            label={intl.formatMessage({
              defaultMessage: 'Reason for disposal',
              id: 'UQWfYL',
            })}
            text={getReasonForDisposalMessage(formState.reasonForDisposal)}
            items={Object.values(ReasonForDisposal).map((item) => (
              <ListItem
                key={item}
                title={getReasonForDisposalMessage(item)}
                onClick={() => {
                  setFormState((prev) => ({
                    ...prev,
                    reasonForDisposal: item,
                  }))
                }}
                className={'pl-2 pr-2'}
              />
            ))}
          />
        </div>

        <div className="flex gap-2">
          <ButtonBasic
            onClick={() => validateMutation.validate()}
            label={intl.formatMessage({
              defaultMessage: 'Next',
              id: '9+Ddtu',
            })}
          />
          <ButtonBasic
            onClick={onClose}
            hierarchy="tertiary"
            label={intl.formatMessage({
              defaultMessage: 'Cancel',
              id: '47FYwb',
            })}
          />
        </div>
      </div>
    </Modal>
  )
}
