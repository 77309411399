import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { NoData } from '@/components/no-data'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import { IUser } from '@/types/user'

export const SuperAdminTeamManagement: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { data } = useLoaderData() as { data: IUser[] }
  const [users, setUsers] = useState<IUser[]>([])

  useEffect(() => {
    const _super_users = data?.filter((_u) => _u?.role === 'super') ?? []
    setUsers(_super_users)
  }, [data])

  return (
    <>
      <div
        className={twMerge(
          'flex flex-col min-h-[calc(100vh-80px)]',
          'tablet:pt-0'
        )}
      >
        <div className={twMerge('flex flex-col p-8 pt-4', 'tablet:gap-8')}>
          <Paragraph
            title={t('team_management')}
            spacerOverrides={{
              description: [],
            }}
          />
        </div>

        <div className={'flex flex-1 flex-col px-8'}>
          {users && users.length ? (
            <Table.AgGrid.Wrapper>
              <Table.Team data={users} />
            </Table.AgGrid.Wrapper>
          ) : (
            <NoData description={t('no_data')} />
          )}
        </div>
      </div>
    </>
  )
}
