import { AgGridReact } from 'ag-grid-react'
import { ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  children: ReactElement<AgGridReact>
  testId?: string
  className?: string
}

export const Wrapper = (props: Props) => {
  return (
    <div
      className={twMerge(
        'flex-1 w-full h-full min-h-[300px]',
        'relative overflow-hidden',
        props.className
      )}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  )
}
export default Wrapper
