import { Icon } from '@/components/icon'

interface Props {
  items: { icon: string; title: string; value: React.ReactNode }[]
}

export const Line: React.FC<Props> = ({ items }) => {
  return (
    <div className="flex">
      <div className="flex flex-col items-center w-6 mr-3">
        {items.map((item, index) => (
          <>
            <div key={index} className="h-6 w-6 bg-[#212427]">
              <Icon
                name={item.icon}
                family="sharp"
                variant="solid"
                size="xsmall"
                className="text-white"
              />
            </div>
            {index < items.length - 1 && (
              <div className="w-0.5 h-10 bg-[#212427]"></div>
            )}
          </>
        ))}
      </div>
      <div className="flex flex-col">
        {items.map((item, index) => (
          <div
            key={index}
            className={index < items.length - 1 ? 'h-16' : undefined}
          >
            <div className="font-extrabold">{item.title}</div>
            <div>{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
