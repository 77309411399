import { useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { NewAssetDocumentDrawer } from '@/components/drawer/variants/new-asset-document'

import { Paragraph } from '../paragraph'
import DrawerContent from './components/content'
// import { Divider } from '../divider'
import { Header } from './components/header'
import { CaseAddExistingAssetsDrawer } from './variants/case-add-assets'
import { EditAssetDrawer } from './variants/edit-asset'
import { EditCaseDrawer } from './variants/edit-case'
import { EditDefendantDrawer } from './variants/edit-defendant'
import { EditOrganizationDrawer } from './variants/edit-organization'
import { EditTeamSuperUserDrawer } from './variants/edit-team-super-user'
import { EditTeamUserDrawer } from './variants/edit-team-user'
import { ImportAssetsDrawer } from './variants/import-assets'
import { NewAssetDrawer } from './variants/new-asset'
import { NewCaseDrawer } from './variants/new-case'
import { NewCustodianDrawer } from './variants/new-custodian'
import { NewDefendantDrawer } from './variants/new-defendant'
import { NewEscrowDrawer } from './variants/new-escrow'
import { NewOperationDocumentDrawer } from './variants/new-operation-document'
import { NewOrganizationDrawer } from './variants/new-organization'
import { NewSupportedAssetDrawer } from './variants/new-supported-asset'
import { NewTeamUserDrawer } from './variants/new-team-user'
import { NewWorkspaceDrawer } from './variants/new-workspace'
import { ReportingDrawer } from './variants/reporting'

interface Props {
  title?: string
  subTitle?: string
  description?: string
  children: JSX.Element | null
  visible: boolean
  onClose: () => void
  className?: string
  drawerClassName?: string
  closeOnBackgroundClick?: boolean
  minHeight?: number
}
export const Drawer = (props: Props): JSX.Element => {
  // const { windowSize } = useWindowSize()
  const [_showing, setShowing] = useState<boolean>(false)
  const [_visible, setVisible] = useState<boolean>(false)
  const [_hiding, setHiding] = useState<boolean>(false)
  const [_childHeight, setChildHeight] = useState<number>(0)

  useEffect(() => {
    const asyncFunc = async () => {
      if (props.visible) {
        if (!_showing && !_visible) {
          await onOpen()
        }
      } else {
        if (_showing || _visible) {
          await onClose()
        }
      }
    }
    asyncFunc()
  }, [props.visible])

  const onOpen = async () => {
    return new Promise<void>((resolve) => {
      setHiding(false)
      setShowing(true)
      setTimeout(() => {
        setVisible(true)
        resolve()
      })
    })
  }

  const onClose = async () => {
    return new Promise<void>((resolve) => {
      setHiding(true)
      setTimeout(() => {
        props.onClose()
        setShowing(false)
        setVisible(false)
        setHiding(false)
        resolve()
      }, 300)
    })
  }

  const onOutsideClick = () => {
    if (props.closeOnBackgroundClick === true) {
      onClose()
    }
  }

  const contentRef = useCallback((node: HTMLDivElement) => {
    if (!node) return
    const resizeObserver = new ResizeObserver(() => {
      // We need +73 to account for the header height
      setChildHeight(Math.max(props.minHeight ?? 0, node.clientHeight + 73))
    })
    resizeObserver.observe(node)
  }, [])

  return (
    <div
      className={twMerge(
        'fixed hidden top-0 left-[150vw] w-0 h-0',
        'pointer-events-auto overscroll-contain',
        'items-center justify-center',
        _showing && 'flex left-0 w-full h-full z-[60] opacity-0',
        _visible && 'opacity-100'
      )}
    >
      <div
        onClick={onOutsideClick}
        className={twMerge(
          'fixed flex inset-0',
          'overflow-hidden overscroll-contain',
          'cursor-default',
          'bg-[#21242759]',
          _visible && 'w-full h-full',
          _visible && 'animate-fade-in',
          _hiding && 'animate-fade-out'
        )}
      />
      <div
        className={twMerge(
          'flex',
          'pointer-events-auto bg-white w-[calc(100vw-32px)] min-h-[30vh] max-h-[calc(100dvh-32px)]',
          'z-[61] overflow-hidden overscroll-contain',
          'transition-[height] duration-[300ms] ease-in-out',
          'rounded-[6px]',
          'shadow-[0_0.5rem_1rem_0_rgba(40,38,35,0.15)]',
          'tablet:w-[calc(100vw-64px)] tablet:max-h-[calc(100dvh-64px)] tablet:max-w-[840px]',
          _visible && 'animate-fade-in tablet:animate-fade-in-up',
          _hiding && 'animate-fade-out tablet:animate-fade-out-down',
          props.drawerClassName
        )}
        style={{
          height: `${_childHeight}px`,
        }}
      >
        <div
          className={twMerge(
            'flex flex-1 flex-col',
            'h-auto w-full overflow-hidden'
          )}
          data-testid="dialog-container"
        >
          <Header title={props.title ?? ''} onClose={onClose} />
          <div
            className={twMerge(
              'flex flex-1 items-start justify-center',
              'overflow-y-scroll overscroll-contain'
            )}
          >
            <div
              ref={contentRef}
              className={twMerge(
                'flex flex-col tablet:items-center',
                'w-full  max-w-[50rem]',
                'p-[24px] pt-[24px] gap-6',
                'tablet:p-[48px]',
                props.className
              )}
            >
              {(props.title || props.subTitle || props.description) && (
                <div className={'flex self-stretch items-start'}>
                  <Paragraph
                    subTitle={props.subTitle}
                    description={props.description}
                    spacerOverrides={{
                      description: [],
                    }}
                  />
                </div>
              )}
              <DrawerContent>{props.children}</DrawerContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default {
  NewWorkspace: NewWorkspaceDrawer,
  NewCase: NewCaseDrawer,
  NewOperationDocument: NewOperationDocumentDrawer,
  NewAssetDocument: NewAssetDocumentDrawer,
  EditCase: EditCaseDrawer,
  NewAsset: NewAssetDrawer,
  EditAsset: EditAssetDrawer,
  Reporting: ReportingDrawer,
  NewDefendant: NewDefendantDrawer,
  EditDefendant: EditDefendantDrawer,
  NewOrganization: NewOrganizationDrawer,
  EditTeamUser: EditTeamUserDrawer,
  NewTeamUser: NewTeamUserDrawer,
  EditOrganization: EditOrganizationDrawer,
  NewCustodian: NewCustodianDrawer,
  NewSupportedAsset: NewSupportedAssetDrawer,
  EditTeamSuperUser: EditTeamSuperUserDrawer,
  CaseAddExistingAssets: CaseAddExistingAssetsDrawer,
  NewEscrow: NewEscrowDrawer,
  ImportAssets: ImportAssetsDrawer,
}
