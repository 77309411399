import './agreement.css'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '@/components/button'
import { Checkbox } from '@/components/checkbox'
import Logo from '@/components/logo'
import { AuthManager, useAuth } from '@/contexts/auth'

import { useUpdateAgreed } from './use-agreed'

export const VERSION = 2

export const Agreement: React.FC = () => {
  const [agree, setAgree] = useState(false)
  const updateAgreed = useUpdateAgreed()
  const { user } = useAuth()
  const navigate = useNavigate()

  if (!user) {
    navigate('/', { replace: true })
  }

  return (
    <div className="h-dvh flex">
      <div className='bg-[url("/auth-image.jpg")] flex-grow bg-cover p-12 hidden md:block'>
        <Logo.WordMark
          style="COLOR"
          color="WHITE"
          heightRem={3.5}
          className="justify-start"
        />
      </div>
      <div className="md:w-[600px] flex flex-col min-w-0">
        <div className="overflow-auto p-6 md:p-8">
          <Button.Back className="mb-6" onClick={AuthManager.logout} />
          <Logo.WordMark
            style="COLOR"
            color="BLACK"
            heightRem={3.5}
            className="justify-start mb-6 md:hidden"
          />
          <div className="agreement">
            <p>Effective Date: 25 November 2024</p>
            <p>Last Updated: 1 December 2024</p>
            <h1>User Access</h1>
            <p>
              Please read this carefully before accessing or using an Asset
              Reality&apos;s product, including the Arc platform, mobile
              application, and any related services (&quot;Product&quot;). By
              clicking &quot;I Agree&quot; or using the Product, you acknowledge
              you have read, understood, and agree to be bound by the terms
              stated herein.
            </p>
            <p>
              This document should be read in conjunction with our Master
              Services Agreement (“MSA”). A copy of the MSA can be found via the
              Asset Reality website within the legal section. If you do not
              agree to the terms of the MSA, you must not access or use the
              Product.
            </p>
            <p>
              In accordance with the MSA, Asset Reality grants you a limited,
              non-exclusive, non-transferable, and revocable license to access
              and use the Product for your personal or business use, subject to
              the terms stated in the MSA. Please see below for a summary of the
              key terms of the MSA.
            </p>
            <h2>User Obligations</h2>
            <p>You agree to:</p>
            <ul>
              <li>
                Use the Product only for lawful purposes and in accordance with
                the MSA.
              </li>
              <li>
                Not use the Product in any manner that could disable,
                overburden, or impair the Product or interfere with any other
                party&apos;s use of the Product.
              </li>
            </ul>
            <h2>Prohibited Uses</h2>
            <p>You agree not to:</p>
            <ul>
              <li>
                Modify, distribute, or reproduce the Product without explicit
                written permission from Asset Reality.
              </li>
              <li>
                Decompile, reverse engineer, or attempt to derive the source
                code of the Product.
              </li>
              <li>
                Access all or any part of the Product to build a product or
                service which competes with the Product.
              </li>
              <li>
                Use the Product for any illegal, fraudulent, or harmful
                activity.
              </li>
            </ul>
            <h2>Privacy</h2>
            <p>
              By using the Product, you acknowledge and agree to Asset
              Reality&apos;s Privacy Policy, which describes how your personal
              data is collected, processed, used, and shared.
            </p>
            <p>
              Please see the privacy policy here:&nbsp;
              <a
                href="https://www.assetreality.com/policies/privacy-policy"
                target="blank"
              >
                Link to Privacy Policy
              </a>
            </p>
            <h2>Intellectual Property</h2>
            <ul>
              <li>
                You acknowledge all intellectual property rights in the Product
                anywhere in the world belong to Asset Reality or our licensors;
                that rights in the Product are licensed (not sold) to you; and,
                that you have no rights in, or to, the Product other than the
                right to use the Product in accordance with the terms of the
                MSA.
              </li>
              <li>
                You acknowledge you have no right to have access to any Product
                in source code form.
              </li>
              <li>
                You agree not to use any intellectual property without Asset
                Reality&apos;s prior written consent.
              </li>
            </ul>
            <h2>Termination</h2>
            <p>
              Asset Reality reserves the right to terminate or suspend your
              access to the Product at any time for a violation of the MSA.
            </p>
            <h2>Limitation of Liability</h2>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY LAW, ASSET REALITY SHALL NOT BE
              LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL
              DAMAGES ARISING FROM YOUR USE OR INABILITY TO USE THE PRODUCT.
            </p>
            <h2>Acknowledgement and Acceptance</h2>
            <p>
              By clicking &quot;I Agree,&quot; you acknowledge you have read,
              understood, and agree to the terms of the MSA.
            </p>
          </div>
        </div>
        <div className="border-t border-[#2124271A] p-6 md:p-8 flex justify-center">
          <div className="w-[375px] flex flex-col">
            <div className="bg-gray-100 rounded-md px-4 py-2 mb-6 flex items-center justify-between">
              I Agree
              <Checkbox
                id="agree"
                testId="agree"
                onCheckChanged={async (_, state) => {
                  setAgree(state === 'checked' ? true : false)
                  return state
                }}
              />
            </div>
            <Button.Basic
              label="Continue"
              className="self-stretch"
              state={agree ? 'default' : 'disabled'}
              trailingIcon={{
                name: 'arrow-right',
              }}
              testId="continue"
              onClick={() => updateAgreed(VERSION)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
