import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'
import { NoData } from '@/components/no-data'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import { useDrawer } from '@/contexts/interface'
import { IOrganisation } from '@/types/organisation'

import { LoaderData } from './loader'

export const Organization: React.FC = (): JSX.Element => {
  const loader = useLoaderData() as LoaderData
  const { revalidate } = useRevalidator()
  const { t } = useTranslation()
  const { setDrawer } = useDrawer()
  const [_organizations, setOrganizations] = useState<
    IOrganisation[] | undefined
  >(loader?.organizations)

  useEffect(() => {
    if (loader) {
      setOrganizations(loader.organizations)
    }
  }, [loader])

  const onUpdated = () => {
    revalidate()
    document.querySelector('main')?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <div
        className={twMerge(
          'flex flex-col min-h-[calc(100vh-80px)]',
          'tablet:pt-0'
        )}
      >
        <div className={twMerge('flex flex-col p-8 pt-4', 'tablet:gap-8')}>
          <Paragraph
            title={t('organization')}
            description={t('organization_description')}
            spacerOverrides={{ description: [] }}
          />
          <div className={'flex flex-row gap-2'}>
            <Button.Basic
              icon={{
                name: 'plus',
              }}
              label={t('create_new_organization')}
              onClick={() => {
                setDrawer({ id: 'NEW_ORGANIZATION' })
              }}
              className="hidden tablet:flex"
            />
          </div>
        </div>

        <div className={'flex flex-1 flex-col px-8'}>
          {_organizations && _organizations.length > 0 ? (
            <Table.AgGrid.Wrapper testId={'case-table-wrapper'}>
              <Table.Organization
                data={_organizations}
                onEditOrganization={(_o: IOrganisation) => {
                  setDrawer({
                    id: 'EDIT_ORGANIZATION',
                    org: _o,
                    onOrganizationUpdated: onUpdated,
                  })
                }}
              />
            </Table.AgGrid.Wrapper>
          ) : (
            <NoData description={t('no_organization_description')} />
          )}
        </div>

        <Button.Fab
          label={t('create_new_organization')}
          icon={{
            name: 'plus',
          }}
          onClick={() => {
            setDrawer({ id: 'NEW_ORGANIZATION' })
          }}
        />
      </div>
    </>
  )
}
