import { NavLink } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

// import Button from '@/components/button'
import { Icon } from '@/components/icon'
// import { ListItem } from '@/components/list-item'
import { Typography } from '@/components/typography'

interface BaseProps {
  testId: string
  label: string
  icon: string
  overrideLabelVisibility?: boolean
  darkMode?: boolean
}
interface LinkProps extends BaseProps {
  to: string
  onClick?: () => void
}
interface FunctionProps extends BaseProps {
  onClick: () => void
}

type Props = LinkProps | FunctionProps
export const NavItem = (props: Props) => {
  const content = (isActive?: boolean) => {
    return (
      <span
        className={twMerge(
          'flex flex-row items-center justify-center gap-2',
          'min-w-[2.75rem] max-w-[2.75rem] min-h-[2.75rem] max-h-[2.75rem]',
          '2xl:justify-start',
          '2xl:max-w-none 2xl:self-stretch',
          'transition-[background-color] duration-300',
          'rounded-[6px] hover:bg-gray-100',
          props.overrideLabelVisibility === true &&
            'justify-start max-w-none self-stretch',
          props.darkMode && 'hover:bg-[#323538]'
        )}
      >
        <span
          aria-hidden="true"
          className={twMerge(
            'flex relative items-center justify-center',
            'min-w-[2.75rem] min-h-[2.75rem] max-w-[2.75rem] max-h-[2.75rem]'
          )}
        >
          <Icon
            size={'medium'}
            family={'sharp'}
            name={props.icon}
            variant={isActive ? 'solid' : 'regular'}
            className={
              props.darkMode
                ? isActive
                  ? 'text-white'
                  : 'text-[#BCBDBE]'
                : isActive
                  ? 'text-black'
                  : 'text-[#585B5F]'
            }
          />
        </span>
        <Typography
          variant={isActive ? 'label-medium' : 'paragraph-medium'}
          className={twMerge(
            'whitespace-nowrap overflow-hidden text-ellipsis',
            'hidden 2xl:inline-block',
            props.overrideLabelVisibility === true && 'inline-block',
            props.darkMode
              ? isActive
                ? 'text-white'
                : 'text-[#BCBDBE]'
              : isActive
                ? 'text-black'
                : 'text-[#585B5F]'
          )}
        >
          {props.label}
        </Typography>
      </span>
    )
  }

  if ('to' in props) {
    return (
      <NavLink
        to={props.to}
        onClick={props.onClick}
        aria-label={props.label}
        target={props.to.includes('http') ? '_blank' : '_self'}
        data-testid={props.testId}
      >
        {({ isActive }) => content(isActive)}
      </NavLink>
    )
  } else {
    return (
      <div
        className={'cursor-pointer'}
        data-testid={props.testId}
        onClick={props.onClick}
      >
        {content(false)}
      </div>
    )
  }
}
