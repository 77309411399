import { ColDef } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { useMemo } from 'react'

import { Group } from '@/components/group'

import { AddressRenderer } from './AddressRenderer'
import { CreatedRenderer } from './CreatedRenderer'
import { StatusRenderer } from './StatusRenderer'
import { TravelRuleRenderer } from './TravelRuleRenderer'
import { UserRequestRenderer } from './UserRequestRenderer'

export const History: React.FC = () => {
  const defaultColDef = useMemo<ColDef>(
    () => ({
      suppressHeaderMenuButton: true,
    }),
    []
  )

  const colDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'identifier',
        width: 164,
      },
      {
        field: 'userRequest',
        cellRenderer: UserRequestRenderer,
        width: 136,
      },
      {
        field: 'created',
        cellRenderer: CreatedRenderer,
        width: 128,
      },
      {
        field: 'from',
        width: 100,
        cellRenderer: AddressRenderer,
      },
      {
        field: 'to',
        width: 100,
        cellRenderer: AddressRenderer,
      },
      {
        field: 'aml',
        headerName: 'AML',
        width: 96,
      },
      {
        field: 'travelRule',
        cellRenderer: TravelRuleRenderer,
        width: 96,
      },
      {
        field: 'balance',
        width: 88,
      },
      {
        field: 'status',
        cellRenderer: StatusRenderer,
        width: 104,
      },
    ],
    []
  )

  const rowData = useMemo(
    () => [
      {
        identifier: 'Bitcoin',
        userRequest: 'Howard Torp',
        organization: 'Police Crime Unit',
        created: new Date(),
        from: '0xac58A21bB412EAd8BE2eE783859710909574d993',
        to: '0x738d3e91EAA23F17742b3Cad044c0d9A90E81226',
        aml: '10 / 10',
        travelRule: 'pass',
        balance: 12.764,
        status: 'accepted',
      },
    ],
    []
  )

  return (
    <Group label="History">
      <div className="ag-theme-quartz h-[352px] ar-theme">
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          rowData={rowData}
          suppressContextMenu
          rowHeight={72}
          suppressMovableColumns
        />
      </div>
    </Group>
  )
}
