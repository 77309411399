import { LoaderFunction } from 'react-router-dom'

import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'
import { IHealth } from '@/types/health'

export const loader: LoaderFunction = async () => {
  try {
    // this used to call getHealthChain() but the service was removed, this code probably can be too.
    const healthData: IHealth[] = []
    return healthData
  } catch (error) {
    const message = ExtractErrorFrom(error)
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
  return null
}
