import { ColDef } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { t } from 'i18next'

import Chip from '@/components/chip'
import { Typography } from '@/components/typography'
import { AssetCategory, IAsset } from '@/types/asset'

import AssetIcon from '../rows/assetIcon'

export const AssetTypeColDef = () => {
  // Gets the first line of text to display
  const getTopText = (asset?: IAsset): string => {
    let line: string | undefined

    // Return back something different depending on the category
    if (!asset) {
      line = undefined
    } else if (asset.category === AssetCategory.FIAT_CASH) {
      line = t(`${asset.currency?.toLowerCase()}`, undefined)
    } else if (
      asset.category === AssetCategory.VEHICLE ||
      asset.category === AssetCategory.PERSONAL_PROPERTY ||
      asset.category === AssetCategory.REAL_ESTATE
    ) {
      line = t(`${asset.type?.toLowerCase().replace(/\//g, '_')}`, undefined)
    } else {
      line = asset.ticker
    }

    // Return the line or a backup incase the value doesnt exist
    return line ?? '-'
  }

  const getBottomText = (asset?: IAsset) => {
    let line: string | undefined

    // Return back something different depending on the category
    if (!asset) {
      line = undefined
    } else if (
      asset.category === AssetCategory.FIAT_CASH ||
      asset.category === AssetCategory.VEHICLE ||
      asset.category === AssetCategory.PERSONAL_PROPERTY ||
      asset.category === AssetCategory.REAL_ESTATE
    ) {
      line = t(`category_${asset.category.toLowerCase()}`)
    } else {
      line = asset.network
    }

    // Return the line or a backup incase the value doesnt exist
    return line ?? '-'
  }

  return {
    headerName: t('asset_type'),
    valueGetter: (params) => {
      return params.data ? getTopText(params.data) : ''
    },
    filterValueGetter: (params) => {
      return params.data ? getTopText(params.data) : ''
    },
    comparator: (a, b) => {
      return a.localeCompare(b)
    },
    cellRenderer: (cellProps: CustomCellRendererProps<IAsset>) => {
      return (
        <div className={'flex flex-row gap-[16px]'}>
          <div
            className={
              'min-w-[50px] max-w-[50px] flex items-center justify-center'
            }
          >
            {cellProps.data && <AssetIcon asset={cellProps.data} />}
          </div>
          <div className={'flex flex-col gap-[4px] justify-center items-start'}>
            <Typography variant={'label-small'}>
              {getTopText(cellProps.data)}
            </Typography>
            {cellProps.data && (
              <Chip.Basic
                colour={'grey'}
                type={'outline'}
                label={getBottomText(cellProps.data)}
                className={'justify-start'}
              />
            )}
          </div>
        </div>
      )
    },
    flex: 1,
    width: 320,
    initialWidth: 320,
    minWidth: 250,
  } as ColDef<IAsset>
}
