import { t } from 'i18next'

import { createOrgUser } from '@/api'
import { updateCustodians } from '@/api/org'
import { REGEX } from '@/constants/regex'
import { FormErrorsManager } from '@/contexts/formErrors'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'
import { ICustodian } from '@/types/custodian'
import { IUser } from '@/types/user'

export const CreateOrganization = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const organization_name = formData.get('org_name')?.toString()
  const country = formData.get('country')?.toString()
  const currency = formData.get('currency')?.toString()
  const owner_name = formData.get('_name')?.toString()
  const owner_email = formData.get('email')?.toString()
  const status_2fa = formData.get('two_factor_enabled') as unknown as string
  const existingAddress = formData.get('existing_address')?.toString()
  const customTracker = formData.get('custom_tracker')?.toString()
  const escrowEnabled = formData.get('escrow_enabled')?.toString()
  const updatedCustodians = JSON.parse(
    (formData.get('custodian_obj') as string) || '[]'
  ) as ICustodian[]

  const organization = {
    name: organization_name,
    address: '',
    country,
    preferred_currency: currency,
    language: 'en',
    two_factor_disabled: status_2fa === 'true' ? false : true,
    existing_wallet_digital_assets_enabled: existingAddress === 'true',
    self_managed_digital_assets_enabled: customTracker === 'true',
    escrow_enabled: escrowEnabled === 'true',
  }
  const user = {
    name: owner_name,
    email: owner_email,
    phone: '',
    language: 'en',
    organization,
  }

  if (IsNot(owner_name, REGEX.IS_HUMAN_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '_name',
        error: t('invalid_name_provided'),
      },
    ])
  }

  try {
    const res = await createOrgUser(user)
    if (res.data) {
      await Promise.all(
        updatedCustodians?.map((_c: ICustodian) => {
          if (_c.status === 'ACTIVE') {
            return updateCustodians({
              organization_id: (res.data as IUser).organization_id,
              custodian_id: _c.id,
            })
          }
          return _c
        })
      )
    }
  } catch (error) {
    const message = ExtractErrorFrom(error)
    return FormErrorsManager.addErrors([
      {
        fieldName: message?.includes('email')
          ? 'email'
          : message?.includes('name')
            ? 'org_name'
            : message?.includes('country')
              ? 'country'
              : '',
        error: message ?? t('something_went_wrong'),
      },
    ])
  }

  return {
    error: false,
    message: t('new_organization_success'),
    completion: 'COMPLETE',
    data: null,
  }
}
