import { t } from 'i18next'
import { ReactNode } from 'react'

import { Divider } from '@/components/divider'
import { Icon } from '@/components/icon'
import { Typography } from '@/components/typography'

export type Props =
  | {
      iconName?: string
      description: string
    }
  | {
      iconName?: string
      children: ReactNode
    }

export const NoData = (props: Props) => {
  return (
    <div
      className={'flex flex-1 flex-col px-8 pt-8'}
      data-testid={'no_data_found'}
    >
      <Divider />
      <div className={'flex flex-1 items-center justify-center'}>
        <div
          className={
            'p-9 flex flex-col items-center justify-center max-w-[23.5rem]'
          }
        >
          <Icon
            variant="solid"
            name={props.iconName ?? 'folder-open'}
            family="sharp"
            size={'xxlarge'}
          />
          <Typography
            variant="paragraph-medium"
            className={'pt-4 pb-6 text-center leading-[1.5rem]'}
          >
            {'description' in props ? t(props.description) : props.children}
          </Typography>
        </div>
      </div>
    </div>
  )
}
