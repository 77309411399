import { FormattedMessage, useIntl } from 'react-intl'

import { ButtonBasic } from '@/components/button/variants/basic.button'
import { TextareaInput } from '@/components/input/variants/textarea'

import { Address } from './Address'
import { Header } from './Header'
import { Item } from './Item'
import { Line } from './Line'
import { RequestedBy } from './RequestedBy'
import { Section } from './Section'
import { Title } from './Title'

interface Props {
  onApprove: () => void
  onDecline: () => void
  onClose: () => void
}

export const ApproveScreen: React.FC<Props> = ({
  onApprove,
  onDecline,
  onClose,
}) => {
  const intl = useIntl()

  const handleApprove = () => {
    onApprove()
  }

  const handleDecline = () => {
    onDecline()
  }

  return (
    <div>
      <Header
        ticker="BTC"
        value={53.11}
        reason={intl.formatMessage({
          defaultMessage: 'Return to defendant',
          id: '+J1Zy3',
        })}
      />
      <RequestedBy
        name="Everett Wiegand"
        date="14/06/2023"
        time="12:45:16 PM"
      />
      <div className="mb-6">
        <Title>
          <FormattedMessage defaultMessage="Disposal" id="bH+F35" />
        </Title>
        <div className="mb-2">
          <Section>
            <Line
              items={[
                { icon: 'coins', title: 'Amount', value: '53.11 BTC' },
                {
                  icon: 'wallet',
                  title: intl.formatMessage({
                    defaultMessage: 'From',
                    id: 'dM+p3/',
                  }),
                  value: (
                    <Address value="0xd36aab03676f519ed49c1863cc6003e9bbb1e1f4" />
                  ),
                },
                {
                  icon: 'badge-check',
                  title: intl.formatMessage({
                    defaultMessage: 'To',
                    id: '9j3hXO',
                  }),
                  value: (
                    <Address value="0xdafea492d9c6733ae3d56b7ed1adb60692c98bc5" />
                  ),
                },
              ]}
            />
          </Section>
        </div>
        <Section>
          <Item icon="file-pdf" text="Court-Order-14-May-2024.pdf" />
        </Section>
      </div>
      <div className="mb-6">
        <Title>
          <FormattedMessage defaultMessage="Message" id="T7Ry38" />
        </Title>
        <TextareaInput
          placeholder={intl.formatMessage({
            defaultMessage:
              'Add an optional message if you choose to approve or decline the disposal',
            id: '5Cjp3f',
          })}
          className="max-w-none"
        />
      </div>
      <div className="flex justify-between">
        <div className="flex">
          <ButtonBasic
            hierarchy="primary"
            icon={{ name: 'circle-check' }}
            label={intl.formatMessage({
              defaultMessage: 'Approve',
              id: 'WCaf5C',
            })}
            className="mr-6"
            onClick={handleApprove}
          />
          <ButtonBasic
            hierarchy="destructive"
            icon={{ name: 'circle-xmark' }}
            label={intl.formatMessage({
              defaultMessage: 'Decline',
              id: 'pvtgR2',
            })}
            onClick={handleDecline}
          />
        </div>
        <ButtonBasic hierarchy="tertiary" label="Cancel" onClick={onClose} />
      </div>
    </div>
  )
}
