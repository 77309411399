import { type ChangeEvent, useEffect, useState } from 'react'

export const fileExtensions: Record<
  'video' | 'image' | 'worddoc' | 'pdf' | 'spreadsheet',
  `.${string}`[]
> = {
  video: ['.webm', '.mp4'],
  image: ['.jpg', '.jpeg', '.png', '.webp', '.gif'],
  pdf: ['.pdf'],
  worddoc: ['.doc', '.docx', '.txt'],
  spreadsheet: ['.xls', '.xlsx', '.ods', '.csv'],
}

type TFile = {
  file: File
  temporaryUrl: string
  type: keyof typeof fileExtensions
}

export const useFileUpload = ({
  allowedFileTypes,
  maxSizeInMB,
}: {
  allowedFileTypes: (keyof typeof fileExtensions)[]
  maxSizeInMB?: number
}) => {
  const [file, setFile] = useState<TFile | null>(null)
  const [hasFileDraggingOver, setHasFileDraggingOver] = useState(false)

  // clean up the object URL when the component unmounts or when fileUrl changes
  useEffect(() => {
    return () => {
      if (file?.temporaryUrl) {
        URL.revokeObjectURL(file.temporaryUrl)
      }
    }
  }, [file?.temporaryUrl])

  return {
    file,

    hasFileDraggingOver,

    clear: () => setFile(null),

    fileInputProps: {
      onDragEnter: () => setHasFileDraggingOver(true),
      onDragLeave: () => setHasFileDraggingOver(false),
      onDrop: () => setHasFileDraggingOver(false),
      accept: allowedFileTypes
        .flatMap((type) => fileExtensions[type])
        .join(','),
      key: file?.temporaryUrl,
      onChange: async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        if (files && files.length > 0) {
          const file = files[0] // assuming single file upload from the server side files

          // extract file extension
          const fileExtension: `.${string}` = `.${file.name.split('.').pop()?.toLowerCase()}`
          const allowedExtensions = allowedFileTypes.flatMap(
            (type) => fileExtensions[type]
          )
          const type = Object.entries(fileExtensions).find(([, extesions]) =>
            extesions.includes(fileExtension)
          )?.[0]

          // validate file type
          if (!type) {
            // TODO: improve error reporting to show some pretty error on the UI
            alert(
              `Invalid file type. Allowed types: ${allowedExtensions.join(', ')}`
            )
            return
          }

          // validate file size
          const maxSizeInBytes = maxSizeInMB
            ? maxSizeInMB * 1024 * 1024
            : undefined
          if (maxSizeInBytes && file.size > maxSizeInBytes) {
            // TODO: improve error reporting to show some pretty error on the UI
            alert(`File is too large. Maximum size is ${maxSizeInMB}MB`)
            return
          }

          // create a URL for the uploaded file
          const temporaryUrl = URL.createObjectURL(file)

          // save to state
          setFile({
            file,
            temporaryUrl,
            type: type as keyof typeof fileExtensions,
          })
        }
      },
    },
  }
}

export type FileUploadState = ReturnType<typeof useFileUpload>
