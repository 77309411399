export enum ReasonForDisposal {
  RETURN_TO_DEFENDANT = 'return_to_defendant',
  LIQUIDATION = 'liquidation',
  TRANSFER_TO_CUSTODIAN = 'transfer_to_custodian',
  SWAP_OF_ASSET = 'swap_of_asset',
}

export type DisposeAssetModalFormState = {
  amount: string
  destinationAddress: string
  beneficiaryName: string
  firstName: string
  lastName: string
  reasonForDisposal: ReasonForDisposal
  selectedVasp: null | string
  accountType: null | 'organization' | 'person'
  declarationOfOwnership: boolean
}
