import { LoaderFunction } from 'react-router-dom'

import { getAllOrgs } from '@/api/org/v1/organization'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'
import { IOrganisation } from '@/types/organisation'

export type LoaderData = {
  organizations: IOrganisation[]
} | null
export const loader: LoaderFunction = async () => {
  try {
    // Get any data we might require
    const res = await Promise.allSettled([getAllOrgs()])
    if (res?.[0]?.status === 'rejected') {
      return ErrorHandler(404, ExtractErrorFrom(res))
    }

    // Keep things neat by naming our res values
    const _organisations = res?.[0]?.value?.data || []
    // Push the data to the page
    return {
      organizations: _organisations,
    } satisfies LoaderData
  } catch (error: unknown) {
    console.log(error)
    const message = ExtractErrorFrom(error)
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
