import { t } from 'i18next'
import Cookies from 'js-cookie'

import { getIamUser, getUser, verify2FA } from '@/api/auth'
import { TOKEN_EXPIRATION_TIME } from '@/constants/auth'
import { REGEX } from '@/constants/regex'
import { AuthManager } from '@/contexts/auth'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { Is } from '@/helpers/test'
import i18n from '@/locale'
import { ActionResponse } from '@/types/actions'
import { BaseResponse } from '@/types/api'
import { IUser } from '@/types/user'

export const VerifyPasscode = async (
  formData: FormData
): Promise<ActionResponse<IUser>> => {
  const token = formData.get('token') as string
  const passcode = formData.get('passcode') as string

  // Validate the fields
  const isTokenValid = Is(token, REGEX.IS_ANY)
  const isPasscodeValid = Is(passcode, REGEX.IS_ANY)

  // Throw any errors for the invalid fields
  if (!isTokenValid) {
    // This isn't something the user can help with - throw an error
    throw new Error(t('something_went_wrong'))
  }
  if (!isPasscodeValid) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'passcode',
        error: t('invalid_passcode_provided'),
      },
    ])
  }

  // Verify the token/passcode combi
  let res: BaseResponse<IUser>
  try {
    res = await verify2FA(token, passcode)
  } catch (e) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'passcode',
        error: ExtractErrorFrom(e),
      },
    ])
  }

  // Check the response
  if (res.error_code || res.error || !res.data) {
    throw new Error(ExtractErrorFrom(res))
  }

  // Check we have a session token
  if (!res.data.session_token) {
    throw new Error('missing session token')
  }

  // Otherwise we should be good - set the session token
  Cookies.set('token', res.data.session_token, {
    expires: TOKEN_EXPIRATION_TIME,
  })

  // Make a call to grab the user details
  const userRes = await getUser()

  if (userRes.error_code || userRes.error || !userRes.data) {
    throw new Error(ExtractErrorFrom(userRes))
  }

  // Otherwise we should be good - start by creating the user
  // object
  const authUser = userRes.data

  // We need to get the users policies using the iam endpoint
  const iam = await getIamUser(authUser.id)

  // Check we got the policies ok
  if (iam.error_code || iam.error || !iam.data) {
    throw new Error(ExtractErrorFrom(iam))
  }

  // Set the user + policies in the state manager
  await Promise.all([
    AuthManager.setUser(authUser),
    AuthManager.setPolicies(iam.data.session.policies ?? []),
  ])

  // Set the users language
  i18n.changeLanguage(authUser.language?.toLowerCase() ?? 'en')

  return {
    error: false,
    message: t('passcode_verified_successfully'),
    completion: 'COMPLETE',
    data: authUser,
  }
}
