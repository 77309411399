import { t } from 'i18next'

import { updateOperationFiles } from '@/api/broker/v2/operations'
import { uploadFiles } from '@/api/files'
import { REGEX } from '@/constants/regex'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'
import { IFile } from '@/types/file'
import { IOperation } from '@/types/operations'

export const AddOperationDocument = async (
  formData: FormData
): Promise<ActionResponse<IFile[]>> => {
  const operation = formData.get('existing_operation')?.toString()
  let newFiles: IFile[] = []

  // Validate some bits...
  if (IsNot(operation, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_operation_provided'),
      },
    ])
  }

  // Attempt to parse the original operation object
  const parsedOperation = JSON.parse(operation) as IOperation

  // Grab the existing files
  const oldFiles = parsedOperation.files ?? []

  // Grab the new files from the form and ignore any
  // files that are not valid
  const formFiles = [...formData.getAll('new_files')]?.filter((file) => {
    return file && file instanceof File && file.size !== 0
  }) as File[]

  // Check if we have any files to upload
  if (formFiles.length > 0) {
    // Upload the files and get back a list of keys
    newFiles = await uploadFiles(formFiles)
  } else {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'case_new_files',
        error: t('no_new_files_attached'),
      },
    ])
  }

  // Build a collection of all files
  const allFiles = [...oldFiles, ...newFiles]

  // Create the new operation
  const newOperationRes = await updateOperationFiles(
    parsedOperation.id,
    allFiles
  )

  // Check if the call failed
  if (newOperationRes.error_code || newOperationRes.error) {
    throw new Error(ExtractErrorFrom(newOperationRes))
  }

  return {
    error: false,
    message:
      formFiles.length > 1
        ? t('new_documents_added_successfully')
        : t('new_document_added_successfully'),
    completion: 'COMPLETE',
    data: allFiles,
  }
}
