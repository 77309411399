import { CustomCellRendererProps } from 'ag-grid-react'

type Status = 'pass' | 'fail'

const statusMap = {
  pass: {
    text: 'Pass',
    color: '#53AB64',
  },
  fail: {
    text: 'Fail',
    color: '#E01F3D',
  },
}

export const TravelRuleRenderer: React.FC<CustomCellRendererProps> = ({
  value,
}: {
  value: Status
}) => {
  const status = statusMap[value]

  return (
    <div
      className={`text-white h-8 px-3 rounded-full leading-none flex items-center bg-[${status.color}]`}
    >
      {status.text}
    </div>
  )
}
