import { ColDef, RowClickedEvent } from 'ag-grid-community'
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import { Avatar } from '@/components/avatar'
import AgGridCell from '@/components/table/components/cell.aggrid'
import { Typography } from '@/components/typography'
import { useAuth } from '@/contexts/auth'
import { useDrawer } from '@/contexts/interface'
import { roleTitleTranslated } from '@/helpers/roles'
import { useGroups } from '@/hooks/queries/useGroups'
import { IWorkspaceMember } from '@/types/workspace'

import styles from '../aggrid.module.css'
import { CreatedAtColDef } from '../colDefs/createdAt.coldef'
import { EmailColDef } from '../colDefs/email.coldef'
import { StatusColDef } from '../colDefs/status.coldef'

export interface Props {
  data: IWorkspaceMember[]
}

export const WorkspaceMembersTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { hasPolicy } = useAuth()
  const { groups } = useGroups()
  const { setDrawer } = useDrawer()
  const ownersUserIds =
    groups
      ?.find((g) => g.name.toLowerCase() === 'owner')
      ?.users?.map((u) => u.id) ?? []

  const getUserRoleDescription = (member?: IWorkspaceMember): string => {
    if (!member) {
      return '-'
    } else if (ownersUserIds.includes(member.user_id)) {
      return t('role_title_owner')
    }
    return roleTitleTranslated(member.role)
  }

  const colDefs = useMemo<ColDef<IWorkspaceMember>[]>(
    () => [
      {
        field: 'user.name',
        headerName: t('user'),
        cellRenderer: (props: CustomCellRendererProps<IWorkspaceMember>) => {
          return (
            <div className={'flex flex-row gap-[16px] items-center w-full'}>
              <div>
                <Avatar
                  type={'monogram'}
                  size={'medium'}
                  shape={'round'}
                  value={props.value}
                />
              </div>
              <Typography variant={'label-small'} className={'truncate'}>
                {props.value}
              </Typography>
            </div>
          )
        },
        flex: 1,
        width: 300,
        minWidth: 200,
      },
      {
        field: 'role.name',
        headerName: t('role'),
        cellRenderer: (cellProps: CustomCellRendererProps<IWorkspaceMember>) =>
          AgGridCell({
            ...cellProps,
            value: getUserRoleDescription(cellProps.data),
            type: 'text',
          }),
        width: 190,
      },
      EmailColDef({
        field: 'user.email',
      }),
      StatusColDef({
        context: 'WORKSPACE_MEMBER',
      }),
      CreatedAtColDef({ field: 'user.created_at' }),
    ],
    [t, ownersUserIds]
  )

  return (
    <div
      data-testid={'table_workspace_members'}
      className={twMerge(
        `ag-theme-quartz ar-theme ${styles.grid} ${styles.no_cell_selection} h-full`,
        !hasPolicy('TEAM.MANAGE_ORG') ? `${styles.no_row_selection}` : ''
      )}
    >
      <AgGridReact
        suppressContextMenu
        autoSizeStrategy={{
          type: 'fitGridWidth',
        }}
        gridOptions={{
          rowHeight: 72,
        }}
        rowData={props.data}
        onRowClicked={(e: RowClickedEvent<IWorkspaceMember>) => {
          if (
            !e.event?.defaultPrevented &&
            hasPolicy('TEAM.MANAGE_ORG') &&
            e.data?.user
          ) {
            setDrawer({
              id: 'EDIT_TEAM_USER',
              userId: e.data.user.id,
            })
          }
        }}
        defaultColDef={{
          suppressHeaderMenuButton: true,
          suppressHeaderContextMenu: true,
        }}
        columnDefs={colDefs}
      />
    </div>
  )
}
