import { CustomCellRendererProps } from 'ag-grid-react'

import { Icon } from '@/components/icon'

type Status = 'accepted' | 'rejected'

const statusMap = {
  accepted: { icon: 'check', color: '#53AB64' },
  rejected: { icon: 'xmark', color: '#E01F3D' },
}

export const StatusRenderer: React.FC<CustomCellRendererProps> = ({
  value,
}: {
  value: Status
}) => {
  const status = statusMap[value]

  return (
    <div
      className={`h-8 w-8 bg-[${status.color}] rounded-full flex justify-center items-center`}
    >
      <Icon
        name={status.icon}
        variant="solid"
        size="small"
        className="text-white"
      />
    </div>
  )
}
