import { ColDef } from 'ag-grid-community'
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { downloadFile, getFileMetadata } from '@/api/files'
import { Avatar } from '@/components/avatar'
import Button from '@/components/button'
import Dropdown from '@/components/dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import AgGridCell from '@/components/table/components/cell.aggrid'
import { Typography } from '@/components/typography'
import { useAuth } from '@/contexts/auth'
import { useDropdown } from '@/contexts/interface'
import { formatBytes } from '@/helpers/formatValue'
import { getFileIcon } from '@/helpers/getFileIcon'
import { useOrgUsers } from '@/hooks/queries/useOrgUsers'
import { useWorkspaceMembers } from '@/hooks/queries/useWorkspaceMembers'
import { IEscrowFile } from '@/types/escrows'
import { IFile } from '@/types/file'

import styles from '../aggrid.module.css'
import { CreatedAtColDef } from '../colDefs/createdAt.coldef'

type AcceptedFileTypes = IFile | IEscrowFile
export interface Props<T extends AcceptedFileTypes> {
  data: T[]
  organizations?: { id: string; name: string }[]
  testId?: string
  onDeleteFile?: (file: T) => Promise<void>
}

export const FilesTable = <T extends AcceptedFileTypes>(props: Props<T>) => {
  const { t } = useTranslation()
  const { members } = useWorkspaceMembers()
  const { users: orgUsers } = useOrgUsers()
  const { hasPolicy } = useAuth()
  const [_downloadingFileKeys, setDownloadingFileIds] = useState<string[]>([])
  const [_deletingFileKeys, setDeletingFileIds] = useState<string[]>([])
  const [_data, setData] = useState<T[]>(props.data)
  const [_canDeleteFiles] = useState<boolean>(
    hasPolicy('CUSTODY.DELETE_DOCUMENT') && !!props.onDeleteFile
  )
  const { setDropdown } = useDropdown()

  useEffect(() => {
    const func = async () => {
      if (props.data.length > 0) {
        const _newData = [...props.data]

        // Find all files that we need to get metadata for - we'll exclude any
        // files that already has a metadata object
        const fileKeys = props.data.filter((f) => !f.metadata).map((f) => f.key)

        // Check we have any files to get metadata for
        if (fileKeys.length > 0) {
          // Get the metadata for those keys
          const metadataRes = await getFileMetadata(fileKeys)

          // Create a lookup
          const metadataLookup = Object.fromEntries(
            metadataRes.map((mdata) => [mdata.metadata.key, mdata])
          )

          // Run through the files and assign the metadata where we have it
          for (const file of _newData) {
            if (metadataLookup[file.key]) {
              file.metadata = metadataLookup[file.key]
            }
          }
        }

        // Set the new data
        setData(_newData)
      } else {
        setData([])
      }
    }
    func()
  }, [props.data])

  const onDownloadFile = async (file?: T) => {
    if (file && !_downloadingFileKeys.includes(file.key)) {
      try {
        // Set the downloading flag
        setDownloadingFileIds([..._downloadingFileKeys, file.key])

        // Fetch the file metadata if not available
        if (!file.metadata || !file.metadata.url) {
          const metadata = await getFileMetadata(file.key)
          file.metadata = metadata[0]
        }

        // Download the file
        setTimeout(async () => {
          await downloadFile(file)
        }, 200)
      } catch (e) {
        console.error(e)
      } finally {
        setDownloadingFileIds(
          _downloadingFileKeys.filter((i) => i !== file.key)
        )
      }
    }
  }

  const showDeletionConfirmation = (file: T, targetId: string) => {
    setDropdown({
      target: targetId,
      maxWidthPx: 300,
      controller: (
        <Dropdown.Controllers.BasicList
          closeOnItemClick={true}
          items={[
            <Typography
              variant={'label-medium'}
              className={'text-gray-600 p-4'}
              key={'header_delete_file_confirmation'}
            >
              {t('confirm_file_deletion')}
            </Typography>,
            <ListItem
              key={'btn_add_asset_new'}
              testId={'btn_delete_file_confirmaton'}
              title={file.name}
              leading={
                <Icon name={'trash'} family={'sharp'} variant={'solid'} />
              }
              onClick={() => {
                onDeleteFile(file)
              }}
              className={'pl-2 pr-2'}
            />,
          ]}
        />
      ),
    })
  }

  const onDeleteFile = async (file: T) => {
    if (props.onDeleteFile && _deletingFileKeys.length === 0) {
      if (!_deletingFileKeys.includes(file?.key as string)) {
        try {
          setDeletingFileIds([..._deletingFileKeys, file?.key as string])
          await props.onDeleteFile(file)
          setData(_data.filter((f) => f.key !== file.key))
        } catch (error) {
          console.error('Error deleting file:', error)
        } finally {
          setDeletingFileIds(_deletingFileKeys.filter((i) => i !== file.key))
        }
      }
    }
  }

  const colDefs = useMemo<ColDef<AcceptedFileTypes>[]>(
    () => [
      {
        field: 'name',
        headerName: t('filename'),
        cellRenderer: (props: CustomCellRendererProps<T>) => {
          return (
            <div className={'flex flex-row gap-[16px] items-center w-full'}>
              <div>
                <Avatar
                  type={'icon'}
                  shape={'square'}
                  size={'medium'}
                  name={props.data ? getFileIcon(props.data) : ''}
                  family={'sharp'}
                  iconVariant={'solid'}
                  iconClassName="text-black"
                />
              </div>
              <Typography variant={'label-small'} className={'truncate'}>
                {props.value}
              </Typography>
            </div>
          )
        },
        cellRendererParams: {
          type: 'header',
        },
        flex: 1,
        minWidth: 200,
      },
      CreatedAtColDef(),
      {
        field: 'metadata.metadata.size',
        headerName: t('size'),
        cellRenderer: (cellProps: CustomCellRendererProps<T>) => {
          return AgGridCell({
            ...cellProps,
            value: formatBytes(cellProps.data?.metadata?.metadata.size),
            type: 'text',
          })
        },
        width: 100,
        initialWidth: 100,
      },
      {
        field: 'created_by',
        headerName: t('uploaded_by'),
        cellRenderer: (cellProps: CustomCellRendererProps<T>) => {
          let isOrg: boolean = false

          // First attempt to find the created by user id in the workspace
          // members...
          let createdBy = members?.find(
            (u) =>
              u.user_id ===
              (typeof cellProps.data?.created_by === 'string'
                ? cellProps.data?.created_by
                : cellProps.data?.created_by?.user_id)
          )?.user?.name

          // Secondly lets take a gander at the org users...
          if (!createdBy && orgUsers) {
            createdBy = orgUsers.find(
              (u) =>
                u.user_id ===
                (typeof cellProps.data?.created_by === 'string'
                  ? cellProps.data?.created_by
                  : cellProps.data?.created_by?.user_id)
            )?.name
          }

          // If we cant find the user in the current workspace or current org
          // its possible they belong to a different organisation - so lets
          // check...
          // (Note that we need a collection or orgs to be provided)
          if (!createdBy && props.organizations) {
            // Attempt to find the org based on the id
            const org = props.organizations.find(
              (o) =>
                o.id ===
                (typeof cellProps.data?.created_by === 'string'
                  ? cellProps.data?.created_by
                  : cellProps.data?.created_by?.organization_id)
            )

            // If we've found an org lets grab the name
            if (org) {
              createdBy = org.name
              isOrg = true
            }
          }

          // Display the createdBy name if we have it - otherwise we'll display a "-"
          if (createdBy) {
            return (
              <div className={'flex flex-row gap-[16px] items-center w-full'}>
                <div>
                  {isOrg ? (
                    <Avatar
                      type={'icon'}
                      shape={'square'}
                      size={'small'}
                      name={'buildings'}
                      iconVariant={'solid'}
                      family={'sharp'}
                      iconClassName={'text-black'}
                    />
                  ) : (
                    <Avatar
                      type={'monogram'}
                      shape={'round'}
                      size={'small'}
                      value={createdBy}
                    />
                  )}
                </div>
                <Typography
                  variant={'paragraph-small'}
                  className={'w-full truncate'}
                >
                  {createdBy}
                </Typography>
              </div>
            )
          } else {
            return AgGridCell({
              ...cellProps,
              value: '-',
              type: 'text',
            })
          }
        },
        flex: 1,
        width: 200,
        initialWidth: 200,
      },
      {
        cellRenderer: (cellProps: CustomCellRendererProps<T>) => {
          if (cellProps.data === undefined) {
            return AgGridCell({ ...cellProps, value: '', type: 'text' })
          } else {
            return (
              <div className={'w-full flex justify-center'}>
                <Button.Shape
                  layout={'icon'}
                  hierarchy={'tertiary'}
                  shape={'square'}
                  size={'small'}
                  icon={{ name: 'arrow-down-to-bracket' }}
                  onClick={(e) => {
                    e.preventDefault()
                    onDownloadFile(cellProps.data)
                  }}
                />
              </div>
            )
          }
        },
        resizable: false,
        width: 70,
        minWidth: 70,
      },
      ...(_canDeleteFiles
        ? [
            {
              cellRenderer: (cellProps: CustomCellRendererProps<T>) => {
                if (cellProps.data === undefined) {
                  return AgGridCell({ ...cellProps, value: '', type: 'text' })
                } else {
                  return (
                    <div className={'w-full flex justify-center'}>
                      <Button.Shape
                        id={`btn_delete_file_${cellProps.data.key}`}
                        state={
                          _deletingFileKeys.includes(cellProps.data.key)
                            ? 'loading'
                            : _deletingFileKeys.length > 0
                              ? 'disabled'
                              : 'default'
                        }
                        layout={'icon'}
                        hierarchy={'tertiary'}
                        shape={'square'}
                        size={'small'}
                        icon={{ name: 'trash' }}
                        onClick={(e) => {
                          e.preventDefault()
                          if (cellProps.data) {
                            showDeletionConfirmation(
                              cellProps.data,
                              `btn_delete_file_${cellProps.data.key}`
                            )
                          }
                        }}
                      />
                    </div>
                  )
                }
              },
              resizable: false,
              width: 70,
              minWidth: 70,
            },
          ]
        : []),
    ],
    [t, props.onDeleteFile]
  )

  return (
    <div
      className={`ag-theme-quartz ar-theme ${styles.grid} ${styles.no_cell_selection} ${styles.no_row_selection}`}
      style={{ position: 'relative' }}
      data-testid={props.testId}
    >
      <AgGridReact
        suppressContextMenu
        autoSizeStrategy={{
          type: 'fitGridWidth',
        }}
        gridOptions={{
          domLayout: 'autoHeight',
          rowHeight: 72,
        }}
        rowData={_data}
        defaultColDef={{
          suppressHeaderMenuButton: true,
          suppressHeaderContextMenu: true,
        }}
        columnDefs={colDefs}
      />
    </div>
  )
}
