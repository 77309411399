import {
  ColDef,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { GridState, SideBarDef, StateUpdatedEvent } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'
import { ChangeEvent, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { findLastStatus } from '@/actions/create-asset/assetForm'
import Button from '@/components/button'
import Card from '@/components/card'
import Dropdown from '@/components/dropdown'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Paragraph } from '@/components/paragraph'
import { ROUTES } from '@/constants/routes'
import { useDropdown } from '@/contexts/interface'
import { getAssetTypeDescriptionForColumn } from '@/helpers/assetTypeDescription'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { formatAssetPrice, formatValue } from '@/helpers/formatValue'
import { useDigitalAssets } from '@/hooks/queries/useDigitalAssets'
import { useOperations } from '@/hooks/queries/useOperations'
import { useOrg } from '@/hooks/queries/useOrg'
import { usePhysicalAssets } from '@/hooks/queries/usePhysicalAssets'
import { AssetCategory, DigitalWalletSource, IAsset } from '@/types/asset'
import { IPrice } from '@/types/common'

import { AssetTypeRenderer } from './asset-type-renderer'
import { IdentifierRenderer } from './identifier-renderer'
import { useInitialState, useUpdateInitialState } from './use-initial-state'

export const Reports: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [quickFilterText, setQuickFilterText] = useState<string>()
  const [totalValue, setTotalValue] = useState<string>()
  const [totalAssets, setTotalAssets] = useState<number>()
  const [tangible, setTangible] = useState<number>()
  const [digital, setDigital] = useState<number>()

  const ref = useRef<AgGridReact>(null)

  const { assets: digitalAssets } = useDigitalAssets('ANY_OPERATIONS')
  const { assets: physicalAssets } = usePhysicalAssets('ANY_OPERATIONS')
  const { operations } = useOperations()
  const { org } = useOrg()
  const initialState = useInitialState()
  const updateInitialState = useUpdateInitialState()

  const { setDropdown } = useDropdown()

  const defaultColDef = useMemo<ColDef>(
    () => ({
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
    }),
    []
  )

  const colDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'assetType',
        headerName: t('asset_type'),
        filter: 'agSetColumnFilter',
        cellRenderer: AssetTypeRenderer,
      },
      {
        field: 'identifier',
        headerName: t('identifier'),
        cellRenderer: IdentifierRenderer,
        cellRendererParams: {
          onClick: (id: string) =>
            navigate(ROUTES.ASSETS.DETAIL.replace(':asset_id', id)),
        },
      },
      { field: 'case', headerName: t('case'), filter: 'agSetColumnFilter' },
      {
        field: 'defendant',
        headerName: t('subject'),
        filter: 'agSetColumnFilter',
      },
      { field: 'status', headerName: t('status'), filter: 'agSetColumnFilter' },
      {
        field: 'value',
        headerName: t('value'),
        type: 'rightAligned',
        valueFormatter: (params: ValueFormatterParams<IPrice>) =>
          formatAssetPrice(params.value),
        filter: 'agNumberColumnFilter',
        filterParams: {
          maxNumConditions: 1,
          filterOptions: ['equals', 'greaterThan', 'lessThan', 'inRange'],
        },
        filterValueGetter: (params: ValueGetterParams) => {
          return params.data?.value?.value ?? 0
        },
        comparator: (valueA, valueB) => {
          const valA = valueA.is_untradeable === true ? -1 : valueA.value
          const valB = valueB.is_untradeable === true ? -1 : valueB.value
          return valA - valB
        },
      },
      {
        field: 'seizureDate',
        headerName: t('seizure_date'),
        valueFormatter: (params) =>
          params.value ? moment(params.value).format('DD MMM yyyy') : '',
        filter: 'agDateColumnFilter',
        filterParams: {
          maxNumConditions: 1,
          filterOptions: [
            'equals',
            'lessThan',
            'greaterThan',
            'inRange',
            'blank',
          ],
        },
      },
      {
        field: 'daysSinceSeizureDate',
        headerName: t('days_since_seizure_date'),
        filter: 'agNumberColumnFilter',
        filterParams: {
          maxNumConditions: 1,
          filterOptions: [
            'equals',
            'greaterThan',
            'lessThan',
            'inRange',
            'blank',
          ],
        },
      },
      { field: 'manufacturer', filter: 'agSetColumnFilter', hide: true },
      { field: 'model', filter: 'agSetColumnFilter', hide: true },
      { field: 'vin', headerName: 'VIN', hide: true },
      { field: 'serialNumber', hide: true },
      { field: 'plotNumber', hide: true },
      { field: 'address', hide: true },
      { field: 'country', filter: 'agSetColumnFilter', hide: true },
      { field: 'description', hide: true },
      { field: 'walletAddress', hide: true, filter: 'agTextColumnFilter' },
      { field: 'contractAddress', hide: true, filter: 'agTextColumnFilter' },
      { field: 'digitalAddressType', hide: true, filter: 'agSetColumnFilter' },
      { field: 'network', filter: 'agSetColumnFilter', hide: true },
      {
        field: 'amount',
        valueFormatter: (params) =>
          params.value !== undefined && params.data.assetCurrency
            ? formatValue(params.value, params.data.assetCurrency)
            : params.value !== undefined && params.data.ticker
              ? `${params.value} ${params.data.ticker.toUpperCase().replace('$', '')}`
              : '',
        filter: 'agNumberColumnFilter',
        filterParams: {
          maxNumConditions: 1,
          filterOptions: [
            'equals',
            'greaterThan',
            'lessThan',
            'inRange',
            'blank',
          ],
        },
        hide: true,
      },
      { field: 'containsFiles', filter: 'agSetColumnFilter', hide: true },
      {
        field: 'daysSinceLastStatusChange',
        filter: 'agNumberColumnFilter',
        filterParams: {
          maxNumConditions: 1,
          filterOptions: ['equals', 'greaterThan', 'lessThan', 'inRange'],
        },
        hide: true,
      },
      { field: 'arReference', headerName: 'AR Reference', hide: true },
    ],
    [t]
  )

  const getDigitalAddressTypeText = (asset: IAsset): string | undefined => {
    if (asset.category === AssetCategory.DIGITAL) {
      if ('wallet' in asset) {
        if (asset.wallet?.source === DigitalWalletSource.GENERATED) {
          return t('report_generated_address')
        } else if (asset.wallet?.source === DigitalWalletSource.EXISTING) {
          return t('report_existing_address')
        }
      } else if ('is_self_managed' in asset && asset.is_self_managed) {
        return t('report_custom_tracker')
      }
    }
    return undefined
  }

  const rowData = useMemo(
    () =>
      digitalAssets !== undefined &&
      physicalAssets !== undefined &&
      operations !== undefined
        ? [...(digitalAssets ?? []), ...(physicalAssets ?? [])].map((asset) => {
            const seizureDate = asset.statuses
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )
              .find((status) => status.name === 'SEIZED')?.happened_at
            const lastStatus = findLastStatus(asset.statuses)
            return {
              assetType: getAssetTypeDescriptionForColumn(asset),
              asset,
              identifier: asset.external_ref,
              id: asset.id,
              case: operations?.find(
                (operation) => operation.id === asset.operation_id
              )?.name,
              defendant: asset.defendant?.name,
              category: asset.category,
              status: lastStatus ? t(lastStatus.name.toLowerCase()) : undefined,
              value: asset.price,
              currency: asset.price?.currency,
              seizureDate: seizureDate
                ? moment(seizureDate).startOf('day').toDate()
                : undefined,
              daysSinceSeizureDate: seizureDate
                ? moment().diff(moment(seizureDate), 'days')
                : undefined,
              manufacturer: 'make' in asset ? asset.make : undefined,
              model: 'model' in asset ? asset.model : undefined,
              vin: 'vin' in asset ? asset.vin : undefined,
              serialNumber:
                'serial_number' in asset ? asset.serial_number : undefined,
              plotNumber:
                'plot_number' in asset ? asset.plot_number : undefined,
              address:
                'location' in asset ? asset.location?.address : undefined,
              country:
                'location' in asset ? asset.location?.country : undefined,
              description: asset.notes?.[0].content,
              walletAddress:
                'wallet' in asset ? asset.wallet?.address : undefined,
              contractAddress:
                'contract_address' in asset
                  ? asset.contract_address
                  : undefined,
              digitalAddressType: getDigitalAddressTypeText(asset),
              network:
                'network' in asset
                  ? capitalizeFirstLetter(asset.network)
                  : undefined,
              amount: 'amount' in asset ? asset.amount : undefined,
              assetCurrency: 'currency' in asset ? asset.currency : undefined,
              ticker: 'ticker' in asset ? asset.ticker : undefined,
              containsFiles: !!asset.files,
              daysSinceLastStatusChange: lastStatus
                ? moment().diff(moment(lastStatus.created_at), 'days')
                : undefined,
              arReference: asset.platform_id,
            }
          })
        : undefined,
    [digitalAssets, physicalAssets, operations, t]
  )

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressPivotMode: true,
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
      ],
    }),
    []
  )

  const handleExport = (format: 'excel' | 'csv') => {
    const fileName = `${moment().utc().format('DDMMMyyyy.HHmm')}.UTC`
    if (format === 'excel') {
      ref.current?.api.exportDataAsExcel({
        fileName: `${fileName}.xlsx`,
      })
    } else if (format === 'csv') {
      ref.current?.api.exportDataAsCsv({
        fileName: `${fileName}.csv`,
      })
    }
  }

  const handleClear = () => {
    ref.current?.api.setFilterModel(null)
  }

  const handleInput = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setQuickFilterText(value)
  }

  const handleModelUpdated = () => {
    let totalValue = 0
    let totalAssets = 0
    let tangible = 0
    let digital = 0
    ref.current?.api.forEachNodeAfterFilter(
      (rowNode: IRowNode<{ value: IPrice; category: AssetCategory }>) => {
        totalValue += rowNode.data?.value?.value ?? 0
        totalAssets++
        if (rowNode.data?.category === AssetCategory.DIGITAL) {
          digital++
        } else {
          tangible++
        }
      }
    )
    setTotalValue(formatValue(totalValue, org?.preferred_currency))
    setTotalAssets(totalAssets)
    setTangible(tangible)
    setDigital(digital)
  }

  const handleStateUpdated = (event: StateUpdatedEvent) => {
    const sources: (keyof GridState)[] = [
      'columnOrder',
      'columnVisibility',
      'filter',
      'sort',
    ]
    const stateUpdated = sources.some((source) =>
      event.sources.includes(source)
    )
    if (stateUpdated) {
      const state = {
        columnOrder: event.state.columnOrder,
        columnVisibility: event.state.columnVisibility,
        filter: event.state.filter,
        sort: event.state.sort,
      }
      updateInitialState(state)
    }
  }

  return (
    <div className="flex flex-col gap-8 px-8 py-4">
      <Paragraph title="Reports" />
      <div className="flex gap-4">
        {totalValue && (
          <Card.Overview
            value={totalValue}
            valueDescriptor={t('total_value')}
          />
        )}
        {totalAssets !== undefined && (
          <Card.Overview
            value={totalAssets}
            valueDescriptor={t('total_assets')}
            testId="total_assets"
          />
        )}
        {tangible !== undefined && (
          <Card.Overview value={tangible} valueDescriptor={t('tangible')} />
        )}
        {digital !== undefined && (
          <Card.Overview value={digital} valueDescriptor={t('digital')} />
        )}
      </div>
      <div className="flex">
        <Button.Basic
          hierarchy="secondary"
          icon={{ name: 'bars-filter' }}
          label={t('clear_all_filters')}
          onClick={handleClear}
        />
        <div className="flex grow justify-end gap-5">
          <Input.Text
            icon={<Icon name="search" variant="solid" size="medium" />}
            placeholder={t('search_for_assets')}
            onInput={handleInput}
          />
          <Button.Basic
            id="export"
            hierarchy="secondary"
            label={t('export')}
            trailingIcon={{
              name: 'chevron-down',
            }}
            onClick={() =>
              setDropdown({
                target: 'export',
                controller: (
                  <Dropdown.Controllers.BasicList
                    closeOnItemClick
                    items={[
                      <ListItem
                        key="excel"
                        title="Excel"
                        leading={<Icon variant="solid" name="file-excel" />}
                        className="px-2"
                        onClick={() => handleExport('excel')}
                      />,
                      <ListItem
                        key="csv"
                        title="CSV"
                        leading={<Icon variant="solid" name="file-csv" />}
                        className="px-2"
                        onClick={() => handleExport('csv')}
                      />,
                    ]}
                  />
                ),
              })
            }
          />
        </div>
      </div>
      <div className="ag-theme-quartz h-[calc(100vh-180px)]">
        {initialState && (
          <AgGridReact
            ref={ref}
            suppressContextMenu
            sideBar={sideBar}
            rowData={rowData}
            defaultColDef={defaultColDef}
            columnDefs={colDefs}
            quickFilterText={quickFilterText}
            initialState={initialState}
            onModelUpdated={handleModelUpdated}
            onStateUpdated={handleStateUpdated}
          />
        )}
      </div>
    </div>
  )
}
