import { Asset } from './Asset'
import { Pill } from './Pill'

interface Props {
  ticker: string
  value: number
  reason: string
}

export const Header: React.FC<Props> = ({ ticker, value, reason }) => {
  return (
    <div className="flex items-center justify-between mb-6">
      <Asset ticker={ticker} value={value} />
      <Pill>{reason}</Pill>
    </div>
  )
}
