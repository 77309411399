import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import {
  // IAsset,
  IDigitalAsset,
  IFiatCashAsset,
  IHighValuePersonalPropertyAsset,
  IPhysicalAsset,
  IRealEstateAsset,
  IVehicleAsset,
} from '@/types/asset'
import { IDefendant, IDefendantBase } from '@/types/defendants'
import { IOperation } from '@/types/operations'

interface IGetPhysicalAssetsRealEstate {
  real_estate: IRealEstateAsset
}
interface IGetPhysicalAssetsVehicle {
  vehicle: IVehicleAsset
}
interface IGetPhysicalAssetsHighValue {
  high_value_personal_property: IHighValuePersonalPropertyAsset
}
interface IGetPhysicalAssetsFiatCash {
  fiat_cash: IFiatCashAsset
}
type IGetPhysicalAssets = Array<
  | IGetPhysicalAssetsRealEstate
  | IGetPhysicalAssetsVehicle
  | IGetPhysicalAssetsHighValue
  | IGetPhysicalAssetsFiatCash
>

// type IAssetOperationState =
//   | 'ANY_OPERATIONS'
//   | 'HAS_OPERATIONS'
//   | 'HAS_NO_OPERATIONS'

/**
 * Get all defendants
 * @returns IDefendant[] -> defendant array
 */
export const getDefendants = async (): Promise<
  BaseResponse<IDefendant[] | null>
> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client.get(`/defendants`).then((response) => {
    return response.data
  })
}

/**
 * create defendant
 * @params IDefendant
 * @returns ID of new defendant
 */
export const createDefendant = async (
  data: IDefendant | IDefendantBase
): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .post<BaseResponse<string>>(`/defendants`, data)
    .then((response) => {
      return response.data
    })
}

/**
 * Update defendant
 * @params IDefendant
 * @returns boolean -> success or failure
 */
export const updateDefendant = async (
  data: IDefendant
): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client.put(`/defendants/${data.id}`, data).then((response) => {
    return response.data
  })
}

/**
 * Delete defendant
 * @params string: defendantId
 * @returns boolean -> success or failure
 */
export const deleteDefendant = async (
  defendantId: string
): Promise<BaseResponse<boolean>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client.delete(`/defendants/${defendantId}`).then((response) => {
    return response.data
  })
}

/**
 * Get all digital assets by defendant_id
 * @returns IDigitalAsset[] -> digital assets array
 */
export const getDigitalByDefendantId = async (
  defendant_id: string
): Promise<BaseResponse<IDigitalAsset[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .get(`/defendants/${defendant_id}/assets/digital`)
    .then((response) => {
      return response.data
    })
}

/**
 * Get all physcial assets by defendant_id
 * @returns IAsset[] -> physical assets array
 */
export const getPhysicalByDefendantId = async (
  defendant_id: string
): Promise<BaseResponse<IPhysicalAsset[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .get(`/defendants/${defendant_id}/assets/physical`)
    .then((response) => {
      return convertPhysicalAssetsToSimpleArray(response.data)
    })
}

const convertPhysicalAssetsToSimpleArray = (
  res: BaseResponse<IGetPhysicalAssets>
): BaseResponse<IPhysicalAsset[]> => {
  if (!res.data) {
    return {
      error_code: res.error_code,
      error: res.error,
      message: res.message,
      data: [],
    }
  }

  // The data returned here is weird but we can't mod the model from the BE due to legacy
  // reasons... so we're going to simplify it here so the rest of the site doesn't suffer!
  const high_value_assets: IHighValuePersonalPropertyAsset[] = res.data
    .filter((a) => 'high_value_personal_property' in a)
    .map((a) => (a as IGetPhysicalAssetsHighValue).high_value_personal_property)
  const real_estate_assets: IRealEstateAsset[] = res.data
    .filter((a) => 'real_estate' in a)
    .map((a) => (a as IGetPhysicalAssetsRealEstate).real_estate)
  const vehicle_assets: IVehicleAsset[] = res.data
    .filter((a) => 'vehicle' in a)
    .map((a) => (a as IGetPhysicalAssetsVehicle).vehicle)
  const fiat_cash_assets: IFiatCashAsset[] = res.data
    .filter((a) => 'fiat_cash' in a)
    .map((a) => (a as IGetPhysicalAssetsFiatCash).fiat_cash)

  return {
    error_code: res.error_code,
    error: res.error,
    message: res.message,
    data: [
      ...high_value_assets,
      ...real_estate_assets,
      ...vehicle_assets,
      ...fiat_cash_assets,
    ],
  }
}

/**
 * Get all operations by defendant_id
 * @returns IOperation[] -> operations array
 */
export const getOperationsByDefendantId = async (
  defendant_id: string
): Promise<BaseResponse<IOperation[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .get(`/defendants/${defendant_id}/operations`)
    .then((response) => {
      return response.data
    })
}

/**
 * Get a defendant by defendant_id
 * @returns IDefendant -> selected defendant
 */
export const getDefendant = async (
  defendant_id: string
): Promise<BaseResponse<IDefendant>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client.get(`/defendants/${defendant_id}`).then((response) => {
    return response.data
  })
}
