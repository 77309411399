import AGGridCell from './components/cell.aggrid'
import Cell from './components/cell.table'
import Row from './components/row.table'
import AGGridWrapper from './components/wrapper.aggrid'
import { AdminEscrowsTable } from './variants/admin-escrows.aggrid'
import { AssetsTable, Props as AssetsProps } from './variants/assets.table'
import {
  AssetCustodianTable,
  Props as AssetCustodianProps,
} from './variants/assets-custodian.table'
import {
  AssetsEscrowTable,
  Props as AssetsEscrowProps,
} from './variants/assets-escrow.aggrid'
import { CasesTable, Props as CasesProps } from './variants/cases.table'
import {
  CustodianTable,
  Props as CustodianProps,
} from './variants/custodian.table'
import {
  Props as CustodianDetailAssetsProps,
  SupportedAssetTable,
} from './variants/custodian-supported-assets.table'
import {
  DefendantTable,
  Props as DefendantProps,
} from './variants/defendant.table'
import { EscrowFeesTable } from './variants/escrow-fees.aggrid'
import { EscrowsTable, Props as EscrowsProps } from './variants/escrows.table'
import { FilesTable } from './variants/files.aggrid'
import { OrganizationTable } from './variants/organization.table'
import { SupportedAssetsTable } from './variants/supported-assets.aggrid'
import { Props as TeamProps, TeamTable } from './variants/team.table'
import { Props as TokenProps, TokensTable } from './variants/tokens.table'
import {
  Props as WorkspaceMemberProps,
  WorkspaceMembersTable,
} from './variants/workspace-members.table'

export type Props =
  | CasesProps
  | AssetsProps
  | AssetsEscrowProps
  | EscrowsProps
  | TeamProps
  | DefendantProps
  | TokenProps
  | AssetCustodianProps
  | CustodianProps
  | CustodianDetailAssetsProps
  | WorkspaceMemberProps

export const Table = (props: Props) => {
  if ('defendants' in props) {
    return <DefendantTable {...props} />
  } else if ('tokens' in props) {
    return <TokensTable {...props} />
  } else if ('assetCustodians' in props) {
    return <AssetCustodianTable {...props} />
  } else if ('custodians' in props) {
    return <CustodianTable {...props} />
  } else if ('custodianDetailAssets' in props) {
    return <SupportedAssetTable {...props} />
  } else {
    return null
  }
}

export default {
  // Components
  Components: {
    Row: Row,
    Cell: Cell,
  },

  AgGrid: {
    Cell: AGGridCell,
    Wrapper: AGGridWrapper,
  },

  // Tables
  AdminEscrows: AdminEscrowsTable,
  AssetCustodian: AssetCustodianTable,
  Assets: AssetsTable,
  Cases: CasesTable,
  Custodian: CustodianTable,
  Defendants: DefendantTable,
  EscrowAssets: AssetsEscrowTable,
  EscrowFees: EscrowFeesTable,
  Escrows: EscrowsTable,
  Files: FilesTable,
  Organization: OrganizationTable,
  SupportedAsset: SupportedAssetTable,
  Team: TeamTable,
  Tokens: TokensTable,
  WorkspaceMembers: WorkspaceMembersTable,
  SupportedAssets: SupportedAssetsTable,
}
