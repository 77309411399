// AssetDetailsGroup.tsx

import { t } from 'i18next'

import { AssetAddressSelector } from '@/components/asset/asset-address-selector'
import { AssetCalculator } from '@/components/asset/asset-calculator'
import { AssetSerialNumberSelector } from '@/components/asset/asset-serial-number-selector'
import { Group } from '@/components/group'
import Input from '@/components/input'
import { Currency } from '@/constants/currencies.ts'
import { AssetRules } from '@/helpers/assetRules.ts'
import { AssetCategory, IAssetCost, IAssetValuation } from '@/types/asset'

export interface AssetDetailsValues {
  category?: AssetCategory
  make?: string
  model?: string
  serialNumber?: string
  country?: string
  address?: string
  costs?: IAssetCost[]
  valuations?: IAssetValuation[]
  currency?: Currency
}

export interface AssetDetailsGroupProps {
  rules: AssetRules
  values?: AssetDetailsValues
}

export const AssetFormDetailsGroup = ({
  rules,
  values,
}: AssetDetailsGroupProps) => {
  const showManufacturer = rules.WhenEditing.Show.Manufacturer()
  const showModel = rules.WhenEditing.Show.Model()
  const showCountryAndAddress = rules.WhenEditing.Show.CountryAndAddress()
  const showSerial = rules.WhenEditing.Show.Serial() && values?.category
  const showValuationAndCost = rules.WhenEditing.Show.ValuationAndCost()
  const showGroup =
    showManufacturer ||
    showModel ||
    showCountryAndAddress ||
    showSerial ||
    showValuationAndCost

  if (!showGroup) {
    return null
  }

  return (
    <Group label={t('asset_details')}>
      {showManufacturer && (
        <Input.Text
          name={'asset_manufacturer'}
          testId={'asset_manufacturer'}
          value={values?.make}
          placeholder={t('select_manufacturer')}
          label={t('manufacturer')}
        />
      )}

      {showModel && (
        <Input.Text
          name={'asset_model'}
          testId={'asset_vehicle_model'}
          value={values?.model}
          placeholder={t('select_model')}
          label={t('model')}
        />
      )}

      {showCountryAndAddress && (
        <AssetAddressSelector
          selectedCountry={values?.country}
          selectedAddress={values?.address}
        />
      )}

      {showSerial && (
        <AssetSerialNumberSelector
          value={values?.serialNumber}
          category={values?.category}
        />
      )}

      {showValuationAndCost && (
        <div className={'flex flex-col w-full gap-2'}>
          {rules.IsTangible() && (
            <Input.Component.Label text={t('valuation_and_cost')} />
          )}
          <AssetCalculator
            costs={values?.costs}
            valuations={values?.valuations}
            valuationCurrency={values?.currency}
          />
        </div>
      )}
    </Group>
  )
}
