import { t } from 'i18next'

import { getUser, updateOrgUser } from '@/api'
import { REGEX } from '@/constants/regex'
import { AuthManager } from '@/contexts/auth'
import { FormErrorsManager } from '@/contexts/formErrors'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'
import { IUser } from '@/types/user'

export const EditUserLanguage = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const languageCode = formData.get('language_code')?.toString()

  // Check basic details
  if (IsNot(languageCode, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'language_code',
        error: t('invalid_language_code_provided'),
      },
    ])
  }

  // Grab the latest version of our user object
  const currUserRes = await getUser()

  // Check we got the user ok
  if (currUserRes.error_code || currUserRes.error || !currUserRes.data) {
    throw new Error(ExtractErrorFrom(currUserRes))
  }

  // Create a user object
  const newUser: IUser = {
    ...currUserRes.data,
    language: languageCode.toUpperCase(),
  }

  // Attempt the update the user
  const updateRes = await updateOrgUser(newUser)

  // Check for errors
  if (updateRes.error_code || updateRes.error) {
    throw new Error(ExtractErrorFrom(updateRes))
  }

  // Update user held in auth manager
  await AuthManager.setUser(newUser)

  return {
    error: false,
    message: t('user_language_updated_successfully'),
    completion: 'COMPLETE',
    data: true,
  }
}
