import { t } from 'i18next'

import { addWorkspaceMember } from '@/api/workspaces/v1'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { IsNot } from '@/helpers/test'
import { key as QueryWorkspaceMembersKey } from '@/hooks/queries/useWorkspaceMembers.ts'
import { ActionResponse } from '@/types/actions'

export const AddWorkspaceMember = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const workspaceId = formData.get('workspace_id')?.toString()
  const userId = formData.get('user_id')?.toString()
  const roleId = formData.get('role_id')?.toString()

  // Validate some bits...
  if (!workspaceId) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_workspace_id'),
      },
    ])
  } else if (IsNot(userId, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'user_id',
        error: t('invalid_user_selection'),
      },
    ])
  } else if (IsNot(roleId, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'role_id',
        error: t('invalid_role_selection'),
      },
    ])
  }

  // Attempt to add the new member
  const res = await addWorkspaceMember(workspaceId, userId, roleId)

  // Check if the call failed
  if (res.error_code || res.error) {
    throw new Error(ExtractErrorFrom(res))
  }

  // Invalidate cache
  await queryClient.invalidateQueries({
    queryKey: [QueryWorkspaceMembersKey, workspaceId],
  })

  return {
    error: false,
    message: t('new_member_invited_to_workspace'),
    completion: 'COMPLETE',
    data: true,
  }
}
