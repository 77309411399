import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { postRequestDigitalAssetBalanceUpdate } from '@/api'
import { progressEscrow, rejectEscrow } from '@/api/escrows/v1'
import Button from '@/components/button'
import Chip from '@/components/chip'
import { Group } from '@/components/group'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { InputElement } from '@/components/input/components/element.input'
import { Modal } from '@/components/modal'
import { NoData } from '@/components/no-data'
import { PageBack } from '@/components/page-back'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import { ROUTES } from '@/constants/routes'
import { BannerManager } from '@/contexts/banner'
import { useModal } from '@/contexts/interface'
import { ToastManager } from '@/contexts/toast'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { key as GetEscrowKey, useEscrow } from '@/hooks/queries/useEscrow'
import { key as GetEscrowsKey } from '@/hooks/queries/useEscrows'
import { IEscrowAsset, IEscrowStatusName } from '@/types/escrows'

import { LoaderData } from './loader'

export const AdminEscrowDetail: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const loader = useLoaderData() as LoaderData
  const { escrow } = useEscrow(loader.escrowId)
  const { setModal } = useModal()
  const queryClient = useQueryClient()

  const [shouldShowRejectEscrowModal, setShouldShowRejectEscrowModal] =
    useState(false)
  const [escrowRejectionReason, setEscrowRejectionReason] = useState('')

  const [_processingAction, setProcessingAction] = useState<
    'SUBMIT_FOR_APPROVAL' | 'REJECTING' | undefined
  >(undefined)

  const {
    mutateAsync: updateAssetBalances,
    isPending: isUpdatingAssetsBalances,
  } = useMutation({
    mutationFn: async (assetIds: string[]) => {
      await Promise.all(
        assetIds.map((assetId) =>
          postRequestDigitalAssetBalanceUpdate({
            assetId,
            assetSource: 'ESCROW',
          })
        )
      )

      await Promise.all([
        queryClient.refetchQueries({ queryKey: [GetEscrowsKey] }),
        queryClient.refetchQueries({
          queryKey: [GetEscrowKey, loader.escrowId],
        }),
      ])
    },
  })

  const latestStatus: IEscrowStatusName | undefined =
    escrow?.status?.at(-1)?.name

  useEffect(() => {
    if (escrow) {
      // Grab the latest status name first
      const latestStatus: IEscrowStatusName | undefined =
        escrow.status?.at(-1)?.name

      // Check the status
      if (latestStatus === 'COMPLETED') {
        BannerManager.showBanner({
          variant: 'page',
          type: 'success',
          title: t('escrow_banner_complete'),
        })
      } else if (latestStatus === 'AWAITING_RELEASE') {
        BannerManager.showBanner({
          variant: 'page',
          type: 'information',
          title: t('escrow_banner_awaiting_release'),
        })
      } else if (latestStatus === 'AWAITING_APPROVAL') {
        BannerManager.showBanner({
          variant: 'page',
          type: 'information',
          title: t('escrow_banner_awaiting_approval'),
        })
      } else if (latestStatus === 'AWAITING_CHECK') {
        if (!escrow.organizations.find((o) => o.require_approval)) {
          BannerManager.showBanner({
            variant: 'page',
            type: 'information',
            title: t('escrow_banner_pending_approvers'),
          })
        } else if (
          escrow.digital_assets.find((a) => !a.destination_wallet?.address)
        ) {
          BannerManager.showBanner({
            variant: 'page',
            type: 'information',
            title: t('escrow_banner_pending_no_destination'),
          })
        } else if (escrow.digital_assets.find((a) => (a.balance ?? 0) <= 0)) {
          BannerManager.showBanner({
            variant: 'page',
            type: 'information',
            title: t('escrow_banner_pending_no_balance'),
          })
        } else {
          BannerManager.showBanner({
            variant: 'page',
            type: 'success',
            title: t('escrow_banner_awaiting_check'),
          })
        }
      } else if (latestStatus === 'PENDING') {
        BannerManager.showBanner({
          variant: 'page',
          type: 'information',
          title: t('escrow_banner_pending'),
        })
      }
    }
  }, [escrow])

  const submitForApproval = async () => {
    if (escrow) {
      setProcessingAction('SUBMIT_FOR_APPROVAL')

      // Make the call to approve the documents
      const res = await progressEscrow(escrow.id)

      await Promise.all([
        queryClient.refetchQueries({ queryKey: [GetEscrowsKey] }),
        queryClient.refetchQueries({
          queryKey: [GetEscrowKey, loader.escrowId],
        }),
      ])

      // Check for errors
      if (res.error_code || res.error) {
        setProcessingAction(undefined)
        return ToastManager.showToast({
          type: 'critical',
          text: ExtractErrorFrom(res),
        })
      }

      // Success
      ToastManager.showToast({
        type: 'success',
        text: t('escrow_updated_successfully'),
      })

      setProcessingAction(undefined)
    }
  }

  const reject = async () => {
    if (escrow) {
      setProcessingAction('REJECTING')

      // Make the call to reject the documents
      const res = await rejectEscrow(escrow.id, {
        reason: escrowRejectionReason,
      })

      await Promise.all([
        queryClient.refetchQueries({ queryKey: [GetEscrowsKey] }),
        queryClient.refetchQueries({
          queryKey: [GetEscrowKey, loader.escrowId],
        }),
      ])

      // Check for errors
      if (res.error_code || res.error) {
        setProcessingAction(undefined)
        return ToastManager.showToast({
          type: 'critical',
          text: ExtractErrorFrom(res),
        })
      }

      // Success
      ToastManager.showToast({
        type: 'success',
        text: t('action_successful'),
      })

      setProcessingAction(undefined)
    }
  }

  const handleAddDestinationAddress = useCallback(
    (asset: IEscrowAsset) => {
      if (escrow) {
        setModal({
          id: 'ADD_BLOCKCHAIN_ADDRESS',
          asset,
          escrow,
        })
      }
    },
    [escrow]
  )

  const handleAddFees = useCallback(() => {
    if (escrow) {
      setModal({
        id: 'ESCROW_FEE',
        mode: 'setup',
        escrow: escrow,
      })
    }
  }, [escrow])

  const handleEditApprovers = useCallback(() => {
    if (escrow) {
      setModal({
        id: 'ESCROW_APPROVERS',
        escrow,
      })
    }
  }, [escrow])

  if (!escrow) {
    return <NoData description={t('escrow_not_found')} />
  }

  return (
    <>
      <div className={twMerge('flex flex-col')}>
        <PageBack url={ROUTES.ADMIN.ESCROW.INDEX} />

        <div className={'flex flex-col gap-8 p-8 pt-4'}>
          <div
            className={twMerge(
              'flex flex-col tablet:flex-row gap-[24px]',
              'p-[24px] self-stretch justify-between',
              'items-start tablet:items-center',
              'bg-[#F5F5F6] rounded-[12px]'
            )}
          >
            <Paragraph title={escrow?.name} spacerOverrides={{ title: [] }} />
            <div
              className={twMerge(
                'flex flex-col tablet:flex-row',
                'w-full tablet:w-auto',
                'gap-[16px]'
              )}
            >
              {escrow.digital_assets.length ? (
                <Button.Basic
                  icon={{
                    name: 'refresh',
                    family: 'sharp',
                    variant: 'solid',
                  }}
                  hierarchy="secondary"
                  label={t('update_balance')}
                  onClick={() =>
                    updateAssetBalances(
                      escrow.digital_assets.map(({ id }) => id)
                    )
                  }
                  state={isUpdatingAssetsBalances ? 'loading' : 'default'}
                  className={'self-stretch tablet:self-auto'}
                />
              ) : null}

              {latestStatus === 'AWAITING_CHECK' ? (
                <>
                  <Button.Basic
                    icon={{
                      name: 'circle-check',
                      family: 'sharp',
                      variant: 'solid',
                    }}
                    hierarchy="primary"
                    label={t('submit_for_approval')}
                    onClick={submitForApproval}
                    state={
                      _processingAction === 'SUBMIT_FOR_APPROVAL'
                        ? 'loading'
                        : // If we have digital assets that don't have a destination address
                          // specified...
                          escrow.digital_assets.find(
                              (a) => a.destination_wallet?.address === undefined
                            ) ||
                            // Or if we havent got any approvers specified...
                            !escrow.organizations.find(
                              (o) =>
                                o.require_approval &&
                                (o.number_of_approvals ?? 0) > 0
                            )
                          ? 'disabled'
                          : 'default'
                    }
                    className={'self-stretch tablet:self-auto'}
                  />
                  <Button.Basic
                    icon={{
                      name: 'circle-xmark',
                      family: 'sharp',
                      variant: 'solid',
                    }}
                    hierarchy="secondary"
                    label={t('reject')}
                    onClick={() => setShouldShowRejectEscrowModal(true)}
                    state={
                      _processingAction === 'REJECTING'
                        ? 'loading'
                        : !_processingAction &&
                            latestStatus === 'AWAITING_CHECK'
                          ? 'default'
                          : 'disabled'
                    }
                    className={'self-stretch tablet:self-auto'}
                  />
                </>
              ) : null}
            </div>
          </div>

          <div className={'flex flex-row flex-wrap gap-[24px]'}>
            <Group label={t('ar_reference')} inline={true}>
              <Input.IDNumber id={escrow.platform_id ?? ''} hideLabel={true} />
            </Group>
            <Group label={t('status')} inline={true}>
              <Chip.Status
                status={latestStatus ?? 'PENDING'}
                context={'ESCROW'}
                size={'large'}
              />
            </Group>
            <Group label={t('party_a')} inline={true} className="min-w-[150px]">
              <Input.Auction
                size={'medium'}
                value={escrow.organizations[0].name}
                icon={
                  <Icon
                    name={'buildings'}
                    variant="solid"
                    size="medium"
                    family={'sharp'}
                  />
                }
                trailingIcon={
                  escrow.organizations[0].require_approval ? (
                    <Icon
                      name={'circle-check'}
                      variant="solid"
                      size="medium"
                      family={'sharp'}
                    />
                  ) : undefined
                }
              />
            </Group>
            <Group label={t('party_b')} inline={true} className="min-w-[150px]">
              <Input.Auction
                size={'medium'}
                value={escrow.organizations[1].name}
                icon={
                  <Icon
                    name={'buildings'}
                    variant="solid"
                    size="medium"
                    family={'sharp'}
                  />
                }
                trailingIcon={
                  escrow.organizations[1].require_approval ? (
                    <Icon
                      name={'circle-check'}
                      variant="solid"
                      size="medium"
                      family={'sharp'}
                    />
                  ) : undefined
                }
              />
            </Group>
          </div>

          <div className={'flex flex-row gap-[24px]'}>
            {latestStatus === 'AWAITING_CHECK' && (
              <Button.Basic
                icon={{
                  name: 'circle-check',
                  family: 'sharp',
                  variant: 'solid',
                }}
                hierarchy="secondary"
                label={t('select_approvers')}
                onClick={handleEditApprovers}
                className={'self-stretch tablet:self-auto'}
              />
            )}

            <Button.Basic
              icon={{
                name: 'coins',
                family: 'sharp',
                variant: 'solid',
              }}
              hierarchy="secondary"
              label={t('add_fees')}
              state={
                escrow.fees?.find((f) => f.status.at(-1)?.name !== 'REJECTED')
                  ? 'disabled'
                  : 'default'
              }
              onClick={handleAddFees}
              className={'self-stretch tablet:self-auto'}
            />
          </div>

          {shouldShowRejectEscrowModal ? (
            <Modal visible>
              <div className={'flex flex-col p-6 gap-6'}>
                <Paragraph
                  title={t('reject_escrow')}
                  description={t('enter_reason')}
                  spacerOverrides={{
                    description: [],
                    title: ['bottom'],
                  }}
                />

                <InputElement
                  variant="default"
                  onValueChanged={(event) =>
                    setEscrowRejectionReason(event.target.value)
                  }
                  input={{
                    key: 'escrow-rejection-reason',
                    type: 'text',
                    props: {
                      value: escrowRejectionReason,
                    },
                  }}
                />

                <div className="flex gap-2">
                  <Button.Basic
                    hierarchy="special"
                    label={t('reject')}
                    onClick={async () => {
                      await reject()
                      setShouldShowRejectEscrowModal(false)
                    }}
                    state={
                      _processingAction === 'REJECTING'
                        ? 'loading'
                        : escrowRejectionReason
                          ? undefined
                          : 'disabled'
                    }
                  />
                  <Button.Basic
                    hierarchy="primary"
                    label={t('close')}
                    onClick={() => setShouldShowRejectEscrowModal(false)}
                    state={
                      _processingAction === 'REJECTING' ? 'disabled' : undefined
                    }
                  />
                </div>
              </div>
            </Modal>
          ) : null}

          <Group
            shadow={true}
            className={'p-[24px] pt-[32px] min-h-[112px]'}
            label={t('assets')}
          >
            {escrow.digital_assets.length > 0 && (
              <Table.EscrowAssets
                data={escrow.digital_assets}
                canSetDestinationAddress={latestStatus === 'AWAITING_CHECK'}
                onAddDestinationAddress={handleAddDestinationAddress}
              />
            )}
          </Group>

          <Group
            shadow={true}
            className={'p-[24px] pt-[32px] min-h-[112px]'}
            label={t('documents')}
          >
            {escrow.files && escrow.files.length > 0 && (
              <Table.Files
                data={escrow.files ?? []}
                organizations={escrow.organizations}
              />
            )}
          </Group>

          {escrow.fees?.length && escrow.fees[0].files.length && (
            <Group
              shadow={true}
              className={'p-[24px] pt-[32px] min-h-[112px]'}
              label={t('fees')}
            >
              <Table.EscrowFees data={escrow} mode={'setup'} />
            </Group>
          )}
        </div>
      </div>
    </>
  )
}
