import { ColDef, ColDefField } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'

import { Typography } from '@/components/typography'

import { ColDefOptions } from './_options.coldef'

interface Options<T> extends ColDefOptions<T> {
  field: ColDefField<T>
}

export const NumberColDef = <T,>(options: Options<T>) => {
  return {
    field: options.field,
    headerName: options?.headerName,
    type: 'rightAligned',
    filter: 'agNumberColumnFilter',
    cellRenderer: (cellProps: CustomCellRendererProps<T>) => {
      return (
        <div className={'flex flex-row justify-end w-full'}>
          <Typography
            variant={'paragraph-small'}
            className={'text-right truncate'}
          >
            {cellProps.value ?? 0}
          </Typography>
        </div>
      )
    },
    width: 120,
    initialWidth: 120,
  } as ColDef<T>
}
