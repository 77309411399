export type BaseResponse<T> = {
  error_code?: string
  data: T | null

  /**
   * @deprecated Use `error_code` - if exists then an error was raised.
   */
  error?: boolean
  /**
   * @deprecated Use `error_code` instead and convert to a translatable message.
   */
  message?: string
}

export type ErrorResponse = {
  error: boolean
  message: string
}

export function isBaseResponse<T>(obj: unknown): obj is BaseResponse<T> {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    'data' in obj &&
    ('message' in obj || 'error_code' in obj)
  )
}
