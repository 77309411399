import { t } from 'i18next'
import { useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { approveTransferRequest, declineTransferRequest } from '@/api'
import { AssetDetails } from '@/components/asset/detail'
import { Avatar } from '@/components/avatar'
import { Badge } from '@/components/badge'
import Banner from '@/components/banner'
import Button from '@/components/button'
import Card from '@/components/card'
import { Divider } from '@/components/divider'
import { ListItem } from '@/components/list-item'
import Logo from '@/components/logo'
import { Paragraph } from '@/components/paragraph'
import { BannerManager } from '@/contexts/banner'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ITransferRequestStatus } from '@/types/transfer'

import { LoaderData } from './loader'

export const Transfers: React.FC = (): JSX.Element => {
  const { asset, requestedBy, transferRequest } = useLoaderData() as LoaderData
  const [_status, setStatus] = useState<ITransferRequestStatus>(
    transferRequest?.status ?? 'PENDING'
  )
  const [_statePanelVisibility, setStatePanelVisibility] = useState<
    'VISIBLE' | 'HIDING' | 'HIDDEN'
  >('HIDDEN')
  const [_submitting, setSubmitting] = useState<
    null | 'APPROVING' | 'DECLINING'
  >(null)
  const navigate = useNavigate()

  const approveTransfer = async () => {
    if (transferRequest) {
      setSubmitting('APPROVING')
      try {
        const res = await approveTransferRequest(transferRequest.id)
        if (res.error_code || res.error) {
          BannerManager.showBanner({
            variant: 'page',
            type: 'error',
            title: 'Failed to approve',
            description: ExtractErrorFrom(res),
          })
        } else {
          setStatus('APPROVED')
          showStatePanel()
        }
      } catch (e) {
        BannerManager.showBanner({
          variant: 'page',
          type: 'error',
          title: 'Failed to approve',
          description: t('something_went_wrong'),
        })
      } finally {
        setSubmitting(null)
      }
    }
  }

  const declineTransfer = async () => {
    if (transferRequest) {
      setSubmitting('DECLINING')
      try {
        const res = await declineTransferRequest(transferRequest.id)
        if (res.error_code || res.error) {
          BannerManager.showBanner({
            variant: 'page',
            type: 'error',
            title: 'Failed to decline',
            description: ExtractErrorFrom(res),
          })
        } else {
          setStatus('DECLINED')
          showStatePanel()
        }
      } catch (e) {
        BannerManager.showBanner({
          variant: 'page',
          type: 'error',
          title: 'Failed to decline',
          description: t('something_went_wrong'),
        })
      } finally {
        setSubmitting(null)
      }
    }
  }

  const showStatePanel = () => {
    setStatePanelVisibility('VISIBLE')
    setTimeout(() => {
      setStatePanelVisibility('HIDING')
      setTimeout(() => {
        setStatePanelVisibility('HIDDEN')
      }, 300)
    }, 3000)
  }

  return (
    <div className="flex flex-col h-[100dvh] min-h-[100dvh] max-h-[100dvh] w-[100vw] max-w-[100vw] overflow-hidden">
      <Banner.Site />
      <div
        className={twMerge(
          'flex w-full px-[32px] flex-col items-center',
          'bg-white'
        )}
      >
        <div
          className={twMerge(
            'flex flex-row h-[4.5rem] justify-between items-center self-stretch'
          )}
        >
          <Logo.WordMark
            style={'COLOR'}
            heightRem={2.75}
            color={'BLACK'}
            onClick={() => navigate('/')}
          />
          <div
            className={twMerge(
              'flex items-center gap-6 self-stretch',
              _status !== 'PENDING' && 'hidden'
            )}
          >
            {requestedBy && (
              <ListItem
                title={requestedBy?.name ?? ''}
                description={t('requested_a_transfer')}
                style={'bold'}
                textAlignment={'right'}
                trailing={
                  <Avatar
                    type={'monogram'}
                    value={requestedBy?.name ?? ''}
                    size={'medium'}
                    shape={'round'}
                  />
                }
              />
            )}
          </div>
        </div>
        <Divider />
      </div>
      <main className="overflow-y-auto min-h-dvh relative">
        <Banner.Toast />
        <Banner.Welcome />
        {!transferRequest ? (
          <div className={'flex w-full h-full items-center justify-center'}>
            <Card.PageState
              type={'error'}
              title={t('not_found')}
              description={t('transfer_request_not_found')}
            />
          </div>
        ) : (
          <>
            <div
              className={twMerge(
                'fixed inset-0 z-40',
                'flex flex-1 items-center justify-center',
                'mt-[72px] w-full h-full',
                _status === 'APPROVED'
                  ? 'bg-[#53AB64]'
                  : _status === 'DECLINED'
                    ? 'bg-[#E01F3D]'
                    : 'bg-transparent',
                _statePanelVisibility === 'HIDING'
                  ? 'animate-fade-out'
                  : _statePanelVisibility === 'VISIBLE'
                    ? 'animate-fade-in'
                    : 'hidden'
              )}
            >
              <Card.PageState
                type={
                  _status === 'APPROVED'
                    ? 'success'
                    : _status === 'DECLINED'
                      ? 'declined'
                      : 'information'
                }
                title={
                  _status === 'APPROVED'
                    ? t('approved')
                    : _status === 'DECLINED'
                      ? t('declined')
                      : ''
                }
                description={t('transfer_approved_description')}
                color={'white'}
              />
            </div>

            <div
              className={twMerge(
                'flex flex-col w-full pt-[32px] items-center',
                'pb-6 tablet:pb-8 desktop:pb-16'
              )}
            >
              <div
                className={twMerge(
                  'flex flex-col w-full max-w-[1280px]',
                  'px-[24px] pt-[16px]',
                  'items-start gap-[16px]',
                  'tablet:px-[32px]',
                  _status === 'PENDING' && 'sticky top-0 bg-white z-10'
                )}
              >
                {_status === 'APPROVED' ? (
                  <div
                    className={twMerge(
                      'flex flex-row bg-[#53AB64] rounded-[0.125rem]',
                      'py-[8px] px-[24px] content-center items-center gap-3 self-stretch'
                    )}
                  >
                    <Badge
                      type={'icon'}
                      color={'green'}
                      size={'medium'}
                      icon={'circle-check'}
                    />
                    <Paragraph
                      subTitle={t('transfer_request_approved')}
                      spacerOverrides={{ subTitle: [] }}
                      classOverrides={{ subTitle: 'text-white' }}
                    />
                  </div>
                ) : _status === 'DECLINED' ? (
                  <div
                    className={twMerge(
                      'flex flex-row bg-[#E01F3D] rounded-[0.125rem]',
                      'py-[8px] px-[24px] content-center items-center gap-3 self-stretch'
                    )}
                  >
                    <Badge
                      type={'icon'}
                      color={'red'}
                      size={'medium'}
                      icon={'circle-xmark'}
                    />
                    <Paragraph
                      subTitle={t('transfer_request_declined')}
                      spacerOverrides={{ subTitle: [] }}
                      classOverrides={{ subTitle: 'text-white' }}
                    />
                  </div>
                ) : (
                  <>
                    <div
                      className={twMerge(
                        'flex flex-col items-start gap-2 self-stretch',
                        'flex-wrap',
                        'tablet:items-center tablet:flex-row'
                      )}
                    >
                      <Paragraph
                        title={t('transfer_request')}
                        spacerOverrides={{ title: [] }}
                      />
                      <div
                        className={twMerge(
                          'flex flex-1 justify-start items-center',
                          'gap-6 self-stretch',
                          'tablet:justify-end'
                        )}
                      >
                        <Button.Basic
                          size={'medium'}
                          hierarchy={'primary'}
                          label={t('approve')}
                          state={
                            _submitting === 'APPROVING'
                              ? 'loading'
                              : _submitting === null
                                ? 'default'
                                : 'disabled'
                          }
                          icon={{
                            name: 'circle-check',
                            family: 'sharp',
                            variant: 'solid',
                          }}
                          onClick={approveTransfer}
                        />
                        <Button.Basic
                          size={'medium'}
                          hierarchy={'destructive'}
                          label={t('decline')}
                          state={
                            _submitting === 'DECLINING'
                              ? 'loading'
                              : _submitting === null
                                ? 'default'
                                : 'disabled'
                          }
                          icon={{
                            name: 'circle-xmark',
                            family: 'sharp',
                            variant: 'solid',
                          }}
                          onClick={declineTransfer}
                        />
                      </div>
                    </div>
                    <Divider />
                  </>
                )}
              </div>
              <div className={'w-full max-w-[720px]'}>
                <Banner.Page />
                <AssetDetails asset={asset} readOnly={true} />
              </div>
            </div>
          </>
        )}
      </main>
    </div>
  )
}
