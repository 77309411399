import { t } from 'i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Divider } from '@/components/divider'
import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { ROUTES } from '@/constants/routes'
import { useAuth } from '@/contexts/auth'
import { useOrg } from '@/hooks/queries/useOrg'

import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<unknown> {}

const NavigationView = (props: Props) => {
  const { user, hasPolicy } = useAuth()
  const { org } = useOrg()
  const navigator = useNavigate()
  const { pathname } = useLocation()

  const renderListItem = (
    action: string | (() => void),
    label: string,
    icon: string
  ) => {
    return (
      <ListItem
        key={`li_nav_${label}`}
        title={label}
        style={'bold'}
        leading={<Icon name={icon} family={'sharp'} variant={'solid'} />}
        {...(typeof action === 'string' &&
          pathname === action && {
            trailing: (
              <Icon
                name="circle-check"
                family={'sharp'}
                variant={'solid'}
                size={'small'}
              />
            ),
          })}
        onClick={typeof action === 'string' ? () => navigator(action) : action}
        className={'pl-4 pr-4'}
      />
    )
  }

  const renderLiAssets = () => {
    if (
      user?.role !== 'super' &&
      hasPolicy('CUSTODY.VIEW_ASSET_DETAIL') &&
      hasPolicy('CUSTODY.VIEW_OPERATION')
    ) {
      return renderListItem(ROUTES.ASSETS.INDEX, t('assets'), 'objects-column')
    }
    return null
  }

  const renderLiOperations = () => {
    if (user?.role !== 'super' && hasPolicy('CUSTODY.VIEW_OPERATION')) {
      return renderListItem(ROUTES.CASES.INDEX, t('cases'), 'folder')
    }
    return null
  }

  const renderLiDefendants = () => {
    if (user?.role !== 'super' && hasPolicy('CUSTODY.MANAGE_DEFENDANT')) {
      return renderListItem(
        ROUTES.SUBJECT.INDEX,
        t('subjects'),
        'user-tie-hair'
      )
    }
    return null
  }

  const renderLiTeamManagement = () => {
    if (user?.role !== 'super' && hasPolicy('TEAM.MANAGE_ORG')) {
      return renderListItem(ROUTES.TEAM_MANAGEMENT, t('team'), 'users')
    }
    return null
  }

  const renderLiReporting = () => {
    if (user?.role !== 'super' && hasPolicy('REPORTING.GENERATE_REPORT')) {
      return renderListItem(ROUTES.REPORTS, t('reports'), 'file-chart-pie')
    }
    return null
  }

  const renderLiEscrow = () => {
    if (
      org?.escrow_enabled &&
      hasPolicy(['ESCROW.APPROVER', 'ESCROW.INITIATOR'], false)
    ) {
      return renderListItem(
        ROUTES.ESCROW.INDEX,
        t('escrow'),
        'hands-holding-dollar'
      )
    }
    return null
  }

  const renderLiAdminEscrow = () => {
    if (['super'].includes(user?.role ?? '')) {
      return renderListItem(
        ROUTES.ADMIN.ESCROW.INDEX,
        t('escrow'),
        'hands-holding-dollar'
      )
    }
    return null
  }

  const renderLiAdminOrganisation = () => {
    if (user?.role === 'super') {
      return renderListItem(
        ROUTES.ADMIN.ORGANIZATION.INDEX,
        t('organization'),
        'buildings'
      )
    }
    return null
  }

  const renderLiAdminTeamManagement = () => {
    if (user?.role === 'super') {
      return renderListItem(
        ROUTES.ADMIN.TEAM_MANAGEMENT.ORG.replace(
          ':org_id',
          user?.organization_id as string
        ),
        t('ar_team'),
        'users'
      )
    }
    return null
  }

  const renderLiAdminDisposal = () => {
    const mode = import.meta.env.MODE
    if ((mode === 'localdev' || mode === 'staging') && user?.role === 'super') {
      return renderListItem(
        ROUTES.ADMIN.DISPOSAL,
        'Disposal',
        'arrow-right-arrow-left'
      )
    }
    return null
  }

  const renderNavigations = () => {
    const liAdminOrg = renderLiAdminOrganisation()
    const liAdminTeam = renderLiAdminTeamManagement()
    const liAdminEscrow = renderLiAdminEscrow()
    const liAdminDisposal = renderLiAdminDisposal()
    const liAssets = renderLiAssets()
    const liOperations = renderLiOperations()
    const liDefendants = renderLiDefendants()
    const liTeam = renderLiTeamManagement()
    const liReports = renderLiReporting()
    const liEscrow = renderLiEscrow()

    return [
      ...(liAdminOrg ? [liAdminOrg] : []),
      ...(liAdminTeam ? [liAdminTeam] : []),
      ...(liAdminEscrow ? [liAdminEscrow] : []),
      ...(liAdminDisposal ? [liAdminDisposal] : []),
      ...(liOperations ? [liOperations] : []),
      ...(liAssets ? [liAssets] : []),
      ...(liDefendants ? [liDefendants] : []),
      ...(liTeam ? [liTeam] : []),
      ...(liEscrow ? [liEscrow] : []),
      ...(liReports ? [liReports] : []),
    ]
  }

  return (
    <DropdownView
      {...props}
      id={'NAVIGATION'}
      items={[
        ...renderNavigations(),
        <Divider key={`item_4`} size={'small'} />,
        <ListItem
          key={`li_nav_help`}
          title={t('help')}
          style={'bold'}
          leading={
            <Icon name={'circle-question'} family={'sharp'} variant={'solid'} />
          }
          trailing={
            <Icon
              name={'square-arrow-up-right'}
              size={'medium'}
              family={'sharp'}
              variant={'regular'}
            />
          }
          onClick={() => window.open(ROUTES.HELP, '_blank')}
          className={'pl-4 pr-4'}
        />,
      ]}
    />
  )
}
export default NavigationView
