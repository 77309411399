import { IAssetStatus } from '@/types/asset'

import { isDefined } from './isDefined'

export const findLastStatus = (
  statuses: IAssetStatus[] | undefined
): IAssetStatus | null => {
  if (!isDefined(statuses) || statuses.length == 0) {
    return null
  }
  return statuses.sort(
    (a, b) =>
      new Date(b.created_at ?? 0).getTime() -
      new Date(a.created_at ?? 0).getTime()
  )[0]
}
