import { t } from 'i18next'

import { transferAsset } from '@/api'
import { REGEX } from '@/constants/regex'
import { FormErrorsManager } from '@/contexts/formErrors'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'

export const TransferAsset = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  // Grab data from the form
  const assetId = formData.get('asset_id')?.toString()
  const toWorkspace = formData.get('to_workspace')?.toString()
  const fromWorkspace = formData.get('from_workspace')?.toString() ?? ''

  // Validate the form
  if (IsNot(toWorkspace, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'email',
        error: t('invalid_workspace_selection'),
      },
    ])
  } else if (IsNot(fromWorkspace, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_from_workspace_selection'),
      },
    ])
  } else if (IsNot(assetId, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('missing_asset_id'),
      },
    ])
  }

  // Update our operation
  const res = await transferAsset(toWorkspace, fromWorkspace, [assetId])

  // Check the share worked
  if (res.error_code || res.error) {
    throw new Error(ExtractErrorFrom(res))
  }

  return {
    error: false,
    message: t('asset_transfer_request_sent_successfully'),
    completion: 'COMPLETE',
    data: true,
  }
}
