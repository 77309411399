import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import bg_banner from '/bg_banner.jpg'
import Button from '@/components/button'
import { NoData } from '@/components/no-data'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import { useAuth } from '@/contexts/auth'
import { BannerManager } from '@/contexts/banner'
import { useDrawer } from '@/contexts/interface'
import { shouldShowBanner } from '@/helpers/shouldShowBanner'
import { useOperations } from '@/hooks/queries/useOperations'

export const Cases: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { setDrawer } = useDrawer()
  const { operations } = useOperations()

  const WELCOME_TIMEOUT_MINUTES = 2
  useEffect(() => {
    if (shouldShowBanner('welcome_banner', WELCOME_TIMEOUT_MINUTES)) {
      BannerManager.showBanner({
        variant: 'welcome',
        items: [
          {
            title: t('welcome'),
            image: bg_banner,
            description: t('asset_reality_platform_description'),
          },
        ],
      })
      return () => {
        BannerManager.hideBanners('welcome')
      }
    } else {
      return () => {}
    }
  }, [t])

  const { policies } = useAuth()
  const canCreateCase = policies?.includes('CUSTODY.MANAGE_OPERATION')

  return (
    <>
      <div
        className={twMerge(
          'flex flex-col min-h-[calc(100dvh-80px)]',
          'tablet:pt-0'
        )}
      >
        <div className={twMerge('flex flex-col p-8 pt-4 pb-0 gap-8')}>
          <Paragraph
            title={t('cases')}
            description={t('case_description')}
            spacerOverrides={{ description: [] }}
          />
          {canCreateCase ? (
            <div className={'flex flex-row gap-2'}>
              <Button.Basic
                icon={{
                  name: 'plus',
                }}
                testId={'btn_add_new_operation'}
                label={t('create_new_case')}
                onClick={() => {
                  setDrawer({ id: 'NEW_CASE' })
                }}
                className="hidden tablet:flex"
              />
            </div>
          ) : null}
        </div>
        {operations && operations.length > 0 ? (
          <div
            className={'flex flex-col px-8 pt-8'}
            data-testid="case-table-wrapper"
          >
            <Table.Cases testId={'table_cases'} data={operations ?? []} />
          </div>
        ) : (
          <NoData description={t('no_cases_description')} />
        )}

        <Button.Fab
          label={t('create_new_case')}
          icon={{
            name: 'plus',
          }}
          onClick={() => {
            setDrawer({ id: 'NEW_CASE' })
          }}
        />
      </div>
    </>
  )
}
