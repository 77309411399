import { t } from 'i18next'

import { updateOperation } from '@/api/broker/v2/operations'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { IsNot } from '@/helpers/test'
import { key as QueryOperationsKey } from '@/hooks/queries/useOperations'
import { ActionResponse } from '@/types/actions'
import { IOperation, IOperationStatus } from '@/types/operations'

export const EditCase = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  // Grab data from the form
  const case_original = formData.get('case_original')?.toString()
  const name = formData.get('case_name')?.toString()?.trim() ?? ''
  const status = formData.get('case_status')?.toString()

  // Validate the form
  if (IsNot(case_original, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_case_provided'),
      },
    ])
  } else if (IsNot(name, REGEX.IS_CASE_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'case_name',
        error: t('invalid_case_name_provided'),
      },
    ])
  }

  // Parse out the original case object as we'll use that as our base
  const existing_case: IOperation = JSON.parse(case_original)

  // Update our operation
  const o_res = await updateOperation(existing_case.id, {
    ...existing_case,
    name: name,
    status: (status ?? existing_case.status) as IOperationStatus,
  })
  if (o_res.error_code || o_res.error) {
    throw new Error(ExtractErrorFrom(o_res))
  }

  // Invalidate the current operations cache so we've got up to date data
  await queryClient.invalidateQueries({ queryKey: [QueryOperationsKey] })

  return {
    error: false,
    message: `${t('case')} '${existing_case.name}' ${t('updated_successfully')}`,
    completion: 'COMPLETE',
    data: existing_case.id,
  }
}
