import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import { getAllCustodians, getCustodians } from '@/api/org'
import Button from '@/components/button'
import Card from '@/components/card'
import { Form } from '@/components/form'
import { Group } from '@/components/group'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { NoData } from '@/components/no-data'
import { Paragraph } from '@/components/paragraph'
import { Switch } from '@/components/switch'
import { Table } from '@/components/table'
import {
  Currency,
  getCurrencyIcon,
  getCurrencyTitle,
} from '@/constants/currencies.ts'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { getEnumValueFromString } from '@/helpers/enums.ts'
import { ActionResponse } from '@/types/actions'
import { ICustodian } from '@/types/custodian'
import { IOrganisation } from '@/types/organisation'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  org: IOrganisation
  onOrganizationUpdated: () => void
}

export const EditOrganizationDrawer = (props: Props) => {
  const navigate = useNavigate()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const [_selectedCurrency, setSelectedCurrency] = useState<Currency>(
    getEnumValueFromString(
      Currency,
      props.org?.preferred_currency?.toUpperCase()
    ) ?? Currency.USD
  )
  const [enable2FA, setEnable2FA] = useState<boolean>(
    !props.org.two_factor_disabled
  )
  const [existingAddress, setExistingAddress] = useState(
    props.org.existing_wallet_digital_assets_enabled
  )
  const [customTracker, setCustomTracker] = useState(
    props.org.self_managed_digital_assets_enabled
  )
  const [escrowEnabled, setEscrowEnabled] = useState(props.org.escrow_enabled)

  const [_loading, setLoading] = useState<boolean>(true)
  const [_custodians, setCustodians] = useState<ICustodian[]>([])
  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    // const organizationUrl = `/cases/${actionRes.data}`
    const organizationUrl = `/organization`
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })
    navigate(organizationUrl)
    props.onClose()
  }

  useEffect(() => {
    const asyncFunc = async () => {
      // const { data } = await getCustodians(props.org.primary_owner.organization_id)
      // setCustodians(data ?? [])
      // setLoading(false)
      const res = await Promise.allSettled([
        getCustodians(props.org.primary_owner.organization_id),
        getAllCustodians(),
      ])

      const _org_custodians =
        res[0].status === 'fulfilled' && Array.isArray(res[0].value.data)
          ? res[0].value.data
          : []
      const _all_custodians =
        res[1].status === 'fulfilled' && Array.isArray(res[1].value.data)
          ? res[1].value.data
          : []

      const _updated_custodians =
        _all_custodians.length > 0
          ? _all_custodians.map((_c) => {
              return _org_custodians.find((_o_c) => _o_c.id === _c.id)
                ? { ..._c, status: 'ACTIVE' }
                : _c
            })
          : []
      setCustodians(_updated_custodians)
      setLoading(false)
    }
    asyncFunc()
  }, [])

  return (
    <Drawer
      title={t('edit_organization')}
      description={t('organization_detail_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      {_loading ? (
        <Card.PageState
          type={'loading'}
          title={t('fetching_data')}
          description={t('please_wait')}
        />
      ) : (
        <Form<string, INTENTS>
          type={'other'}
          testId={'create-organization-form'}
          buttons={[
            <Button.Basic
              key={'btn_edit_organization'}
              label={t('edit_organization')}
              state={
                _submitting === INTENTS.EDIT_ORGANIZATION
                  ? 'loading'
                  : _submitting !== null
                    ? 'disabled'
                    : 'default'
              }
              withAttributes={{
                type: 'submit',
                name: 'intent',
                value: INTENTS.EDIT_ORGANIZATION,
              }}
            />,
          ]}
          onSubmit={onSubmit}
          onSuccess={onSuccess}
          onException={onException}
          onFailed={clearSubmitting}
          onUnknownResponse={clearSubmitting}
          containerClassName="gap-[48px]"
        >
          <input
            type="hidden"
            name="custodian_obj"
            value={JSON.stringify(_custodians ?? [])}
          />

          <input type="hidden" name="org_id" value={props.org.id} />

          <Group label={t('details')}>
            <Input.Text
              id="org_name"
              name="org_name"
              label={t('name')}
              autoComplete={'off'}
              defaultValue={props.org?.name}
              placeholder={t('organization_name_placeholder')}
            />

            <Input.Country name={'country'} selectedValue={props.org.country} />

            <Input.Dropdown
              label={t('currency')}
              name={'currency'}
              {...{
                icon: (
                  <Icon
                    name={getCurrencyIcon(_selectedCurrency)}
                    variant={'solid'}
                    family={'sharp'}
                    size={'small'}
                  />
                ),
              }}
              value={_selectedCurrency}
              text={getCurrencyTitle(_selectedCurrency)}
              items={Object.values(Currency).map((currency, index) => (
                <ListItem
                  key={`currency_${index}`}
                  title={getCurrencyTitle(currency)}
                  value={currency}
                  style={'normal'}
                  onClick={() => {
                    setSelectedCurrency(currency)
                  }}
                  {...{
                    leading: (
                      <Icon
                        size={'small'}
                        family={'sharp'}
                        name={getCurrencyIcon(currency)}
                        variant={'solid'}
                      />
                    ),
                  }}
                  trailing={
                    _selectedCurrency === currency ? (
                      <Icon
                        size={'small'}
                        family={'sharp'}
                        name={'circle-check'}
                        variant={'solid'}
                      />
                    ) : undefined
                  }
                  className={'pl-2 pr-2'}
                />
              ))}
            />
          </Group>

          <Group label={t('owner')}>
            <Paragraph description={t('owner_description')} />

            <Input.Text
              id="name"
              name="_name"
              state={'readonly'}
              label={t('name')}
              autoComplete={'off'}
              hint={t('subject_hint')}
              placeholder="e.g. Emmett Brown"
              defaultValue={props.org.primary_owner?.name}
              icon={<Icon name={'user'} family={'sharp'} variant={'solid'} />}
            />

            <Input.Email
              id="email"
              name="email"
              state={'readonly'}
              dynamicInput
              label={t('email_address')}
              placeholder={t('email_address_placeholder')}
              autoComplete="email"
              defaultValue={props.org.primary_owner?.email}
              icon={
                <Icon name={'envelope'} family={'sharp'} variant={'solid'} />
              }
            />

            <div className="max-w-[23.4rem]">
              <ListItem
                title={t('2fa')}
                description={t('two_factor_authentication_description')}
                style={'bold'}
                trailing={
                  <Switch
                    name={'two_factor_enabled'}
                    enabled={enable2FA}
                    onChanged={setEnable2FA}
                    size={'large'}
                  />
                }
              />
            </div>
          </Group>

          <Group label={'Features'} className="p-[24px] gap-[24px]">
            <ListItem
              title="Enable Existing Address"
              description="Enables the creation of Existing Address assets"
              style="bold"
              trailing={
                <Switch
                  name="existing_address"
                  size="large"
                  enabled={existingAddress}
                  onChanged={setExistingAddress}
                />
              }
              className="max-w-[23.4rem]"
            />

            <ListItem
              title="Enable Custom Tracker"
              description="Enables the creation of Custom Tracker assets"
              style="bold"
              trailing={
                <Switch
                  name="custom_tracker"
                  size="large"
                  enabled={customTracker}
                  onChanged={setCustomTracker}
                />
              }
              className="max-w-[23.4rem]"
            />

            <ListItem
              title="Enable Escrow"
              description="Enables the Escrow feature for this organisation"
              style="bold"
              trailing={
                <Switch
                  name="escrow_enabled"
                  size="large"
                  enabled={escrowEnabled}
                  testId="escrow_enabled"
                  onChanged={setEscrowEnabled}
                />
              }
              className="max-w-[23.4rem]"
            />
          </Group>

          <Group label={t('custodians')}>
            <Paragraph
              description={t('custodians_description')}
              classOverrides={{ description: 'max-w-[40rem]' }}
            />

            <div className="border-black border">
              {_custodians && _custodians.length > 0 ? (
                <Table
                  className="max-h-[20rem] min-h-[20rem]"
                  assetCustodians={_custodians}
                  setCustodian={setCustodians}
                  name={'custodians'}
                />
              ) : (
                <NoData description={t('no_custodian_description')} />
              )}
            </div>
          </Group>
        </Form>
      )}
    </Drawer>
  )
}
