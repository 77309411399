import { ColDef } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { t } from 'i18next'

import { Typography } from '@/components/typography'
import { IAsset } from '@/types/asset'

import { ColDefOptions } from './_options.coldef'

interface Options<T> extends ColDefOptions<T> {}

export const ReferenceColDef = <T,>(options?: Options<T>) => {
  return {
    field: options?.field ?? 'platform_id',
    headerName: options?.headerName ?? t('ar_reference'),
    filter: 'agTextColumnFilter',
    cellRenderer: (cellProps: CustomCellRendererProps<IAsset>) => {
      return (
        <div className={'flex flex-row w-full'}>
          <Typography variant={'paragraph-small'} className={'truncate'}>
            {cellProps.value}
          </Typography>
        </div>
      )
    },
    width: 200,
    initialWidth: 200,
  } as ColDef<T>
}
