interface Props {
  children: React.ReactNode
}

export const Pill: React.FC<Props> = ({ children }) => {
  return (
    <div className="bg-[#DCEEE0] h-8 flex items-center px-3 rounded-full text-sm font-medium">
      {children}
    </div>
  )
}
