import { ColDef } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { t } from 'i18next'

import Button from '@/components/button'
import { Typography } from '@/components/typography'
import { copyToClipboard } from '@/helpers/copyToClipboard'

import { ColDefOptions } from './_options.coldef'

interface Options<T> extends ColDefOptions<T> {}

export const EmailColDef = <T,>(options?: Options<T>) => {
  return {
    field: options?.field ?? 'email',
    headerName: options?.headerName ?? t('email'),
    cellRenderer: (cellProps: CustomCellRendererProps<T>) => {
      return (
        <div className={'flex flex-row gap-[16px] items-center w-full'}>
          <Typography
            variant={'paragraph-small'}
            className={'text-right truncate'}
          >
            {cellProps.value ?? ''}
          </Typography>
          <Button.Shape
            layout={'icon'}
            size={'xsmall'}
            shape={'square'}
            hierarchy={'tertiary'}
            icon={{ name: 'copy' }}
            onClick={(e) => {
              e.preventDefault()
              if (cellProps.value) {
                copyToClipboard(cellProps.value, t('email'))
              }
            }}
          />
        </div>
      )
    },
    width: 300,
    initialWidth: 300,
  } as ColDef<T>
}
