import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import { IWorkspace, IWorkspaceMember } from '@/types/workspace'

/**
 * get workspaces
 */
export const getWorkspaces = async (): Promise<BaseResponse<IWorkspace[]>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client
    .get<BaseResponse<IWorkspace[]>>(`workspaces`)
    .then((response) => {
      return response.data
    })
}

/**
 * get single workspace
 */
export const getWorkspace = async (
  id: string
): Promise<BaseResponse<IWorkspace>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client
    .get<BaseResponse<IWorkspace>>(`workspaces/${id}`)
    .then((response) => {
      return response.data
    })
}

/**
 * delete a single workspace
 */
export const deleteWorkspace = async (
  id: string
): Promise<BaseResponse<boolean>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client
    .delete<BaseResponse<boolean>>(`workspaces/${id}`)
    .then((response) => {
      return response.data
    })
}

/**
 * create new workspace
 */
export const createWorkspace = async (obj: {
  name: string
  description?: string
}): Promise<BaseResponse<IWorkspace>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client
    .post<BaseResponse<IWorkspace>>(`workspaces`, obj)
    .then((response) => {
      return response.data
    })
}

/**
 * update workspace
 */
export const updateWorkspace = async (
  obj: IWorkspace
): Promise<BaseResponse<IWorkspace>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client
    .put<BaseResponse<IWorkspace>>(`workspaces/${obj.id}`, obj)
    .then((response) => {
      return response.data
    })
}

/**
 * Gets Workspace members
 */
export const getWorkspaceMembers = async (
  id: string
): Promise<BaseResponse<IWorkspaceMember[]>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client
    .get<BaseResponse<IWorkspaceMember[]>>(`workspaces/${id}/members`)
    .then((response) => {
      return response.data
    })
}

/**
 * Adds Workspace members
 */
export const addWorkspaceMember = async (
  id: string,
  userId: string,
  roleId: string
): Promise<BaseResponse<boolean>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client
    .post<BaseResponse<boolean>>(`workspaces/${id}/members`, {
      user_id: userId,
      role_id: roleId,
    })
    .then((response) => {
      return response.data
    })
}

/**
 * Adds Workspace members
 */
export const editWorkspaceMember = async (
  id: string,
  userId: string,
  roleId: string
): Promise<BaseResponse<boolean>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client
    .put<BaseResponse<boolean>>(`workspaces/${id}/members/${userId}`, {
      role_id: roleId,
    })
    .then((response) => {
      return response.data
    })
}

/**
 * Gets Workspace members
 */
export const removeWorkspaceMembers = async (
  id: string,
  userId: string
): Promise<BaseResponse<boolean>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client
    .delete<BaseResponse<boolean>>(`workspaces/${id}/members/${userId}`)
    .then((response) => {
      return response.data
    })
}
