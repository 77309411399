import { useState } from 'react'

import { Modal } from '@/components/modal'

import { ApproveScreen } from './ApproveScreen'
import { ConfirmationScreen } from './ConfirmationScreen'

interface Props {
  id: string
  onClose: () => void
}

export const ReviewModal: React.FC<Props> = ({ onClose }) => {
  const [status, setStatus] = useState('review')

  return (
    <Modal portal visible className="p-6">
      {status === 'review' && (
        <ApproveScreen
          onApprove={() => setStatus('approved')}
          onDecline={() => setStatus('declined')}
          onClose={onClose}
        />
      )}
      {(status === 'approved' || status === 'declined') && (
        <ConfirmationScreen status={status} onClose={onClose} />
      )}
    </Modal>
  )
}
