import { useState } from 'react'

import { Paragraph } from '@/components/paragraph'
import { ReviewModal } from '@/screens/admin/ReviewModal/ReviewModal'

import { History } from './History'
import { Requests } from './Requests'

export const Disposal: React.FC = () => {
  const [id, setId] = useState<string>()

  return (
    <div className="flex flex-col gap-8 px-8 py-4">
      <Paragraph title="Disposal" />
      <Requests onClick={setId} />
      <History />
      {id && <ReviewModal id={id} onClose={() => setId(undefined)} />}
    </div>
  )
}
