import { ColDef } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { t } from 'i18next'

import { Typography } from '@/components/typography'
import { IAsset } from '@/types/asset'

export const AssetIdentifierColDef = () => {
  return {
    field: 'external_ref',
    headerName: t('identifier'),
    filter: 'agTextColumnFilter',
    cellRenderer: (cellProps: CustomCellRendererProps<IAsset>) => {
      return (
        <div className={'flex flex-row w-full'}>
          <Typography variant={'paragraph-small'} className={'truncate'}>
            {cellProps.value}
          </Typography>
        </div>
      )
    },
    width: 200,
    initialWidth: 200,
  } as ColDef<IAsset>
}
