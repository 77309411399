import { ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { Badge, Props as BadgeProps } from '../badge'
import { Icon, Props as IconProps } from '../icon'
import { Typography } from '../typography'
import { StatusChip } from './variants/status'

type leadingOptions = ReactElement<IconProps> | ReactElement<BadgeProps>
type trailingOptions = ReactElement<IconProps> | ReactElement<BadgeProps>
export interface Props {
  label: string
  size?: 'small' | 'medium' | 'large'
  colour?:
    | 'green'
    | 'yellow'
    | 'red'
    | 'blue'
    | 'white'
    | 'black'
    | 'grey'
    | 'error'
  type?: 'filled' | 'outline'
  leading?: leadingOptions
  trailing?: trailingOptions
  state?: 'default' | 'disabled'
  onClick?: () => void
  className?: string
  textClassName?: string
}

export const Chip = (props: Props) => {
  const renderArt = (artProps?: leadingOptions | trailingOptions) => {
    if (artProps) {
      if (artProps.type === Icon) {
        return (
          <Icon
            {...(artProps.props as IconProps)}
            state={props.state}
            size={'small'}
          />
        )
      } else if (artProps.type === Badge) {
        return (
          <div
            className={
              props.size === 'small'
                ? 'flex items-center justify-center min-w-[18px]'
                : 'flex items-center justify-center min-w-[16px] ml-[6px]'
            }
          >
            <Badge {...(artProps.props as BadgeProps)} size={'small'} />
          </div>
        )
      }
    }
    return null
  }

  const backgroundStyles = () => {
    if (props.state === 'disabled') {
      return twMerge(
        'bg-[#E1E1E2]',
        props.type === 'outline' && 'border-[#BCBDBE]'
      )
    } else if (props.colour === 'green') {
      return twMerge(
        'bg-green-100',
        props.type === 'outline' && 'border-green-500',
        props.onClick &&
          'hover:bg-green-200 focus:bg-green-100 active:bg-green-500'
      )
    } else if (props.colour === 'yellow') {
      return twMerge(
        'bg-[#FFF3D5]',
        props.type === 'outline' && 'border-[#FFC42D]',
        props.onClick &&
          'hover:bg-[#FFDB80] focus:bg-[#FFF3D5] active:bg-[#FFC42D]'
      )
    } else if (props.colour === 'red') {
      return twMerge(
        'bg-red-100',
        props.type === 'outline' && 'border-red-500',
        props.onClick && 'hover:bg-red-200 focus:bg-red-100 active:bg-red-500'
      )
    } else if (props.colour === 'blue') {
      return twMerge(
        'bg-blue-100',
        props.type === 'outline' && 'border-blue-500',
        props.onClick &&
          'hover:bg-blue-200 focus:bg-blue-100 active:bg-blue-500'
      )
    } else if (props.colour === 'black') {
      return twMerge(
        'bg-black',
        props.type === 'outline' && 'border-black bg-gray-100',
        props.onClick && 'hover:bg-gray-600 focus:bg-gray-600 active:bg-black',
        props.onClick &&
          props.type === 'outline' &&
          'hover:bg-[#E1E1E2] focus:bg-[#E1E1E2] active:bg-[#E1E1E2]'
      )
    } else if (props.colour === 'grey') {
      return twMerge(
        'bg-[#E1E1E2]',
        props.type === 'outline' && 'border-[#2124271A] bg-gray-100',
        props.onClick &&
          'hover:bg-gray-300 focus:bg-gray-300 active:bg-[#E1E1E2]',
        props.onClick &&
          props.type === 'outline' &&
          'hover:bg-[#E1E1E2] focus:bg-[#E1E1E2] active:bg-[#E1E1E2]'
      )
    } else if (props.colour === 'error') {
      return twMerge(
        'bg-yellow-500',
        props.type === 'outline' && 'border-yellow-500 bg-yellow-100',
        props.onClick &&
          'hover:bg-yellow-200 focus:bg-yellow-100 active:bg-yellow-500'
      )
    } else {
      // Default to white
      return twMerge(
        'bg-white',
        props.type === 'outline' && 'border-white bg-gray-100',
        props.onClick &&
          'hover:bg-gray-100 focus:bg-gray-100 active:bg-gray-200',
        props.onClick &&
          props.type === 'outline' &&
          'hover:bg-[#E1E1E2] focus:bg-gray-100 active:bg-white'
      )
    }
  }

  const onClick = () => {
    if (props.onClick && props.state !== 'disabled') {
      props.onClick()
    }
  }

  return (
    <span
      className={twMerge(
        'inline-flex items-center rounded-[4px] red-',
        'transition-[background-color] duration-300',
        'select-none overflow-hidden',
        props.onClick && 'cursor-pointer',
        props.type === 'outline' && 'border-[1px] border-solid',
        props.size === 'large'
          ? 'h-10 max-h-10 min-h-10 p-[4px]'
          : props.size === 'medium'
            ? 'h-8 max-h-8 min-h-8 p-[4px]'
            : 'h-5 max-h-5 min-h-5 p-[2px]',
        backgroundStyles(),
        props.className
      )}
      onClick={onClick}
    >
      {renderArt(props.leading)}
      <Typography
        variant={
          props.size === 'large'
            ? 'paragraph-medium'
            : props.size === 'medium'
              ? 'paragraph-small'
              : 'paragraph-extrasmall'
        }
        tooltip={props.label}
        spacers={[]}
        className={twMerge(
          'truncate',
          props.leading ? 'pr-[8px] pl-[4px]' : 'pr-[8px] pl-[8px]',
          props.state === 'disabled' ? 'text-[#909293]' : 'text-black',
          props.textClassName
        )}
      >
        {props.label}
      </Typography>
      {renderArt(props.trailing)}
    </span>
  )
}

export default {
  Basic: Chip,
  Status: StatusChip,
}
